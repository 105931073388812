import CourtSettingsLeft2 from "../../components/CourtSetting/CourtSettingsLeft2";
import TimeRangePicker from "../../components/CustomDropdownTimeRange";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { allTimeFormats } from "src/constants/variables";
import { RxCross2 } from "react-icons/rx";
import AddIcon from "@mui/icons-material/Add";
import BreakTimeRangePicker from "../../components/CustomDropdownBreakTimeRange";
import ServicesSpecialRow from "./ServicesSpecialRow";
import ConditionalDaysRow from "./../CourtSetting/ConditionalDaysRow";
import {
  CreateCoachByClubId,
  DeleteCoachByID,
  GetCoachSettings,
  UpdateCoachByID,
  UpdateCoachSettings,
} from "src/api/Coach";
import validateWorkingHours from "src/constants/workingHoursIschecked";
import { useFieldArray, useForm } from "react-hook-form";
import { useGetAllCustomersTypes } from "src/hooks/useGetAllCustomersTypes";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { useGetAllMemeberShip } from "src/api/membership";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const CourtSettingsTab = ({
  CoachData,
  GetCoachData,
  getWokingHours,
  Coach_id,
  handleCoachClose,
  sport_id,
  UserProfile,
  setError
}: {
  sport_id: number;
  GetCoachData: any;
  CoachData: any;
  getWokingHours: any;
  Coach_id: any;
  handleCoachClose: any;
  UserProfile: any;
  setError:any;
}) => {
  const {t} = useTranslation();
  const { control, setValue, watch, formState } = useForm<any>();
   const StaffPermission = useGetPermissions();
  const { Notification, setNotification,setIsPermissionOn } = useContext(MainContext);
  const { email, phone_number, full_name, description, profile_url } =
    CoachData();
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "Rows",
  });
  const conditionData = watch("Rows");
  const {
    control: SpecialControl,
    setValue: setSpecial,
    watch: specialWatch,
  } = useForm<any>({});
  const {
    fields: FieldsSpecial,
    remove: removeSpecial,
    append: specailAppend,
    insert: specialInsert,
  } = useFieldArray<any>({
    control: SpecialControl,
    name: "Special",
  });
  const {
    data: customType,
    isSuccess: customerTypeSuccess,
    isError: customerTypeError,
  } = useGetAllMemeberShip();
  const specialData = specialWatch("Special");
  const [allCustomerType, setAllCustomerType] = useState<any[]>([]);
  const [selectedcustomerType, setselectedcustomerType] = useState<any[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showBreak, setShowBreak] = useState(false);
  const [time, setTime] = useState<any>();
  const [workingHoursDiff, setWorkingHoursDiff] = useState([]);
  const [TimeBreak, getTimeBreak] = useState<any>([]);
  const [servicesopen, setServicesOpen] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleClose = () => {
    setShowBreak(false);
  };

  const removeBreak = (i: number) => {
    const filteredBreaks = TimeBreak.filter(
      (item: any, index: any) => index !== i
    );
    getTimeBreak(filteredBreaks);
  };

  useEffect(() => {
    if (Coach_id) {
      GetCoachSETTINGS();
    }
  }, []);

  useEffect(() => {
    const forparentdatapassing = isChecked
      ? {
          is_checked: isChecked,
          workinghours: workingHoursDiff,
        }
      : {
          is_checked: isChecked,
        };
    getWokingHours(forparentdatapassing);
  }, [workingHoursDiff, isChecked]);

  const GetCoachSETTINGS = async () => {
    const response = await GetCoachSettings(Coach_id);
    if (!!response) {
      remove();
      setselectedcustomerType([]);
      let isCHECKED: any;
      if (response?.working_hours?.length !== 0) {
        isCHECKED = validateWorkingHours(response?.working_hours);
      }
      if (isCHECKED) {
        setIsChecked(true);
        const finalCondition = response?.working_hours?.map((item: any) => {
          let { id, ...newitem } = item;
          id = item.days;
          let ischecked = true;
          return { id, ischecked, ...newitem };
        });
        setWorkingHoursDiff(finalCondition);
        const forparentdatapassing = {
          ischecked: true,
          workinghours: finalCondition,
        };
        getWokingHours(forparentdatapassing);
      } else {
        setIsChecked(false);
        if(!!response?.working_hours?.length){
          setTime(response?.working_hours[0]);
        }else{
          setTime({start_time: "Select Time", end_time: "Select Time"});
        }
 
        getTimeBreak(response?.working_hours[0]?.break_times);
        const forparentdatapassing = {
          ischecked: false,
        };
        getWokingHours(forparentdatapassing);
      }
      // getTimeBreak(response?.working_hours?.break_times);
      const arr = response?.conditional_days?.map(
        (item: any, index: number) => {
          let { days, id,all_user, memberships, ...newitem } = item;
          days !== null ? (days = [days]) : (days = undefined);
          const filterMember = memberships?.map((mem:any)=>{
            const {id,membership_name} = mem;
            return {id,name:membership_name}
          })
          setselectedcustomerType((prev: any) => [...prev, filterMember]);
          insert(index, { days,all_user, ...newitem });
          return { days, id, ...newitem, memberships };
        }
      );
      const specialDaysData: any = response?.special_days?.map(
        (item: any, index: number) => {
          let { days, id, Unavailable,start_time,end_time, ...newitem } = item;
          id = index;
          days !== null ? (days = [days]) : (days = undefined);
          start_time=Unavailable ? "Unavailable":start_time;
          end_time=Unavailable?"Unavailable":end_time;
          specialInsert(index, { days,start_time,end_time, Unavailable, ...newitem });
          return { id, days, ...newitem, Unavailable };
        }
      );
    }
  };

  useEffect(() => {
    selectedcustomerType?.map((item: any, index) => {
      const isAllUser = conditionData[index].all_user;
      const AllMemebrship = isAllUser ? [{id:0,name:'All User'},...item]:item;
      setValue(`Rows.${index}.memberships`, AllMemebrship);
    });
  }, [selectedcustomerType]);

  useEffect(() => {
    if (customerTypeSuccess) {
      const filtered = customType?.map((item:any)=>{
        const {id,membership_name}=item;
        return {id,name:membership_name}
      })
      setAllCustomerType(filtered);
    } else {
      setAllCustomerType([]);
    }
  }, [customType, customerTypeSuccess, customerTypeError]);

  // creating data format
  const finalData = {
    is_checked: isChecked,
    working_hours: isChecked
      ? workingHoursDiff
          ?.filter((item: any) => item.is_checked === true)
          ?.map((item: any) => ({
            start_time: item.start_time,
            end_time: item.end_time,
            is_checked: item.is_checked,
            days: item.days,
            break_times: item?.break_times?.map((data: any) => ({
              start_break: data.start_break,
              end_break: data.end_break,
            })),
          }))
      : [
          {
            start_time: time?.start_time,
            end_time: time?.end_time,
            break_times: TimeBreak?.map((item: any) => ({
              start_break: item.start_break,
              end_break: item.end_break,
            })),
          },
        ],
    conditional_days: conditionData?.map((item: any) => {
      let { days, date_start, date_end, memberships, ...newitem } = item;
      days = days?.length !== 0 ? days : undefined;
      date_start =
        date_start !== undefined && date_start !== null
          ? date_start
          : undefined;
      date_end =
        date_end !== undefined && date_end !== null ? date_end : undefined;
        const all_user = item.memberships?.some((mem:any)=>mem.name==="All User");
      const membership=item.memberships?.filter((mem:any)=>mem?.name!=="All User")?.map((item:any)=>item.id)
      return { date_start, date_end, days, ...newitem,all_user, membership };
    }),
    special_days: specialData?.map((item: any) =>{
      let {start_time,end_time,Unavailable,...newitem} = item;
      Unavailable=start_time==="Unavailable" && end_time==="Unavailable"?true:false;
      start_time= start_time!=="Unavailable"?start_time:"08:00";
      end_time= end_time!=="Unavailable" ? end_time:"08:00";

      return {start_time,end_time,Unavailable,...newitem}
    }),
  };



  const updateClick = async (times:string,CoachId: number) => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }

    if (!full_name) {
        setError("full_name");
        setTimeout(() => setError(""), 2000);
        return;
    } else {
      const formData = new FormData();
      formData.append("full_name", full_name);
      formData.append("description", description);
      formData.append("phone_number", phone_number);
      formData.append("email", email || "");
      UserProfile && formData.append("image", UserProfile);
      // for updating coach settings
      let response: boolean;
      let response2: boolean;
      try {
        response = await UpdateCoachSettings(CoachId, finalData);
      } catch (error: any) {
       times!=="first" && setNotification({
          message: error,
          type: "error",
          subMessage: "",
          isShow: true,
        });
        return;
      }

      try {
        response2 = await UpdateCoachByID(CoachId, formData);
      } catch (error: any) {
        times!=="first" && setNotification({
          message: error,
          type: "error",
          subMessage: "",
          isShow: true,
        });
        return;
      }

      //for updating coach
     if(times!=="first"){
      setNotification({
        message: "Coach settings updated",
        type: "success",
        subMessage: "",
        isShow: true,
      });
     }
      GetCoachData();
      handleCoachClose();
    }
  };

  const handleClick = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }

    if (!full_name) {
      setError("full_name");
      setTimeout(() => setError(""), 2000);
      return;
    } else {
      const formData = new FormData();
      formData.append("full_name", full_name);
      formData.append("description", description);
      formData.append("phone_number", phone_number);
      formData.append("email", email);
      UserProfile && formData.append("image", UserProfile);
      try {
        const response: any = await CreateCoachByClubId(sport_id, formData);
        updateClick("first",response?.data?.id);
        GetCoachData();
        handleCoachClose();
        setNotification({
          message: response.message,
          type: "success",
          subMessage: "",
          isShow: true,
        });
      } catch (error:any) {
        setNotification({
          message: error,
          type: "error",
          subMessage: "",
          isShow: true,
        });
      }
    }
  };

  const handleDelete = async () => {
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleCoachClose()
      return;
    }

    const response: any = await DeleteCoachByID(Coach_id);
    if (response?.data.status === "success") {
      GetCoachData();
      handleCoachClose();
    }
  };

  const handleAddCondition = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      memberships: [],
      price: "",
    };
    append(final);
  };

  const handleAddSpecial = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      break_times: [],
      is_working: true,
    };
    specailAppend(final);
  };

  const handleUpdateForm = (data: any, index: number) => {
    setValue(`Rows.${index}.start_time`, data[0].start_time);
    setValue(`Rows.${index}.end_time`, data[0].end_time);
    const updatedObj = conditionData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      delete updatedObj["date_end"];
      delete updatedObj["date_start"];
      setValue(`Rows.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setValue(`Rows.${index}.date_start`, data[0].date_start);
      setValue(`Rows.${index}.date_end`, data[0].date_end);
    }
  };

  const handleUpdateSpecial = (data: any, index: number) => {
    setSpecial(`Special.${index}.start_time`, data[0].start_time);
    setSpecial(`Special.${index}.end_time`, data[0].end_time);
    const updatedObj = specialData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      setSpecial(`Special.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setSpecial(`Special.${index}.date_start`, data[0].date_start);
      setSpecial(`Special.${index}.date_end`, data[0].date_end);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid>
        <div
          style={{ margin: "10px 0px 5px 0px ", gap: "15px" }}
          className="flex flex-row items-center"
        >
          <h3
            style={{
              color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "16px",
              lineHeight: "17px",
            }}
          >
            {t('courts.Working Hours')}
          </h3>
          <p
            style={{
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              lineHeight: "17px",
            }}
          >
            {t('courts.Different every day')}
          </p>
          <label className="switch">
            <input
              checked={isChecked}
              onChange={handleCheckboxChange}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        {isChecked ? (
          <CourtSettingsLeft2
            preWorkingHours={workingHoursDiff}
            setWorkingHoursDiff={setWorkingHoursDiff}
          />
        ) : (
          <Box
            className="CourtSettings"
            sx={{
              alignItems: { xs: "baseline", sm: "center" },
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Grid
              alignItems={"center"}
              container
              gap={"5px"}
              sx={{ margin: { xs: "0px", sm: "0px 20px" } }}
            >
              <TimeRangePicker
                TimeRange={time}
                listData={allTimeFormats}
                getTime={setTime}
              />
              {TimeBreak?.length == 0 ? (
                ""
              ) : (
                <Grid container>
                  {TimeBreak?.map((item: any, index: any) => (
                    <div
                      key={item}
                      style={{ marginRight: "5px" }}
                      className="relative"
                    >
                      <span
                        key={index}
                        className="flex justify-between items-center"
                        style={{
                          height: "28px",
                          width: "110px",
                          padding: "5px 8px",
                          rowGap: "5px",
                          color: "#22356D",
                          borderRadius: "7px",
                          backgroundColor: "rgba(136, 154, 182, 0.15)",
                          fontFamily: "var(--font-regular)",
                          fontSize: "14px",
                        }}
                      >
                        <span>
                          {item?.start_break.slice(0, 5)}-
                          {item?.end_break.slice(0, 5)}
                        </span>
                        <Box
                          onClick={() => removeBreak(index)}
                          sx={{
                            marginTop: "-2px",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#22356DB2",
                              color: "#FFFFFF",
                            },
                          }}
                        >
                          <RxCross2 />
                        </Box>
                      </span>
                    </div>
                  ))}
                </Grid>
              )}
            </Grid>

            <div>
              <Button
                onClick={() => setShowBreak(true)}
                style={{
                  width: "140px",
                  color: "rgba(34, 53, 109, 0.70)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  gap: "10px",
                  textTransform: "unset",
                }}
              >
                {t('courts.Add time break')}
                <AddIcon style={{ verticalAlign: "middle" }} fontSize="small" />
              </Button>
            </div>
          </Box>
        )}
      </Grid>
      <Grid marginTop={"25px"}>
        <Grid container alignItems={"center"} gap={"15px"}>
          <h3
            style={{
              color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "16px",
            }}
          >
           {t('courts.Special Days')}
          </h3>
          <Button
            onClick={handleAddSpecial}
            style={{
              color: "rgba(34, 53, 109, 0.70)",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",
              gap: "10px",
              textTransform: "none",
            }}
          >
           {t('Add special day')}
            <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
          </Button>
        </Grid>
        <div className="flex flex-col my-1">
          {FieldsSpecial?.length !== 0 &&
            FieldsSpecial?.map((item: any, index: any) => (
              <ServicesSpecialRow
                isBreak={true}
                remove={removeSpecial}
                handleUpdateSpecial={handleUpdateSpecial}
                preSpecialDays={specialData}
                key={index}
                index={index}
                setSpecial={setSpecial}
              />
            ))}
        </div>
      </Grid>
      <Grid style={{ margin: "25px 0" }}>
        <Grid container>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {t('services.events.Discount Conditions')}
            </h3>
            <Button
              onClick={handleAddCondition}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t('services.bookings.Add Condition')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <Box sx={{ margin: { xs: "0px", sm: "0px 20px" }, width: "100%" }}>
            {fields?.length !== 0 &&
              fields?.map((item: any, index: any) => (
                <ConditionalDaysRow
                  selectedlocation={{ id: 0, location_name: "", currency: "" }}
                  setServicesOpen={setServicesOpen}
                  customer_type={allCustomerType}
                  conditionData={conditionData}
                  handleUpdateForm={handleUpdateForm}
                  index={index}
                  remove={remove}
                  setValue={setValue}
                  key={index}
                />
              ))}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        marginTop={servicesopen ? "200px" : "20px"}
      >
        <Button
          onClick={Coach_id !== undefined ? handleDelete : handleCoachClose}
          style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            width: "104px",
            height: "37px",
            color: "#22356D",
            backgroundColor: "white",
            fontSize: "16px",
            padding: "10px 30px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {Coach_id !== undefined ? t("common.Delete") : t("common.Cancel")}
        </Button>
        <Button
          onClick={() => {
            Coach_id !== undefined ? updateClick("",Coach_id) : handleClick();
          }}
          style={{
            textTransform: "unset",
            fontFamily: "var(--font-semibold)",
            color: "white",
            backgroundColor: "#22356D",
            width: "92px",
            height: "37px",
            fontSize: "16px",
          }}
        >
          {t('common.Save')}
        </Button>
      </Grid>
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "auto",
            height: "200px",
          },
        }}
        hideBackdrop={true}
        onClose={handleClose}
        open={showBreak}
      >
        <DialogContent>
          <Typography
            sx={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              margin: "10px",
            }}
          >
           {t('courts.Add break times')}
          </Typography>
          <BreakTimeRangePicker
            handleClose={handleClose}
            preTime={TimeBreak}
            getTime={getTimeBreak}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CourtSettingsTab;
