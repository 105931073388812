import SelectBox from "../../components/SelectBox";
import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import moment from "moment";
import "../Payments/payments.css";
import MainContext from "src/context/MainContext";
import Cleave from "cleave.js/react";
import { useGetCourtList } from "src/hooks/Calendars/useGetCourtList";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import { Dialog, DialogContent, useMediaQuery } from "@mui/material";
import theme from "src/theme";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { useTranslation } from "react-i18next";
import { getCategory } from "src/api/POS";
import { category } from "./Products";
import RefundSale from "./RefundSale";

const commonStyle = {
  width: "150px",
  color: "#22356D",
  height: "32px",
  fontFamily: "var(--font-regular)",
};

const services = ["Booking", "Event", "Lesson", "Voucher"];
const methods = ["Credit Card", "Voucher", "debit card"];
const statuses = [
  "Paid",
  "Pending payment",
  "Total Refund",
  "Partial Refund",
  "Cancelled",
  "Deleted",
];

const PurchaseFilter = ({
  allPayments,
  selectedLocation,
  update,
  setSelectedDays,
  allCategory
}: {
  allPayments: any;
  selectedLocation: any;
  update: any;
  setSelectedDays: any;
  allCategory:any;
}) => {
  const {
    data: PaymentMethods,
    isSuccess,
    isError,
    refetch,
    setlocation_id,
  } = useGetAllPaymentMethodsByLoc();
  const {t} = useTranslation();
  const { Notification, setNotification } = useContext(MainContext);
  const [AllEvent, setAllEvent] = useState<any>();
  const [selectedHead, setselectedHead] = useState<string>("");
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [selectedCourt, setSelectedCourt] = useState<any>("");
  const [AllPaymentMethods, setAllPaymentMethods] = useState<any[]>([]);
  const [index, setindex] = useState<number>();
  const { selectedDate } = React.useContext(MainContext);
  const context = useContext(MainContext);
  const setSelectedLocationId = context?.setSelectedLocationId;
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const setSelectedDate = context?.setSelectedDate;
  const queryParams = new URLSearchParams(window.location.search);
  const inputValueParam = queryParams.get("inputValue");
  const [filters, setFilters] = useState({
    customerName: "",
    ProductName:"",
    customerId: "",
    services: "",
    paymentMethod: "",
    Category: "",
    discount: "",
    totalPrice: "",
  });
  const [open, setOpen] = useState(false);
  const [showExpand, setshowExpand] = useState<any>(undefined);
  const [data, setData] = useState<any>();
  const [filteredData, setFilteredData] = useState([]);
  const [openBookingModel, setOpenBooking] = useState<boolean>(false);

  useEffect(() => {
    if (!!selectedLocation) {
      setlocation_id(selectedLocation?.id);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (isSuccess) {
      const backend = PaymentMethods;
      const newupdte = [{ method_name: "Wallet" }, ...backend];
      setAllPaymentMethods(newupdte);
    } else {
    }
  }, [PaymentMethods, isSuccess, isError]);

    // function to convert it into excel format
    const exportToCSV = (data: any) => {
      let csvContent =
        "Date,Customer,ProductName,Category,PaymentMethod,Price,Discount,TotalPrice\n";
  
      data.forEach((booking: any) => {
        const { createdAt,fullName,product,price,discount,paymentMethods } = booking;
        const date = moment(createdAt).format('DD/MM/YYYY');
          const row = `${date},${fullName?fullName:"-"},${product?.product_name},${product?.category?.category_name},${paymentMethods?.method_name},${price},${discount},${price-discount}\n`;
          csvContent += row;
      });

      const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const csvURL = URL.createObjectURL(csvBlob);
  
      const link = document.createElement("a");
      link.href = csvURL;
      link.download = "Sales.csv";
      link.click();
    }
  
  
    // export to csv
    const handleExportButtonClick = () => {
      exportToCSV(sortedData);
    };
  

  const handleGetEvents = async (data: any) => {
    if(data?.refund_method && data?.refund_price>0){
      setNotification({isShow:true,message:'This product is already refunded',subMessage:'',type:'error'});
    }else{
      setAllEvent(data);
      setOpenBooking(true);
    }
     
  
  };

  const handlepopup = (item: any, index: number) => {
    setData(item);
    setindex(index);
    setOpen(true);
  };

  const handleFilterChange = (name: string, value: any) => {
    setFilters((prevFilters: any) => {
      if (prevFilters[name] === value) {
        return { ...prevFilters, [name]: "" };
      } else {
        return {
          ...prevFilters,
          [name]: value,
        };
      }
    });
  };


 

  // Function to filter data based on filter criteria
  const filterData = () => {
    let filtered = allPayments;

    if (filters.customerName) {
      filtered = filtered.filter((item: any) =>
        item?.fullName &&
    item.fullName.toString()
      .toLowerCase()
      .includes(filters.customerName.toLowerCase())
      );
    }
    if (filters.ProductName) {
      filtered = filtered.filter((item: any) =>
        item.product?.product_name
            .toString()
            .toLowerCase()
            .includes(filters.ProductName.toLowerCase())
      );
    }
    // if (filters.customerId) {
    //   filtered = filtered.filter((item: any) =>
    //     item.id.toString().startsWith(filters.customerId)
    //   );
    // }
    if (filters.services) {
      filtered = filtered.filter((item: any) =>
        item.service_type.toString().startsWith(filters.services)
      );
    }
    if (filters.paymentMethod) {
      filtered = filtered?.filter((item: any) =>
        (item?.paymentMethods?.method_name || item?.payment_method) === filters.paymentMethod
      );
    }
    if (filters.Category) {
      filtered = filtered.filter(
        (item: any) => item?.product?.category?.category_name === filters.Category
      );
    }
    if (filters.discount) {
      filtered = filtered.filter(
        (item: any) => String(item?.discount).includes(filters.discount)
      );
    }
    if (filters.totalPrice) {
      filtered = filtered.filter((item: any) =>
        String(item?.price).includes(filters.totalPrice)
      );
    }
    setFilteredData(filtered);
    setSortedData(filtered)
  };

  useEffect(() => {
    if (inputValueParam !== null) {
      setSelectedDays({
        date_start: moment(selectedDate).format("YYYY-MM-DD"),
        date_end: moment(selectedDate).format("YYYY-MM-DD"),
      });
      handleFilterChange("customerName", inputValueParam);
    }
  }, [inputValueParam]);

  const handleClick = (id: number, data?: any) => {
    if (showExpand === id) {
      setshowExpand(undefined); // Remove ID if already selected
    } else {
      setshowExpand(id); // Set ID if not selected
    }
    if (isMobile) {
      handleGetEvents(data);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect hook to trigger filtering whenever filter criteria change
  useEffect(() => {
    filterData();
  }, [filters, allPayments]);

  const isnextelement =
    showExpand !== undefined &&
    filteredData.findIndex((item: any) => item.id === showExpand) + 1;

  const determineStatus = (data: any) => {
    const statusCounts = data.reduce((acc: any, item: any) => {
      acc[item.status] = (acc[item.status] || 0) + 1;
      return acc;
    }, {});

    const uniqueStatuses = Object.keys(statusCounts);

    if (uniqueStatuses.length === 1) {
      // Only one unique status
      return uniqueStatuses[0];
    } else {
      // More than one unique status
      if (uniqueStatuses.includes("Pending payment")) {
        return `Pending payment +${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Partial Refund")) {
        return `Partial Refund + ${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Total Refund")) {
        return `Total Refund + ${uniqueStatuses.length - 1}`;
      } else if (uniqueStatuses.includes("Paid")) {
        return "Paid";
      }
    }
    return;
  };

  useEffect(() => {
    let sorted;
    if (selectedHead) {
      sorted = [...filteredData].sort((a: any, b: any) => {
        // Sorting logic based on the current sorted column
       if (selectedHead === "date") {
          const dateA: any = new Date(
            a?.createdAt
          );
          const dateB: any = new Date(
            b?.createdAt
          );
          return dateB - dateA;
        } else if (selectedHead === "customer") {
          return a?.fullName && b?.fullName && a?.fullName.localeCompare(b?.fullName);
        } else if (selectedHead === "price") {
          return (
            b?.price -
            a?.price
          );
        } else if (selectedHead === "Tprice") {
          return (
            (b?.price-b?.discount) -
            (a?.price-a?.discount)
          );
        } else if (selectedHead === "discount") {
          return (
            b?.discount -
            a?.discount
          );
        }
        return 0;
      });
    } else {
      sorted = filteredData;
    }

    setSortedData(sorted);
  }, [selectedHead, filteredData]);

  const handleSelectedHeadChange = (name: string) => {
    setselectedHead((prev: any) => {
      if (prev === name) {
        return "";
      } else {
        return name;
      }
    });
  };


  return (
    <Box>
      <Box
        marginBlock={"20px"}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <img
          src="/images/view.png"
          alt="filter"
          style={{ width: "20px", height: "20px" }}
        />
        <input
          name="customerId"
          value={filters.customerId}
          onChange={(e: any) =>
            handleFilterChange("customerId", e.target.value)
          }
          type="number"
          placeholder="ID"
          style={commonStyle}
          className="mobile-res"
        />
        <input
          name="customerName"
          value={filters.customerName}
          onChange={(e: any) =>
            handleFilterChange("customerName", e.target.value)
          }
          type="text"
          placeholder="Staff Name"
          style={commonStyle}
          className="mobile-res"
        />
        <input
          name="ProductName"
          value={filters.ProductName}
          onChange={(e: any) =>
            handleFilterChange("ProductName", e.target.value)
          }
          type="text"
          placeholder="Product"
          style={commonStyle}
          className="mobile-res"
        />
        <Box sx={{ width: "150px" }} className="mobile-res">
          <SelectBox
            text={"Category"}
            value={filters.Category}
            setValue={(data: any) => handleFilterChange("Category", data)}
            arr={allCategory?.map((item:any)=>item?.category_name)}
          />
        </Box>
        <Box width={"198px"}>
          <SelectBox
            text={"Payment Methods"}
            value={filters.paymentMethod}
            setValue={(data: any) => handleFilterChange("paymentMethod", data)}
            arr={AllPaymentMethods?.map((item: any) => item?.method_name)}
          />
        </Box>
        {/* <Cleave
          placeholder="Discount"
          value={filters.discount}
          onChange={(e: any) => handleFilterChange("discount", e.target.rawValue)}
          style={commonStyle}
          className="mobile-res"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter: selectedLocation?.currency === "IDR" ? "\u2024" : ",",
          }}
        /> */}
        <Cleave
          placeholder="Total Price"
          value={filters.totalPrice}
          onChange={(e: any) =>
            handleFilterChange("totalPrice", e.target.rawValue)
          }
          style={commonStyle}
          className="mobile-res"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter: selectedLocation?.currency === "IDR" ? "\u2024" : ",",
          }}
        />
        <Button
          className="mobile-res"
          style={{
            ...commonStyle,
            borderRadius: "7px",
            padding: "0px",
            border: "#C6CBDA 1px solid",
            opacity: "0.7",
            background: "white",
            textTransform: "capitalize",
          }}
          onClick={handleExportButtonClick}
        >
          {t('common.Export To CSV')}
        </Button>
      </Box>
      <Box
        sx={{
          padding: "10px",
          background: "white",
          borderRadius: "7px",
          boxShadow: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
        }}
      >
        <table style={{ width: "100%" }} id="paymentTable">
          <thead>
            <tr>
              <th
                onClick={() => handleSelectedHeadChange("id")}
                className={
                  selectedHead === "id"
                    ? "static-width underline"
                    : "static-width"
                }
              >
                ID
              </th>
              <th
                onClick={() => handleSelectedHeadChange("date")}
                className={
                  selectedHead === "date"
                    ? "static-width underline"
                    : "static-width"
                }
              >
                Date
              </th>
              <th
                onClick={() => handleSelectedHeadChange("customer")}
                className={
                  selectedHead === "customer"
                    ? "static-width underline "
                    : "static-width "
                }
              >
                {t('POS.Staff Name')}
              </th>
              <th className="show-row">{t('POS.Product')}</th>
              <th className="show-row">Category</th>
              <th
                className={
                  "static-width show-row"
                }
              >
                {t('customers.Payment Method')}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("price")}
                className={
                  selectedHead === "price"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t('POS.Unit Price')}
              </th>
              <th
               onClick={() => handleSelectedHeadChange("discount")}
               className={selectedHead === "discount"
               ? "static-width underline show-row"
               : "static-width show-row"}
                style={isLargeScreen ? { width: "16%" } : { width: "auto" }}
              >
                {t('POS.Units')}
              </th>
              <th
                onClick={() => handleSelectedHeadChange("Tprice")}
                className={
                  selectedHead === "Tprice"
                    ? "static-width underline show-row"
                    : "static-width show-row"
                }
              >
                {t('common.Total Price')}
              </th>
              <th className="show-row">{t('common.Details')}</th>
            </tr>
          </thead>
          <tbody>
            {

              sortedData?.length>0 ? sortedData?.map((item: any) => (
                <tr>
                  <td className="">{item.id}</td>
                  <td className="">{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                  <td className="">{item?.fullName ? item?.fullName :'-'}</td>
                  <td className="show-row">{item.product?item.product.product_name:''}</td>
                  <td className="show-row">{item?.product?item?.product?.category?.category_name:''}</td>
                  <td className="show-row">{item?.paymentMethods?.method_name || item?.payment_method}</td>
                  <td className="show-row">{addThousandSeparator(item.price,selectedLocation?.currency)}</td>
                  <td className="show-row">{item.discount}</td>
                  <td className="show-row">{addThousandSeparator(item.price-item.discount,selectedLocation?.currency)}</td>
                  <td className="show-row">
                    <Typography
                        onClick={() => handleGetEvents(item)}
                      sx={{
                        margin: "auto",
                        cursor: "pointer",
                        width: "80px",
                        borderRadius: "7px",
                        padding: "5px 15px",
                        border: "1px solid #EEEFEE",
                        opacity: "0.9",
                        background: "var(--Main-Colors-White, #FFF)",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07)",
                        fontFamily: "var(--font-regular)",
                        "&:hover": {
                          background: "#22356D !important",
                          color: "white !important",
                        },
                      }}
                    >
                      {t('common.Details')}
                    </Typography>
                  </td>
                </tr>
              )):<tr>
              <td className=""></td>
              <td className=""></td>
              <td className="show-row"></td>
              <td className="show-row"></td>
              <td className="show-row">No purchase found</td>
              <td className="show-row"></td>
              <td className="show-row"></td>
              <td className=""></td>
              <td className="show-row"></td>
              <td className="show-row"></td>
            </tr>
            }
          </tbody>
        </table>
      </Box>
      <Dialog
    sx={{
      ".MuiDialog-paper": {
      minWidth: {xs:"auto", sm:"700px"},
      },
    }}
    onClose={()=>setOpenBooking(false)}
    open={openBookingModel}
    >
      <DialogContent>
      <RefundSale refetch={update} open={openBookingModel} handleClose={()=>setOpenBooking(false)} SelectedLocation={selectedLocation} data={AllEvent} selectedLocationID={selectedLocation?.id}  />
      </DialogContent>
      </Dialog>
    </Box>

  );
};

export default PurchaseFilter;
