import { Calendar, DateLocalizer, Views, momentLocalizer } from 'react-big-calendar';
import { useGetCourtsWeekly } from 'src/api/CoachCalendar';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getDateAtSpecificTime } from 'src/constants/MomentTimeFor';
import BookedSlotPopover from '../calenderDaily/BookedSlotPopover';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BookingModel from '../calenderDaily/BookingModel';
import MainContext from 'src/context/MainContext';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import moment from 'moment';
import { calculateTotalHours } from 'src/constants/variables';
import { roundTimeToNextHour } from '../calenderDaily/CourtCalendar';

const localizer = momentLocalizer(moment);

const font1 = {fontFamily:'var(--font-medium)', fontSize:"0.9rem"};

const CourtWeekCalendar = ({columnsArray,setWeeksGraph, setSelectView, resource, courtList, removeResource, selectedWeek, dates, view}:{columnsArray:string, setSelectView: (selectView: string) => void, resource:any, courtList:any,setWeeksGraph:any, removeResource:any, selectedWeek:number, dates:any, view:string}) => {
    const max = moment().hour(22).minute(0).second(0).millisecond(0).toDate();
    const context = useContext(MainContext);
    const [maxTime,setMaxtime] = useState<any>("22:00:00")
    const updatedData:any = {};
    const service_id:number[] = [];
    const setEvent = context?.setEvent;
    const selectedDate = context?.selectedDate;
    const SelectedLocation = context?.SelectedLocation;
    const selectedMonth = context?.selectedMonth;
    const formats = { timeGutterFormat: 'HH:mm' };
    const [TotalHeight,setTotalHeight] = useState(900);
    const [minTime, setMinTime] = useState<any>(moment().hour(7).minute(0).second(0).millisecond(0).toDate());
    const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'));
    const [resources, setResources] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [serviceList, setServiceList] = useState<any>([]);
    const [filteredArray, setFilteredArray] = useState<any>([]);
    const [update, setUpdate] = useState<boolean>(false);
    const [clickStart, setClickStart] = useState('');
    const [clickEvent, setClickEvent] = useState({});
    const [clickEnd, setClickEnd] = useState('');
    const [weekLists, setWeeks] = useState<any>([]);
    const {data,isSuccess,isError,refetch,setCourt_id,setMonthYear,setWeekNumber} = useGetCourtsWeekly ();
    const setSelectedDate = context?.setSelectedDate;

    
    serviceList?.map((item:any)=>{
      if (service_id.includes(item?.service?.id)){
        updatedData[item?.service?.id] = {
          ...updatedData[item?.service?.id],
          value:updatedData[item?.service?.id]?.value + item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity
        }
      }else{
        service_id.push(item?.service?.id);
        updatedData[item?.service?.id] = {
          value: item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity,
          players: item?.players?.length
        }
      }
    })  
    
    const ShowRecurring = () =>{  
      return (
        <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#889AB6", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>R</Box>
      )
    }
  
    const ShowAvailablity = () =>{  
      return (
        <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#455585", fontSize:"9px",lineHeight:"8px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>A</Box>
      )
    }

    const CustomEvent = ({ event}:{event:any}) => {
      const [showPopHover, setPopHover] = useState(null);
      const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
      const [hoveredCourt,setHoveredCourt] = useState<any>();
      const handleTouchStart = (events: React.TouchEvent<HTMLDivElement>) => {
        events.stopPropagation();
        const filteredCourt = courtList?.filter((item:any)=>item?.id===event?.court_id);
        setHoveredCourt(filteredCourt[0]?.courts_timing[0])
        setPopHover(event);
        setAnchorE1(events.currentTarget);
      };

    
      const handleMouseEnter = (events: React.MouseEvent<HTMLElement>) =>{
        const filteredCourt = courtList?.filter((item:any)=>item?.id===event?.court_id);
        setHoveredCourt(filteredCourt[0]?.courts_timing[0])
          setPopHover(event);
        setAnchorE1(events.currentTarget);
      }
    
      const handlePopClose = () => {
        setAnchorE1(null);
        setPopHover(null);
      } 
  
      const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        setAnchorE1(null);
        setPopHover(null);
      };

     
      function isThirtyMinutes(start_time: Date, end_time: Date): boolean {
        // Parse the date objects using Moment.js
        const startTime = moment(start_time);
        const endTime = moment(end_time);
        
        // Calculate the difference in minutes
        const duration = moment.duration(endTime.diff(startTime));
        
        // Check if the duration is equal to 30 minutes
        if(duration.asMinutes() === 60){
          return true;
        }else if(duration.asMinutes() === 30){
          return true;
        }else{
          return false;
        }
      }
 
     

      const totaluser = event?.players?.filter((item:any)=>!item?.is_canceled);
      let unwaiting = [];
      if(totaluser){
         unwaiting = totaluser?.filter((item:any)=>!item.is_wating);
      } 

 
      if(event.service?.service_type==='Event'){
        return(
          <Box onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} position={'relative'} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : '',border:'#7E93FF 1px'}}>
            {!isThirtyMinutes(event.start_time, event.end_time) && <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>}
          <Typography sx={{...font1, color:'#F07727'}}>{event.service?.additional_service ? event.service?.additional_service : event?.service?.event?.event_name}</Typography>
          <Typography sx={{...font1,fontSize:"0.8rem", color:'#F07727',lineHeight:'10px'}}>{event?.service?.coaches[0]?.full_name}{event?.service?.coaches?.length>1 ? ` + ${event?.service?.coaches?.length-1}` : ""}</Typography>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
              {/* <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.9rem",
                color:'#F07727'}}>{event.full_name}</Typography> */}
    
              <Typography sx={{fontFamily:'var(--font-regular)', borderRadius:'7px', width:'fit-content', height:'14px', fontSize:'11px',padding:'2px 7px', textAlign:'center',background:`${event?.players.length==event?.event?.maximum_capacity ? 'transparent' : '#F07727'}`,
                color:`${event?.players.length==event?.event?.maximum_capacity ? '#F07727' : 'white'}`, position:"absolute",bottom:0, right:0}}>{event?.players?.length}/{event?.service?.event?.maximum_capacity}</Typography>
            </Box>
            {/* ------ showing recurring ------ */}
            <Box sx={{position:"absolute", width:"93%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.service?.event?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>  
        )
      }else if(event.service?.service_type==="Booking"){
        return (
          <Box onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} position={'relative'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : ''}}>
          <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>
          <Typography sx={{...font1, color:'#7E93FF',lineHeight:'10px'}}>
          {event.service?.booking_type==="Open Match" ? `Open Match ` : event.service?.additional_service ? event.service?.additional_service : event?.players?.length>0 ? `${event?.players[0]?.customer?.first_name} ${event?.players[0]?.customer?.last_name}` : "Booking"}
          </Typography>
            <Box position={'absolute'} bottom={'9px'} right={'9px'} sx={{borderRadius:'7px', width:'fit-content', height:'14px', fontSize:'11px',padding:'2px 7px', textAlign:'center',
              background:`${event?.players?.length==event?.service?.booking?.maximum_capacity ? 'transparent' : '#7E93FF'}`,
              color:`${event?.players?.length==event?.service?.booking?.maximum_capacity ? '#7E93FF' : 'white'}`
            }}>{event?.players?.length}/{event?.service?.booking?.maximum_capacity}</Box>
            {/* ------ showing recurring ------ */}
            <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.by_app && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>
        )
      }else if(event.service?.service_type==="Lesson" ){
        return (
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} sx={{">p":isThirtyMinutes(event.start_time, event.end_time) ? {fontSize:  "0.73rem !important", lineHeight:"13px"} : ''}}>
            {!isThirtyMinutes(event.start_time, event.end_time) &&<Typography sx={{fontFamily:'var(--font-regular)', fontSize:"14px", color:'#22356D',lineHeight:'20px'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>}
          
          <Typography sx={{...font1,fontSize:"0.8rem", color:'#A3A434'}}>{event?.service?.coaches[0]?.full_name}{event?.service?.coaches?.length>1 ? ` + ${event?.service?.coaches?.length-1}` : ""}</Typography>
          <Typography sx={{...font1, color:'#A3A434', textTransform:'capitalize',lineHeight:'10px'}}>{event.service?.additional_service ? event.service?.additional_service : event.service?.lesson?.lesson_name}</Typography>
          <Box position={'absolute'} bottom={'5px'} right={'5px'} sx={{
            background:`${unwaiting?.length==updatedData[event.service?.id].value ? 'transparent' : '#A3A434'}`,
            color:`${unwaiting?.length==updatedData[event.service?.id].value ? '#A3A434' : 'white'}`,
            borderRadius:'7px', width:'fit-content', height:'12px', fontSize:'10px',padding:'2px 7px', textAlign:'center'}}>{unwaiting?.length}{event?.service?.additional_service ? "" : `/${updatedData[event.service?.id].value}`}</Box>
          {/* ------ showing recurring ------ */}
          <Box sx={{position:"absolute", width:"98%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
            {event?.service?.lesson?.app_available && ShowAvailablity()}
            {event.service?.recurring && ShowRecurring()}
          </Box>
            {/* ------ showing recurring ------ */}
            {/* {event.service?.recurring && ShowRecurring()} */}
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>
        )
      }else if(event?.service?.service_type==="Block"){
        return (
          <>
            <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'}>
              <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#889AB6CC'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>
              <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#889AB6CC', textAlign:"center"}}>Blocked - {event?.service?.block_reason}</Typography>
            </Box>
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </>
        )
      }else if(event?.is_cancelled){
        return(
          <Box onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} className="default-hover" width={"10px"} height={'100%'}>
           <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>
        )
      }else{
        return (
          <>
            <Box onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} height={'100%'}>
            </Box>
         
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='weekly' courtTiming={hoveredCourt} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </>
        )
      }
    }

    const eventPropGetter = useCallback(
      (event:any, start:any, end:any, isSelected:any) => {

        const isAnyPlayerShown = event?.players?.some((item:any) => item?.is_show);
        const pendingPayment:any = event?.players?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.some((item:any) => item?.status ==="Pending payment");
      const unfilledOpenMatch = event?.service?.booking_type==="Open Match";

      return {
        ...(event.service?.service_type.includes('Booking') && {
          // className: event.notPlay === 1 ? 'two-border' : 'court-border',
          className: isAnyPlayerShown && pendingPayment && unfilledOpenMatch?'three-border' : isAnyPlayerShown && pendingPayment ? "twos-border" : pendingPayment && unfilledOpenMatch ? "two-border" : isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' : unfilledOpenMatch? 'yellow-border' :'court-border',
          style: {
            backgroundColor: '#EBECFD99', // blue
            right:0,
            border:'#7E93FF 0.5px solid',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch?'none':'#7E93FF 0.5px solid'
          },
        }),
        ...(event.service?.service_type.includes('Event') && {
          className: isAnyPlayerShown && pendingPayment ? "twos-border": isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
          style: {
            backgroundColor: '#FDF0E699', // orange
            border:'#F07727 solid 0.5px',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch?'none':'#F07727 solid 0.5px'
          },
        }),
        ...(event.service?.service_type.includes('Lesson') && {
          className: isAnyPlayerShown && pendingPayment ? "twos-border" : isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
          style:{
            backgroundColor:'#FFFDD199', // yellow
            border:'#A3A434 solid 0.5px',
            borderLeft:isAnyPlayerShown || pendingPayment || unfilledOpenMatch ?'none':'#A3A434 solid 0.5px'
          }
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Event') && {
          className: 'cancel eventCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Lesson')  && {
          className: 'cancel lessCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event?.is_cancelled && event.service?.service_type.includes('Booking')  && {
          className: 'cancel bookCan',
          style:{
            borderRadius: '7px !important',
          },
        }),
        ...(event.service?.service_type.includes('Block') && {
          className: 'blocked',
          style:{
            borderRadius: '7px !important',
            border:'rgba(136, 154, 182, 0.80) 0.5px solid'
          },
        }),
        ...(event?.type?.includes('working_hours') && {
          className: 'special',
        }),
        ...(event?.type?.includes("break_hours") && {
          className:"special",
        }),
        ...(event?.type?.includes("special_days") && {
          className:"special",
        })
      }
      },
      []
    )

    function checkEventOverlap(startTime:any, endTime:any,  fullName:string) {
      return filteredArray.some((event:any) => {
        const eventStart = new Date(event.start_time);
        const eventEnd = new Date(event.end_time);
        const inputStart = new Date(startTime);
        const inputEnd = new Date(endTime);
        const isTimeOverlap = (inputStart > eventStart && inputStart < eventEnd) ||
                              (inputEnd > eventStart && inputEnd < eventEnd) ||
                              (inputStart <= eventStart && inputEnd >= eventEnd);
  
        return isTimeOverlap && event.full_name === fullName;
      });
    }

    const handleClickOpen = (event:any) =>{
      // setSelectedDate(event?.start);

      const {start, end, resourceId} = event;
      setSelectedDate(start);
      let start_time = moment(start).format("HH:mm");
      let end_time = moment(end).format("HH:mm");
      setClickStart(start_time);
      setClickEnd(end_time);
      setClickEvent({});
      setEvent({});
      setOpen(true);
      // let background = checkEventOverlap(start, end, resourceId);
      // if(background){
      //   setOpen(false);
      // }else{
      //   setOpen(true);
      // }
    }

    const handleEvent = (data: any) => {
      const {start_time} = data;
      setSelectedDate(start_time);
      setClickEvent(data);
      setOpen(true);
    }


    function getWeekList(date:any) {
      const startDate = moment(date).startOf('week'); // Start date of the week
      const endDate = moment(date).endOf('week'); // End date of the week
      const weekList = [];
    
      // Loop through each day of the week
      for (let currentDay = startDate.clone(); currentDay.isSameOrBefore(endDate); currentDay.add(1, 'day')) {
        weekList.push({
          date: currentDay.format('DD'),
          dayName: currentDay.format('dddd'),
          monthName: currentDay.format('MMMM'),
        });
      }
      setWeeks(weekList)
    }

    const DayHeader = ({ label } : { label: string }) => {
      const [startDateString, endDateString] = label.split(' - ');
      const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const dayName = startDateString.split(" ")[1];
      // Find the index of the provided day name in the weekdays array
      const dayIndex = weekdays.findIndex(weekday => weekday === dayName);
      
      return (
        <div style={{fontFamily:"var(--font-regular)",lineHeight:'16px'}}>
          <p>{weekLists[dayIndex]?.dayName}</p>
          <p><b>{weekLists[dayIndex]?.date}</b></p>
          <p>{weekLists[dayIndex]?.monthName}</p>
        </div>
      );
    };

    useEffect(() => {
      if(isSuccess){
        const myArray = data?.serviceBookings;
        const newArray = myArray?.map((obj:any) => {
          const hello = obj?.courts?.map((item:any)=>
            {
              const date = moment(obj?.date).format('DD/MM/YYYY');
                  return { ...obj,
                   id:item?.id,
                   court_name:item?.court_name,
                   service_id:obj?.id,
                   start_time: moment(`${date} ${obj?.start_time}`, "DD/MM/YYYY HH:mm:ss").toDate(),
                   end_time: moment(`${date} ${obj?.end_time}`, "DD/MM/YYYY HH:mm:ss").toDate()}
                 })   
                 return hello[0]
        });

        const NotHidden = newArray?.filter((item:any)=>!item?.is_hide && !item?.is_cancelled)
        setWeeksGraph(data?.stats);
        setServiceList(NotHidden||[]);
      }else{
        setServiceList([])
      }
     
    }, [data,isSuccess,isError]);

    

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(moment().format('HH:mm'));
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
      const updateDisplayTime = () => {
        const firstDiv = document.querySelector('.rbc-current-time-indicator');
        const existingDisplayTime = document.getElementById('display-time-week');
        if (firstDiv !== null) {
          if (existingDisplayTime) {
            existingDisplayTime.textContent = currentTime;
          } else {
            const newDiv = document.createElement('div');
            newDiv.textContent = currentTime;
            newDiv.id = 'display-time-week';
            firstDiv.appendChild(newDiv);
          }
        }
      };
            // Delay the update until after the initial render
      const timeoutId = setTimeout(updateDisplayTime, 1000);

      return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
    }, [currentTime]);
  

    useEffect(()=>{

      if(Object.keys(resource).length!==0){
        let month_year = moment(selectedMonth).format("MM-YYYY");
        setResources([resource]);
        setCourt_id(resource?.id);
        setMonthYear(month_year);
        setWeekNumber(selectedWeek);
        // getWeekCoachData(resource, selectedMonth, selectedWeek);
      }else{
        let month_year = moment(selectedMonth).format("MM-YYYY");
        setResources(courtList);
        setCourt_id(courtList[0]?.id);
        setMonthYear(month_year);
        setWeekNumber(selectedWeek);
        // getWeekCoachData(courtList[0], selectedMonth, selectedWeek)
      }
    },[resource, selectedMonth, courtList, selectedWeek]);

    // const getServiceList = () =>{
    //   getWeekCoachData(resources[currentIndex], selectedDate, selectedWeek);
    // }

    function getDateOfWeekInMonth(days:string) {
      const date = moment(selectedMonth, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
      const year = date.format('YYYY');
      let dateString:string = '';
      weekLists.forEach((element:any) => {
        if(element.dayName === days){
          let date = element.date;
          let month = element.monthName;
          dateString = date+"-"+month+"-"+year;
        }
      });
      return dateString;
    }

    function getEarliestStartTimeForToday(coaches:any) {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const today = new Date().getDay();
      const todayName = daysOfWeek[today];
  
      let earliestTime:any = null;
      let earliestObject:any = null;
 
      coaches?.forEach((coach:any) => {
        const todayTiming = coach.courts_timing?.find((timing:any) => timing.days === todayName);
        if (todayTiming) {
          if (!earliestTime || todayTiming.start_time < earliestTime) {
            earliestTime = todayTiming.start_time;
            earliestObject = coach;
          }
        }
      });
      let currentDate = moment(selectedDate).toDate();
      if(earliestTime){
        let value = getDateAtSpecificTime(currentDate, earliestTime);
        setMinTime(value);
        return value;
      }else{
        return minTime;
      }
    }

    useEffect(()=>{
      setFilteredArray([]);
      let dateStringss = getEarliestStartTimeForToday(resources);
      const date = moment(dateStringss, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
      const time = date.format('HH:mm:ss');

        //finding late end time
    let latestEndTime = "22:00:00"; 
    resources.forEach((court:any) => {
        court.courts_timing.forEach((timing:any) => {
            if (timing.end_time > latestEndTime) {
                latestEndTime = timing.end_time;
            }
        });
    });   

        //finding late end time
    let latestStartTime :any= "08:00:00"; 
    resources.forEach((court:any) => {
      court.courts_timing.forEach((timing:any) => {
          if (timing.start_time < latestStartTime) {
            latestStartTime = timing.start_time;
          }
      });
  });
  
  let {endtime:FilteredTime,starttime}:any = roundTimeToNextHour(latestStartTime,latestEndTime);
    setMaxtime(FilteredTime);
    const startTimeDate = moment().hour(starttime?.slice(0, 2)).minute(starttime?.slice(3, 5)).second(0).millisecond(0).toDate();
    setMinTime(startTimeDate);
 

      const adjustTimings = (court:any) => {
        const { id, court_name, courts_timing} = court;
        const sTimeMoment = moment(latestStartTime, "HH:mm:ss");
        const eTimeMoment = moment(FilteredTime, "HH:mm:ss");
        const workingDays = courts_timing.filter((entry:any) => !entry.special).map((entry:any) => entry.days);
        const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const nonWorkingDays = allDays?.filter(day => !workingDays?.includes(day));
        nonWorkingDays?.map((item:any)=>{
          let dateString = getDateOfWeekInMonth(item)
            setFilteredArray((prevArray:any) => [
              ...prevArray,
              {
                day:item, 
                type: "working_hours",
                start_time: moment(`${dateString} ${latestStartTime}`).toDate(),
                end_time: moment(`${dateString} ${FilteredTime}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                court_name,
                id
              },
            ]);
        })

        courts_timing?.forEach(({ start_time, end_time, days, break_times,special }: any) => {
          const startTime = moment(start_time, "HH:mm:ss");
          const endTime = moment(end_time, "HH:mm:ss");
            if(days){
              const dayOfWeek = moment().day(days).format("YYYY-MM-DD"); 
              let dateString = getDateOfWeekInMonth(days); 

              const SpecialDays = courts_timing?.filter((item:any)=>item.special===true);
              // console.log(SpecialDays)
              if(!!SpecialDays?.length){
                
                SpecialDays?.map((item:any)=>{
                  const startDate = new Date(item?.date_start);
                  const endDate = new Date(item?.date_end);
                  const selectedDate = new Date(dateString);
                  const FormatedDate = moment(selectedDate).format('YYYY-MM-DD')
                  const BreakTime = item?.break_times
                 
                if(selectedDate >= startDate && selectedDate <= endDate){

                //   resources.forEach((court:any) => {
                //     court.courts_timing.forEach((timing:any) => {
                //       if (new Date(timing?.date_start) <= new Date(FormatedDate) && new Date(timing?.date_end) >= new Date(FormatedDate)) {
                //         if (timing.start_time < latestStartTime) {
                //           latestStartTime = timing.start_time;
                //         }
                //       }else if(!timing.special){
                //         if (timing.start_time < latestStartTime) {
                //           latestStartTime = timing.start_time;
                //         }
                //       }
                //     });
                // });
                // const startTimeDate = moment().hour(latestStartTime?.slice(0, 2)).minute(latestStartTime?.slice(3, 5)).second(0).millisecond(0).toDate();
                // setMinTime(startTimeDate);

                  BreakTime.forEach(({ start_break, end_break }: any) => {
                    const startBreakTime = moment(`${FormatedDate} ${start_break}`, "YYYY-MM-DD HH:mm:ss");
                      setFilteredArray((prev: any) => [
                        ...prev, {
                          type: "break_hours",
                          start_time: startBreakTime.toDate(),
                          end_time: moment(`${FormatedDate} ${end_break}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                          id,
                          court_name
                        }
                      ]);
                    
                  });
                }else{
                  if (startTime.isAfter(sTimeMoment) && !special) {
                    setFilteredArray((prevArray:any) => [
                      ...prevArray,
                      {
                        type: "working_hours",  
                        day:days,
                        start_time: moment(`${dateString} ${latestStartTime}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                        end_time: moment(`${dateString} ${start_time==="00:00:00"?"24:00:00":start_time}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                        court_name,
                        id
                      },
                    ]);
                  }
    
                  if (endTime.isBefore(eTimeMoment) && !special) {
                    setFilteredArray((prevArray:any) => [
                      ...prevArray,
                      {
                        type: "working_hours",
                        day:days,
                        start_time: moment(`${dateString} ${end_time}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                        end_time: moment(`${dateString} ${FilteredTime==="24:00:00"?"23:59:59":FilteredTime}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                        court_name,
                        id
                      },
                    ]);
                  }
                 
                  if(break_times.length>0 && !special){
                    break_times?.forEach(({start_break, end_break}:any) => {
                      const startBreakTime = moment(start_break, "HH:mm:ss");
                      const Time = moment(dateStringss).format("HH:mm:ss");
                      const getTime = moment(latestStartTime,"HH:mm:ss")
                      if (startBreakTime.isAfter(moment(getTime))){
                        setFilteredArray((prev:any)=>[
                          ...prev,{
                            type:"break_hours",
                            day:days,
                            start_time: moment(`${dateString} ${start_break}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                            end_time: moment(`${dateString} ${end_break}`, "DD-MMMM-YYYY HH:mm:ss").toDate(),
                            court_name,
                            id
                          }
                        ])
                      }
                    })
                  }
                }
              })}
            }          
        });
      };
      
      resources?.forEach(adjustTimings);
    },[columnsArray, resources]);

    useEffect(() => {
      const min = moment(minTime).format("HH:mm:ss")
      const hours =  calculateTotalHours(min,maxTime);
      const height:any= (hours * 2)*27
      setTotalHeight(height);

    }, [minTime,maxTime])


    useEffect(()=>{
      getWeekList(moment(dates.startDate));
    },[dates.startDate, selectedWeek]);

    useEffect(()=>{
      const containers = document.querySelectorAll('.rbc-events-container');
  
      containers?.forEach(container => {
        const cancels = container.querySelectorAll('.cancel');
        cancels?.forEach((cancel:any, index:number) => {
          cancel.id = `cancel-${index}`;
        });
      });
    },[serviceList])

    useEffect(()=>{},[weekLists]);
   return (
    <Box marginRight={4} marginBottom={3}>
      <div style={{width:"100%", position:"relative"} as React.CSSProperties}>
        {/* {resources.length>1 && currentIndex>0 && <Box position={'absolute'} top={"1.5%"} left={"4%"} sx={{cursor:"pointer"}} onClick={previousPage}><SlArrowLeft style={{color:"#22356D"}} /></Box>}
        {resources.length>1 && resources.length!==currentIndex+1 && <Box position={'absolute'} top={"1.4%"} right={"0.5%"} sx={{cursor:"pointer"}} onClick={nextPage}><SlArrowRight style={{color:"#22356D"}} /></Box>} */}

        <Calendar
          key={weekLists}
          formats={formats}
          localizer={localizer}
          defaultView={Views.WEEK}
          dayLayoutAlgorithm={'no-overlap'}
          date={moment(dates?.startDate).startOf('week').toDate()}
          backgroundEvents={filteredArray}
          events={serviceList}
          resourceIdAccessor="id"
          resourceAccessor="id"
          resourceTitleAccessor={(resource: any) => (
            <div onClick={()=>{setSelectView("Daily");removeResource({})}}>
            <p>{resource.court_name}</p>
            <p style={{fontFamily:"var(--font-regular)"}}>{resource.sport?.sport_name}</p>
          </div>
          
          )}
          titleAccessor="title"
          startAccessor='start_time'
          endAccessor='end_time'
          min={minTime}
          max={maxTime=== "24:00:00"?moment().endOf('day').toDate():moment().hour(maxTime?.slice(0,2)).minute(maxTime?.slice(3,5)).second(0).millisecond(0).toDate()}
          resources={[resource]}
          step={30}
          selectable
          onSelectSlot={handleClickOpen}
          onSelectEvent={handleEvent}
          eventPropGetter={eventPropGetter}
          components={{
            week: {
              header: DayHeader,
            },
            event: (serviceList)=>{
              return CustomEvent(serviceList)
            }
          }}
          views={{ day: false, week: true, month: false }}
          style={{height: TotalHeight}}
        />
          <Typography sx={{position:'absolute', bottom:'-10px',left:'5px',color:"#22356DB2",fontSize:'16px',fontFamily:"var(--font-regular)"}}>{moment(maxTime, "HH:mm:ss").format("HH:mm")}</Typography>
      </div>
      {/* ----- Model Box ------ */}
      <BookingModel fromWh={""} events={clickEvent} update={update} serviceList={refetch} columnsArray={columnsArray} openBookingModel={open} setOpen={setOpen} clickStart={clickStart} clickEnd={clickEnd} clickCourt={[resource?.id]} courtList={courtList} view={"Courts"} />
    </Box>
  )
}

export default CourtWeekCalendar;

CourtWeekCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  dayLayoutAlgorithm: PropTypes.string,
}


