import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { activeColor } from 'src/constants/color';

const style = {
    fontFamily: "var(--font-semibold)",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: "7px",
  };


const StatusWarning = ({userData,name,setshowWarning,handleShowAndCancel,sendNotification,SetSendNotification}:{userData:any,name:string,setshowWarning:any,handleShowAndCancel:any,sendNotification:any,SetSendNotification:any}) => {
  const {t} = useTranslation();


    
  return (
    <Box padding={2}>
    <Box>
      <Typography
        sx={{
          fontFamily: "var(--font-medium)",
          color: "#22356D",
        }}
      >
        {userData.is_show && name==="show" ?t("Calendar.Are you sure you want to undo this players no show mark?"):userData.is_canceled && name==="cancel"?t("Calendar.Are you sure you want to undo this players cancellation?"):
          name==="show" ?t("Calendar.Are you sure you want to mark this player as no show?")
          :name=="cancel" && t("Calendar.Are you sure you want to cancel this players participation?")}
      </Typography>
      {!userData?.is_cancelled && !userData?.is_show && <Box sx={{display:'flex',flexDirection:'row',marginTop:'1rem',gap:'10px'}}>
              <label style={{margin:'0px',top:'-8px'}} className="container">
                <input
                  checked={sendNotification}
                  onChange={()=>SetSendNotification(!sendNotification)}
                  type="checkbox"
                />
                <span className="mark"></span>
              </label>
                <Typography sx={{  fontFamily: "var(--font-regular)",
                  color: "#22356D",}}>Send emails notification to customers.</Typography>
              </Box>}
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        marginTop={2}
      >
        <button
          style={{ ...style, color: activeColor }}
          onClick={() => setshowWarning(false)}
        >
          {t('common.Cancel')}
        </button>
        <button
          onClick={handleShowAndCancel}
          style={{
            ...style,
            color: "white",
            background: activeColor,
          }}
        >
          {t('common.Yes')}
        </button>
      </Box>
    </Box>
  </Box>
  )
}

export default StatusWarning