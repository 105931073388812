import { createBrowserRouter, Navigate } from "react-router-dom";
import MainPage from "../pages/MainPage";
import SignIn from "../auth/login";
import Logout from "src/pages/Logout";
import React, { useContext, useEffect } from "react";
import MainContext from "../context/MainContext";
import CalenderPage from "../pages/CalenderPage";
import Services from "../pages/Services";
import CourtsSetting from "../pages/CourtsSettings";
import Admin from "src/pages/Admin";
import { jwtDecode } from "jwt-decode";
import LocationManager from "src/features/Admin/LocationManager";
import Payments from "src/pages/Payments";
import Customers from "src/pages/Customers";
import Statistics from "src/pages/Statistics";
import ErrorElement from "src/pages/ErrorElement";
import POS from "src/pages/POS";

const RequireAuth: React.FC<any> = ({ children }) => {
  const context = useContext(MainContext);
  const authenticated = context?.authenticated;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userData");
        window.location.href = "/signin";
      }
    }
  }, [context]);

  if (!authenticated) {
    return <Navigate to="/signin" />;
  }

  return children;
};

const RequireAdminRole: React.FC<any> = ({ children }) => {
  const user_data = JSON.parse(localStorage.getItem("user") || "");

  const userRole = user_data.role.name;

  if (userRole === "SUPER_ADMIN") {
    return children;
  } else {
    return <LocationManager />;
  }
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Navigate to="/calendars" />
      </RequireAuth>
    ),
  },
  {
    path: "/",
    errorElement: <ErrorElement />,
    element: (
      <RequireAuth>
        <MainPage />
      </RequireAuth>
    ),
    children: [
      { path: "calendars", element: <CalenderPage /> },
      { path: "logout", element: <Logout /> },
      // { path: "bookings", element: <Bookings /> },
      { path: "payments", element: <Payments /> },
      { path: "courts", element: <CourtsSetting /> },
      { path: "customers", element: <Customers /> },
      { path: "services", element: <Services /> },
      { path: "stats", element: <Statistics /> },
      { path: "POS", element: <POS /> },
      {
        path: "Settings",
        element: (
          <RequireAdminRole>
            <Admin />
          </RequireAdminRole>
        ),
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  // Redirect for any non-existing route
  {
    path: "*",
    element: <Navigate to="/calendars" />,
  },
]);

export default router;
