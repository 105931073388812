import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import MainContext, { locationTypes } from 'src/context/MainContext';
import { useTranslation } from 'react-i18next';

interface sports {
    id:number,
    sport_name: string, 
    sequence: number
}

const VLdropdown = ({selectView, setSelectView,selectLocation, setSelectLocation,tabView, tabLocation, changeCourtList, sportsData,updateSport, selectedSport} : {selectView:string, setSelectView:any,selectLocation:string, setSelectLocation:any, tabView:string[], tabLocation:any[], changeCourtList:any, sportsData:sports[], updateSport:any, selectedSport:any}) => {
    const {t} = useTranslation();
    const context = React.useContext(MainContext);
    const sport_id = context?.sport_id;
    const setSport_id = context?.setSport_id;
    const locationSel = context?.SelectedLocation;
    const [show, setShow] = useState(false);
    const [view, setView] = useState(false);
    const [sport, setSport] = useState(false);
    const [location, setLocation] = useState(false);
    const SetSelectedLoationID = context.setSelectedLocationId;
    const setSelectedLocation = context.setSelectedLocation;
    const SelectedLocation = context.SelectedLocation;

    useEffect(()=>{
        document.addEventListener("click", ()=>{
            setShow(false);
        })
    },[]);


    useEffect(() => {
      if(!!tabLocation?.length){
        if(SelectedLocation?.id===0){
            setSelectedLocation(tabLocation[0]);
            SetSelectedLoationID(tabLocation[0]?.id)
            return;
           }
           setSelectLocation(SelectedLocation?.location_name)
           SetSelectedLoationID(SelectedLocation?.id)
      }
    }, [tabLocation,SelectedLocation]);


    return (
        <Box position={'relative'} sx={{fontFamily:"var(--font-regular) !important", width:"231px"}}> 
            <Box display={'flex'} gap={1} alignItems={'center'} sx={{color:"#22356D"}} onClick={(event)=>{
                event.stopPropagation();
                setShow((prev)=>!prev)}} className='filter'>
                <img src="/images/view1.png" style={{width:"22px", height:"22px"}} alt="calender" />
                <p style={{fontFamily:"var(--font-regular)", textTransform:"capitalize"}}>{t(`Calendar.${selectView}`)} View - {selectLocation}</p>
                {!show ? <BsChevronDown /> : <BsChevronUp />}
            </Box>
            { show && <Box position={'absolute'} sx={{background:"white",borderRadius:"7px", color:"#22356DB2",boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',marginTop:"10px", zIndex:999}} width={'100%'} padding={'0.65rem'}>

                {/* ------ location selector ------ */}
                <Box position={'relative'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{width:"100%",borderRadius:"7px", border:'1px solid #EEEEFE',padding:'10px',marginTop:"10px"}} onClick={(event)=>{
                    event.stopPropagation();
                    setLocation((prev)=>!prev)}}>
                    <p style={{textTransform:"capitalize"}}>{selectLocation}</p>
                    {!location ? <BsChevronDown /> : <BsChevronUp />}
                </Box>
                { location && <Box position={'relative'} display={'block'} sx={{background:'#889AB61A',marginTop:"0px !important", boxShadow:"none !important",maxHeight:"132px",'&>div':{":hover":{"background":"#22356DB2",color:"white",borderRadius:"7px"}}}} id="table-person">
                    {tabLocation.map((item, index)=>
                        <Box 
                        sx={{padding:'10px', textTransform:"capitalize", background:locationSel?.id===item.id ? '#22356DB2' :'#889AB61A', color: locationSel?.id === item.id ? 'white' : '#22356DB2',borderRadius:locationSel?.id === item.id ? "7px":"0px"}}
                        onClick={(event)=>{
                            event.stopPropagation();
                            setSelectedLocation(item)   
                            SetSelectedLoationID(item.id);
                            changeCourtList(item.id);
                            setShow(false);
                            setLocation(false);
                        }} key={index}>{item.location_name}</Box>
                    )}
                </Box> }

                {/* ----------- sport Selector ----- */}
                <Box position={'relative'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{width:"100%",borderRadius:"7px", border:'1px solid #EEEEFE',padding:'10px',marginTop:"10px"}} onClick={(event)=>{
                    event.stopPropagation();
                    setSport((prev)=>!prev)}}>
                    <p style={{textTransform:"capitalize"}}>{selectedSport}</p>
                    {!sport ? <BsChevronDown /> : <BsChevronUp />}
                </Box>
                { sport && <Box position={'relative'} display={'block'} sx={{background:'#889AB61A',marginTop:"0px !important", boxShadow:"none !important",maxHeight:"132px",'&>div':{":hover":{"background":"#22356DB2",color:"white",borderRadius:"7px"}}}} id="table-person">
                    {sportsData?.map((item, index)=>
                        <Box 
                        sx={{padding:'10px', textTransform:"capitalize", background:sport_id.includes(item.id) ? '#22356DB2' :'#889AB61A', color: sport_id.includes(item.id) ? 'white' : '#22356DB2',borderRadius:sport_id.includes(item.id) ? "7px":"0px"}}
                        onClick={(event)=>{
                            event.stopPropagation();
                            // setShow(false);
                            // setSport(false);
                            updateSport(item.sport_name);
                            if(sport_id.includes(item.id)){
                                const filter = sport_id.filter((id:any)=> id!==item.id);
                                setSport_id(filter);
                            }else{
                                setSport_id((prev:any)=>[...prev, item.id]);
                            }
                           
                        }} key={index}>{item.sport_name}</Box>
                    )}
                </Box> }
                 {/* ----- view selector ----- */}
                 <Box position={'relative'} display={'flex'} alignItems={'center'} justifyContent={'space-between'} sx={{width:"100%",borderRadius:"7px",border:'1px solid #EEEEFE',padding:'10px',marginTop:'10px'}} onClick={(event)=>{
                    event.stopPropagation();
                    setView((prev)=>!prev)}}>
                    <p>{t(`Calendar.${selectView}`)} View</p>
                    {!view ? <BsChevronDown /> : <BsChevronUp />}
                </Box>
                { view && <Box position={'relative'} display={'block'} sx={{background:'#889AB61A',marginTop:"0px !important", boxShadow:"none !important",maxHeight:"88px",'&>div':{":hover":{"background":"#22356DB2",color:"white",borderRadius:"7px"}}}} id="table-person">
                    {tabView.map((item:string, index:number)=>
                        <Box sx={{padding:'10px',background:selectView===item ? '#22356DB2' :'#889AB61A', color: selectView === item ? 'white' : '#22356DB2',borderRadius:selectView === item ? "7px":"0px"}} onClick={(event)=>{
                            event.stopPropagation()
                            setSelectView(item);
                            setShow(false)
                        }} key={index}>{t(`Calendar.${item}`)} View</Box>
                    )}
                </Box> }
            </Box> }
        </Box>
    )
}

export default VLdropdown;