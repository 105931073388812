import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomModel from '../CustomModel';
import { BsChevronDown } from 'react-icons/bs';
import React, { useContext, useEffect, useState } from 'react';
import { activeColor } from 'src/constants/color';
import { Controller, useForm } from 'react-hook-form';
import SelectBox from '..//SelectBox';
import "./admin.css";
import { updateClubData,createClub as CreateNewClub, insertClubAdmin, updateClubAdmin } from 'src/api/SuperAdmin';
import { useTranslation } from 'react-i18next';
import MainContext from 'src/context/MainContext';
import { addAppUpdates, getAppUpdates } from 'src/api/ClubAdmin';

const common = {
    display:"flex", alignItems:"center"
};

const style = {
    fontFamily: 'var(--font-semibold)',
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
    padding: "7px 20px",
    borderRadius: '7px'
}

const inputStyle = {
    width:"160px",
    border:'1px solid #EEEEFE', 
    padding:'3px 10px',
    fontFamily:'var(--font-regular)', 
    color:'#22356DB2'
}


export interface FormInputUpdateApp{
    "club_id": number,
    "ios_url": string,
    "android_url": string,
    "ios_version": string,
    "android_version": string,
    "ios_force_update": boolean,
    "android_force_update": boolean
}

const AdminPopUp = ({open,fetchData, setOpen, clubAdmin, data, showInfo, setShowInfo, handleClub, text, setText}:{open:boolean, setOpen:any, clubAdmin?:any, data?:any,showInfo:boolean, setShowInfo:any, handleClub?:any, text?:string, setText?:any,fetchData?:any}) => {
    const { register, control, setValue, handleSubmit, formState: { errors }, reset} = useForm();
    const { register:appRegister,setValue:appSetValue,watch:appWatch,reset:appReset} = useForm<FormInputUpdateApp>();
    const {android_force_update,ios_force_update} = appWatch();
    const { t, i18n } = useTranslation();
    const [error, setError] = useState<any>({});
    const [value, setValues] = useState(0);
    const [clubAdmins, setUsers] = useState<any>([]);
    const [user, setUser] = useState<any>({});
    const [Validation,setValidation] = useState('');
    const {Notification,setNotification} = useContext(MainContext);

    const createClub = (userData:any) => {
        if(value==0){
            setError((prevState:any) => ({
                ...prevState,
                max_location: 'Please select location' 
            }));
        }else{
            setError((prevState:any) => {
                const { max_location, ...updatedState } = prevState;
                return updatedState;
            });
            const final_data = {
                ...userData,
                maximum_allowed_location:value,
                users: clubAdmins
            };
            if(data){
                let id = data.id;
                let edit_data = {
                    "club_name": userData.club_name,
                    maximum_allowed_location: value
                }
                handleUpdate(id, edit_data);
            }else if(!data){  
                handleCreateNewClub(final_data);
            }
        }
    }


    const handleCreateNewClub = async(data:any)=>{
        if(!data?.club_name){
            setValidation("club_name");
            setTimeout(() => setValidation(""), 2000);
            return;
        }else if(!data?.maximum_allowed_location){
            setValidation("location");
            setTimeout(() => setValidation(""), 2000);
            return;
        }

        try {
           const response = await CreateNewClub(data);
           setNotification({message:response?.message,type:'success',subMessage:'',isShow:true})
            handleClub();
        } catch (error:any) {
            setNotification({message:error,type:'error',subMessage:'',isShow:true})
            console.log(error)
        }
    }


    const getAppClub = async (club_id:number)=>{
        try {
            const response = await getAppUpdates(club_id);
            const {id,...updatedData} = response
            appReset(updatedData)
        } catch (error) {
            const updated ={
                ios_url: '',
                android_url: '',
                ios_version:'',
                android_version: '',
                ios_force_update: false,
                android_force_update: false
            }
            appReset(updated);
        }
    }

    const handleUpdate = async(club_id:number,data:any)=>{
        appSetValue('club_id',club_id);
        if(!data?.club_name){
            setValidation("club_name");
            setTimeout(() => setError(""), 2000);
            return;
        }else if(!data?.maximum_allowed_location){
            setValidation("location");
            setTimeout(() => setError(""), 2000);
            return;
        }

        const promises = [addAppUpdates(appWatch()),updateClubData(club_id,data)];
        try {
           const [appUpdateREsponse,response] = await Promise.all(promises);
           setNotification({message:response?.message,type:'success',subMessage:'',isShow:true});

           if(appUpdateREsponse && response){
            handleClub();
            appReset();
            reset();
            setUsers([]);
            setValues(0);
            setOpen(false);
           }
          
        } catch (error:any) {
            setNotification({message:error,type:'error',subMessage:'',isShow:true})
            console.log(error)
        }

        
    }


    const handleDelete = () =>{
        // NARUTO
        console.log("Delete")
    }

    const addUser = (data:any) =>{
        let user = data.users;
        setUsers((prev:any)=>[...prev, ...user]);
        setShowInfo(false);
    }

    const handleEditClubAdmin = async(apiData:any,text:string) => {
        if(text==="Creating_user"){
         try {
         const response = await insertClubAdmin(data.id, apiData);
         setNotification({message:response?.message,type:'success',subMessage:'',isShow:true})
         setOpen(false);
         setShowInfo(false);
         reset();
         fetchData();
         } catch (error:any) {
            setNotification({message:error,type:'error',subMessage:'',isShow:true})
            console.log(error,'error')
         }
        }else if(text==="Updating_user"){
          const {id, users} = apiData;
          let admin = users.users[0];
        try {
            const response = await updateClubAdmin(id, admin);
            setNotification({message:response?.message,type:'success',subMessage:'',isShow:true})
            setOpen(false);
            setShowInfo(false);
            reset();
            fetchData();
        } catch (error:any) {
            setNotification({message:error,type:'error',subMessage:'',isShow:true})
            console.log(error,'error')
        }
        }
        // setTimeout(()=>{
        //   if(selected===0){
        //     fetchData()
        //   }else{
        //     fetchData(name);
        //   }
        // },9000)
      }
  

    const updateUser = (userData:any) => {
        if(text==="Creating_user"){
            handleEditClubAdmin({users: userData.users},text);
        }else if(text==="Updating_user"){
            let data = {
                id:clubAdmin.id ? clubAdmin.id : user.id,
                users: userData
            }
            handleEditClubAdmin(data,text);
        }
    }

    const handleUser = (club:any) =>{
        setUser(club);
        setValue("users[0].full_name", club.full_name);
        setValue("users[0].email", club.email);
        // setValue("users[0].phone_number", club.phone_number);
        setShowInfo(true);
        setText("Updating_user");
    }

    const addNewAdmin = () =>{
        setShowInfo(true);
        if(data || clubAdmin){
            setText("Creating_user");
        }
    }

    useEffect(()=>{
        
        if(data){
         
            reset({
                club_name:data.club_name
            })
            setUsers(data.users);
            setValues(data.maximum_allowed_location);
        }else{
            reset();
        }
    },[data]);

    useEffect(() => {
       if(data && open){
         getAppClub(data?.id);
       }
    }, [open, data])
    
    
   useEffect(() => {
        if(clubAdmin){
            setValue("users[0].full_name", clubAdmin.full_name);
            setValue("users[0].email", clubAdmin.email);
            // setValue("users[0].phone_number", clubAdmin.phone_number);
            setUser(clubAdmin);
        }
    }, [setValue, clubAdmin]);

  return (
    <CustomModel open={open} setOpen={setOpen}> 
        {!showInfo ? 
        ( <Box sx={{padding:{xs:'10px 4px 0px 4px', sm:'20px 16px 0px 16px'}}}>
            <form onSubmit={handleSubmit(createClub)}>
                <Typography sx={{fontFamily:"var(--font-medium)", width:"max-content",borderBottom:"2px solid #22356D"}}>{t('Club Settings')}</Typography>
                <Box sx={{...common, color:"#22356D !important"}} justifyContent={"space-between"} marginTop={2}>
                    <Typography sx={{fontFamily:'var(--font-regular)'}}>{t('Club Name')}</Typography>
                    <input id={Validation==="name"?'customerror':''} type="text" placeholder='Jungle Padel' style={{width:"160px", height:"27px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular)"}} {...register('club_name',)}/>
                        {/* {Validation ==="name" && (
                            <Typography
                              sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {t('This is a required field')}
                            </Typography>
                          )} */}
                </Box>
                <Box sx={{...common, gap:{xs:"10px", sm:'0px'}}} justifyContent={"space-between"} marginTop={1.5}>
                    <Typography sx={{fontFamily:'var(--font-regular)'}}>{t('Max Locations Allowed')}</Typography>
                    <Box sx={{width:'160px',height:"25px"}}>
                        <SelectBox
                            value={value} 
                            setValue={setValues}
                            arr={[1,2,3,4,5,6,7,8,9,10]} 
                        />
                         {/* {error?.max_location && (
                            <Typography
                              sx={{
                                fontFamily: "var(--font-regular)",
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {t('This is a required field')}
                            </Typography>
                          )} */}
                        {/* {error?.max_location && <p style={{fontSize:"0.8rem", color:'red', fontFamily:"var(--font-regular)"}}>Select the no of location</p>} */}
                    </Box>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',gap:{xs:"10px", md:'10px'}, color:"#22356D !important"}} marginTop={'20px'}>

                    <Box sx={{...common,justifyContent:'space-between'}}>
                    <Typography sx={{fontFamily:'var(--font-regular)',...common,gap:'10px'}}>{t('IOS Force Update')} {" "}  <label className="switch">
                        <input
                        {...appRegister('ios_force_update')}
                            // checked={}
                            // onChange={()=>setValue('ios.isChecked',)}
                            type="checkbox"
                        />
                        <span className="slider round"></span>
                    </label></Typography>
                    <input {...appRegister('ios_version')} type='text' placeholder='update version' style={{width:"100px", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular) !important"}}/>
                    </Box>
                    <input {...appRegister('ios_url')} type='text' placeholder='update URL' style={{width:"100%", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular) !important"}}/>
                  
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',gap:{xs:"10px", md:'10px'}, color:"#22356D !important"}} marginTop={'20px'}>

                <Box sx={{...common,justifyContent:'space-between'}}>
                <Typography sx={{fontFamily:'var(--font-regular)',...common,gap:'10px'}}>{t('Android Force Update')} {" "}  <label className="switch">
                    <input
                    {...appRegister('android_force_update')}
                        // checked={}
                        // onChange={()=>setValue('ios.isChecked',)}
                        type="checkbox"
                    />
                    <span className="slider round"></span>
                </label></Typography>
                <input {...appRegister('android_version')} type='text' placeholder='update version' style={{width:"100px", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular) !important"}}/>
                </Box>
                <input {...appRegister('android_url')} type='text' placeholder='update URL' style={{width:"100%", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular) !important"}}/>

                </Box>
                <Typography sx={{fontFamily:"var(--font-medium)", width:"max-content",borderBottom:"2px solid #22356D", marginTop:"1rem"}}>{t('Club Admin')} {data ?`[${data?.users?.length}]`:`[${clubAdmins.length}]`}</Typography>
                <Box width={'184px'} marginTop={1.5} position={'relative'} onClick={()=>addNewAdmin()} className='placeholders'>
                    <input type="text" placeholder='1' style={{width:"100%", height:"37px", border:"1px solid #EEEEFE", borderRadius:"7px",fontFamily:"var(--font-regular)", color:"#22356D !important"}} value={t("Add new")} readOnly/>
                    <BsChevronDown style={{position:'absolute',top:"12.5px", right:'7.5px',fontSize:"0.8rem"}} />
                </Box>
                <Box>
                    <Box id="table-person" sx={{height:"108px",width:{xs:"auto", sm:'486px'}}} className="background-dull">
                        {clubAdmins?.map((item:any, index:number)=>
                            <Box sx={{...common, fontFamily:"var(--font-regular)", color:"#22356D",padding:'8px 10px',height:'77px', fontSize:{xs:'0.9rem', sm:'1rem'}}} justifyContent={'space-between'} onClick={()=>handleUser(item)} key={item.phone_number}>
                                <Box sx={{...common}} gap={1.5}>
                                    <Box sx={{padding:'2px 10px',borderRadius:'7px', background:"white"}}>{index+1}</Box>
                                    <p>{item.full_name}</p>
                                </Box>
                                <p>{item.email}</p>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} marginTop={2} >
                    <button style={{ ...style, color: activeColor }} type="button" onClick={handleDelete}>{t('Delete')}</button>
                    <button style={{ ...style, color: 'white', background: activeColor }} type="submit" onSubmit={handleSubmit(createClub)}>{t('Save')}</button>
                </Box>
            </form>
        </Box>)
       : 
        (<Box sx={{padding:{xs:'10px 4px 0px 4px', sm:'20px 16px 0px 16px'}, fontFamily:'var(--font-regular)'}}>
            <form onSubmit={handleSubmit(data ? updateUser : addUser)}>
                <Typography sx={{fontFamily:'var(--font-medium)'}}>{t('Club Admin Info')}</Typography>
                <Box sx={{boxShadow: "4px 4px 4px 0px #0000000D inset", background:"#889AB61A", padding:'10px 20px',borderRadius:'7px',width:{xs:"auto", sm:'500px'}, marginBottom:"10px"}}>
                    <Box sx={{...common, alignItems:{xs:"flex-start", sm:"center"}, justifyContent:'space-between', gap:{xs:"35px", sm:"0"}, marginBottom:{xs:"5px", sm:"10px"}}}>
                        <Controller
                            name="users[0].full_name"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => <input {...field} type="text" placeholder="Enter Name" style={{...inputStyle}} />}
                        />
                        <Box sx={{background:"#889AB626", padding:'0px 8px',borderRadius:'7px', fontSize:{xs:"0.9rem", sm:'1rem'}}}>Club Admin</Box>
                    </Box>
                    <Box sx={{...common, display:{xs:"grid", sm:"flex"},gap:{xs:"5px", sm:"0"}, justifyContent:'space-between'}}>
                        {/* <Controller
                            name="users[0].phone_number"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => <input {...field} type="tel" placeholder="+569 81346824" style={{...inputStyle}} />}
                        /> */}
                        <Controller
                            name="users[0].email"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={""}
                            render={({ field }) => <input {...field} type="email" placeholder="Enter Email" style={{...inputStyle}} />}
                        />
                    </Box>
                </Box>
                <Typography sx={{fontFamily:"var(--font-medium)", width:"max-content",borderBottom:"2px solid #22356D"}}>Admin Settings</Typography>
                <Box sx={{...common}} justifyContent={"space-between"} marginTop={2}>
                    <Typography sx={{fontFamily:'var(--font-regular)'}}>New Password</Typography>
                    <Controller
                        name="users[0].password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input {...field} type="password" placeholder="password" style={{width:"160px", height:"27px", border:"1px solid #EEEEFE", borderRadius:"7px"}} />}
                    />
                </Box>
                <Box display={'flex'} justifyContent={'space-between'} marginTop={2} >
                    <button style={{ ...style, color: activeColor }} type="button">{t('Delete')}</button>
                    <button style={{ ...style, color: 'white', background: activeColor }} type='submit'>{t('Save')}</button>
                </Box>
            </form>
        </Box>)
        }
    </CustomModel>
  )
}

export default AdminPopUp