import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import CustomSelectCheckBox from "../components/CustomSelectBox";
import CustomTabComponent from "../components/CustomTabComponent";
import CourtSettingLeft from "../components/CourtSetting/CourtSettingLeft";
import CourtSettingRight from "../components/CourtSetting/CourtSettingRight";
import CustomCourtCrousel from "../components/CourtSetting/CustomCourtCrousel";
import { useGetLocation } from "src/api/CourtSettings";
import { useSportQuery } from "src/hooks/useSportQuery";
import { useGetAllCourts } from "src/hooks/useGetAllCourts";
import { RxHamburgerMenu } from "react-icons/rx";
import MainContext from "src/context/MainContext";
import NotificationToast from "src/Notification/NotificationToast";
import { useTranslation } from "react-i18next";
import TopUserInfo from "../components/TopUserInfo";
import StaffModal from "src/Notification/StaffModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const borderInput = {
  width: "46px",
  opacity: "0.7",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};

const CourtsSetting = () => {
  const [view, setView] = useState([]);
  const StaffPermission= useGetPermissions();
  const [BookableDaysFut,setBookableDaysFut] =useState<string>('');
  const { t, i18n } = useTranslation();
  const [tab, selectedTab] = useState<any>([]);
  const [Selectedcourt, setSelectedCourt] = useState<any>([]);
  const [tabValue, setTabValue] = useState<any>(tab[0]);
  const [selected, setSelected] = useState(1);
  const [courtName, setCourtNAme] = useState<string>("");
  const [fromaddCourt, setFromAddCourt] = useState(false);
  const [selectedCourtId, setSelectedCourtId] = useState(0);
  const { MobileSideBar, setMobileSideBar,SelectedLocation,setSelectedLocation } = React.useContext(MainContext);
  const [courts, setCourts] = useState<any[]>([]);
  const [timeslot_format,setTimeSlot_format] = useState();

  const { data, isSuccess, isError, refetch } = useGetLocation();
  const {
    data: sportData,
    isError: sportError,
    refetch: sportRefetch,
    isSuccess: sportSuccess,
    setlocation_id,
  } = useSportQuery();
  const {
    data: courtData,
    isError: courtError,
    refetch: courtRefetch,
    isSuccess: courtSucess,
    setSportId,
  } = useGetAllCourts();

  useEffect(() => {
    setSportId(tabValue?.id);
  }, [tabValue]);

  useEffect(() => {
    setlocation_id(SelectedLocation?.id);
  }, [SelectedLocation]);

  //lcoation
  useEffect(() => {
    if (isSuccess) {
      setView(data);
      if(SelectedLocation?.id===0){
        setSelectedLocation(data[0]);
      }
      
    }else{
      setView([]);
    }
  }, [data, isSuccess, isError]);


  useEffect(() => {
    if (sportSuccess) {
      selectedTab(sportData);
      setTabValue(sportData[0]);
      setSportId(sportData[0]?.id);
    } else {
      selectedTab([]);
      setTabValue("");
    }
  }, [sportData, sportSuccess, sportError]);

  
  //court
  useEffect(() => {
    if (courtSucess) {
      getCourtByID(courtData);
    } else {
      setSelectedCourt("");
      setCourts([]);
    }
  }, [courtError, courtData, courtSucess]);


  const getCourtByID = async (response: any) => {
    if (response?.length > 0) {
      const backendCourts = response;
      const allCourts = { id: 0, court_name: "All Courts" };
      const updatedCourts = [allCourts, ...backendCourts]?.sort(
        (a: any, b: any) => a?.court_name.localeCompare(b?.court_name)
      );
      setSelectedCourt({
        court_id: 0,
        court_name: "All Courts",
      });
      setSelectedCourtId(0);
      setCourts(updatedCourts);
    }
  };

  const openMobileSidebar=(event:any)=>{
    event.stopPropagation();
    setMobileSideBar(true)
  }

  return (
    <div style={{ marginBottom: "100px", minHeight: "100vh" }}>
      <TopUserInfo/>
       <NotificationToast/>
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        <Box
          sx={{
            display: { xs: "block", sm:"block", md: "none" },
            marginTop: { xs: "64px", sm:"64px", md: "0" },
            marginLeft: { xs: "10px", sm:"10px", md: "0" },
          }}
          onClick={openMobileSidebar}
        >
          <RxHamburgerMenu className="MobileHamburger" />
        </Box>
        <h3 className="headingasdfsdf" style={{ width: "100%" }}>
         {t('courts.Courts Settings')}
        </h3>
      </Box>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100%",
          paddingTop: "20px",
          paddingLeft: "20px",
          fontFamily: "var(--font-regular) !important",
        }}
        spacing={1}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Grid container flexDirection={"column"}>
              <Grid sx={{ minHeight: "150px" }}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{
                    width: { xs: "100%", sm:"100%", md: "90%" },
                    marginLeft: { xs: "0px", sm:"0px", md: "30px" },
                    gap: { xs: "10px", sm:"10px", md: "0px" },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                    container
                    item
                    xs={12}
                    sm={12}
                    md={2}
                    className='filter'
                  >
                    <img src="/images/view.svg" alt="calender" />
                    <CustomSelectCheckBox
                      background={true}
                      text={t("Select location")}
                      value={SelectedLocation}
                      setValue={setSelectedLocation}
                      arr={view}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={9.7}>
                    <CustomTabComponent
                      type={"court"}
                      getSportById={sportRefetch}
                      locationid={SelectedLocation?.id}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabTextArray={tab}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    width: { xs: "100%", sm:"100%", md: "90%" },
                    marginLeft: { xs: "-11px", sm:"-11px", md: "-35px" },
                    marginBlock: { xs: "7px", sm:"7px", md: "0px" },
                  }}
                >
                  <CustomCourtCrousel
                    courts={courts}
                    selectedCourtId={selectedCourtId}
                    setSelectedCourtId={setSelectedCourtId}
                    fromaddCourt={fromaddCourt}
                    setFromAddCourt={setFromAddCourt}
                    locationid={SelectedLocation?.id}
                    setSelectedCourt={setSelectedCourt}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* for mobile view only */}
          <Box
            sx={{
              display: { xs: "block", sm:"block", md: "none" },
              width: "100%",
              background: "white",
              borderRadius: "7px",
              padding: "20px",
              marginTop: "10px",
            }}
          >
            {/* <p style={{color:"#22356D"}}>All Courts</p> */}
            {fromaddCourt ? (
              <input
                onChange={(e: any) => setCourtNAme(e.target.value)}
                value={courtName}
                style={{
                  ...borderInput,
                  width: "300px",
                  fontSize: "16px",
                  fontFamily: "var(--font-semibold)",
                  opacity: 1,
                }}
                type="text"
                placeholder={t("Enter Court Name")}
              />
            ) : Selectedcourt.court_id === 0 || Selectedcourt === "" ? (
              <Box sx={{ color: "#22356D" }}>{t('courts.All Courts')}</Box>
            ) : (
              <input
                onChange={(e: any) => setCourtNAme(e.target.value)}
                value={courtName}
                style={{
                  ...borderInput,
                  width: "300px",
                  fontSize: "16px",
                  fontFamily: "var(--font-semibold)",
                  opacity: 1,
                }}
                type="text"
                placeholder={t("Enter Court Name")}
                 />
            )}
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginTop: "10px",
                ">div": { paddingBottom: "3px" },
              }}
            >
              {["Courts Settings", "App Settings"].map((item, index) => (
                <Box
                  sx={{
                    color: selected == index + 1 ? "#22356D" : "#22356D99",
                    borderBottom:
                      selected == index + 1 ? "1px solid #22356D" : "none",
                  }}
                  onClick={() => setSelected(index + 1)}
                >
                  {t(`courts.${item}`)}
                </Box>
              ))}
            </Box>
            <Box sx={{ paddingTop: "20px" }}>
              {selected === 1 && (
                <Box>
                  <CourtSettingLeft
                    setTimeSlot_format={setTimeSlot_format}
                  setBookableDaysFut={setBookableDaysFut}
                  update={courtRefetch}
                    courtName={courtName}
                    setCourtNAme={setCourtNAme}
                    getCourtByID={getCourtByID}
                    fromaddCourt={fromaddCourt}
                    sport={tabValue}
                    selectedLocation={SelectedLocation}
                    Selectedcourt={Selectedcourt}
                  />
                </Box>
              )}
              {selected == 2 && (
                <Box>
                  <CourtSettingRight
                  timeslot_format={timeslot_format}
                  setTimeSlot_format={setTimeSlot_format}
                   setBookableDaysFut={setBookableDaysFut}
                  BookableDaysFut={BookableDaysFut}
                    update={courtRefetch}
                    fromaddCourt={fromaddCourt}
                    sport={tabValue}
                    selectedLocation={SelectedLocation}
                    Selectedcourt={Selectedcourt}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {/* for above mobile view */}
          <Box sx={{ display: { xs: "none", sm:"none", md: "block" } }}>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <CourtSettingLeft
                setTimeSlot_format={setTimeSlot_format}
                 setBookableDaysFut={setBookableDaysFut}
                update={courtRefetch}
                  courtName={courtName}
                  setCourtNAme={setCourtNAme}
                  getCourtByID={getCourtByID}
                  fromaddCourt={fromaddCourt}
                  sport={tabValue}
                  selectedLocation={SelectedLocation}
                  Selectedcourt={Selectedcourt}
                />
              </Grid>
              <Grid item xs={6}>
                <CourtSettingRight
                  setTimeSlot_format={setTimeSlot_format}
                 timeslot_format={timeslot_format}
                setBookableDaysFut={setBookableDaysFut}
                 BookableDaysFut={BookableDaysFut}
                  update={courtRefetch}
                  fromaddCourt={fromaddCourt}
                  sport={tabValue}
                  selectedLocation={SelectedLocation}
                  Selectedcourt={Selectedcourt}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {!StaffPermission.edit_permissions && <StaffModal />}    
    </div>
  );
};

export default CourtsSetting;
