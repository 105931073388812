import CustomSelectCheckBox from "../components/CustomSelectBox";
import { FinalCalednarasdmnf } from "../components/customTimeSeletor";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Dialog, DialogContent, Grid, Tab, Typography } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RxHamburgerMenu } from "react-icons/rx";
import NotificationToast from "src/Notification/NotificationToast";
import { useGetLocation } from "src/api/CourtSettings";
import { addThousandSeparator } from "src/constants/SportSEparator";
import MainContext from "src/context/MainContext";
import POSFilter from "src/features/POS/POSFilter";
import { FiPlus } from "react-icons/fi";
import Products from "src/features/POS/Products";
import { useGetAllPaymentMethodsByLoc } from "src/hooks/useGetAllPaymentMethods";
import MakeSale from "src/features/POS/MakeSale";
import { GetAllProductsSale } from "src/api/POS";
import TopUserInfo from "../components/TopUserInfo";
import Purchase from "src/features/POS/Purchase";
import StaffModal from "src/Notification/StaffModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const paymentsDesgin = {
  background: "white",
  borderRadius: "7px",
  padding: "20px 26px 20px 20px",
  gap: "5px",
};

const justifyContent = {
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  fontFamily: "var(--font-regular)",
  color: "#22356DB2",
  gap: "30px",
};

const selectedTab = {
  textDecoration: "underline",
  textDecorationColor: "#22356D",
  padding: "0px",
  textUnderlineOffset: "6px",
};

const headingStyle: any = {
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  textTransform: "capitalize",
  fontSize: { xs: "1.3rem", sm: "1.5rem" },
};

const unSelected: any = {
  opacity: "0.7",
  paddingLeft: "0",
  color: "#22356D99 !important",
  fontFamily: "var(--font-regular) !important",
  marginLeft: "15px",
};

export  function getTotalBookingPrice(data:any,name:string) {

  let total = 0;
  data?.forEach((item:any) => {
           if (item.paymentMethods?.method_name===name) {
              total += item.price;
           }
  });
  return total;
}

export  function getTotalBookingDiscount(data:any,name:string) {

  let total = 0;
  data.forEach((item:any) => {
           if (item.paymentMethods?.method_name===name) {
              total += item.discount;
           }
  });
  return total;
}

export  function getTotalBookingRefund(data:any,name:string) {

  let total = 0;
  data.forEach((item:any) => {
           if (item.refund_method===name) {
              total += item.refund_price;
           }
  });
  return total;
}



const POS = () => {
  const {t}  = useTranslation();
  const [value, setValue] = useState("1");
   const StaffPermission = useGetPermissions();
  const AllTotalPayment:Array<number> = [];
  const [open,setOpen] = useState(false);
  const [AllRecords,setAllRecords] = useState<any[]>([]);
  const { MobileSideBar, setMobileSideBar,SelectedLocation,setSelectedLocation } = useContext(MainContext);
  const [selectedDay, setSelectedDays] = useState<any>({
    date_start: moment(new Date()).format("YYYY-MM-DD"),
    date_end: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [showPR,setShowPR] = useState(false);
  const {
    data: PaymentMethods,
    isSuccess: PaymentMethodsSuccess,
    isError: PaymentMethodsError,
    setlocation_id: SetLocationID,
  } = useGetAllPaymentMethodsByLoc();
  const [AllPaymentMethods, SetAllPayemntMEthods] = useState<any[]>([]);
  const {
    data: locationdata,
    isError: locationerror,
    isSuccess: locationSucess,
  } = useGetLocation();
  const [AllLocations, setAllLocations] = useState<any>([]);
  // const [location, setLocation] = useState<any>();

  const openMobileSidebar = (event: any) => {
    event.stopPropagation();
    setMobileSideBar(true);
  };

  const handleSelectTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (locationSucess) {
      setAllLocations(locationdata);
     if(SelectedLocation?.id!==0){ 
      setSelectedLocation(SelectedLocation);
      SetLocationID(SelectedLocation?.id);
      return;
    }
      setSelectedLocation(locationdata[0]);
      SetLocationID(locationdata[0].id);

    } else {
      setAllLocations([]);
    }
  }, [locationSucess, locationdata, locationerror]);


  useEffect(() => {
    SetLocationID(SelectedLocation?.id);
  }, [SelectedLocation])
  

  useEffect(() => {
    if (PaymentMethodsSuccess) {
      SetAllPayemntMEthods(PaymentMethods);
    } else {
      SetAllPayemntMEthods([]);
    }
  }, [PaymentMethods, PaymentMethodsSuccess, PaymentMethodsError]);

  useEffect(() => {
   if(!!SelectedLocation){
    getProductSale(SelectedLocation?.id,selectedDay);
   }
  }, [SelectedLocation,selectedDay])

  const getProductSale = async(location_id:number,selectedDay:any)=>{
  try {
    const response = await GetAllProductsSale(location_id,selectedDay);
    const finaldata = response?.payments?.map((item:any)=>{
      const {customers,...newitem} =item;
      const fullName = !!customers ?`${customers?.first_name} ${customers?.last_name}`:null
      return {...newitem,fullName,customers}
    })
    setAllRecords(finaldata);
  } catch (error) {
    setAllRecords([])
    console.log(error)
  }
  }
  

  return (
    <Box>
      <TopUserInfo/>
    <Box
      sx={{
        padding: { xs: "20px", sm: "20px", md: "2.3rem" },
        minHeight: "100vh",
      }}
    >
      
      <Typography sx={{ textAlign: "center", ...headingStyle }}>POS</Typography>
      <NotificationToast />
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                cursor: "pointer",
              }}
            >
              <RxHamburgerMenu
                onClick={openMobileSidebar}
                className="MobileHamburger"
              />
            </Box>
            <TabList
              className="CoachSettingsTabs"
              onChange={handleSelectTab}
              aria-label="people list"
              TabIndicatorProps={{ sx: { backgroundColor: "#22356D" } }}
              sx={{
                ".Mui-selected": {
                  color: "#22356D !important",
                  fontFamily: "var(--font-semibold)",
                },
              }}
            >
              <Tab
                label={t(`title.Sales`)}
                value="1"
                sx={
                  value == "1"
                    ? { ...selectedTab, ...headingStyle }
                    : { ...headingStyle, ...unSelected }
                }
              />
              <Tab
                label={t(`title.Products`)}
                value="2"
                sx={
                  value == "2"
                    ? { ...selectedTab, ...headingStyle }
                    : { ...headingStyle, ...unSelected }
                }
              />
               <Tab
                label={t(`common.Purchase`)}
                value="3"
                sx={
                  value == "3"
                    ? { ...selectedTab, ...headingStyle }
                    : { ...headingStyle, ...unSelected }
                }
              />
              {
                value==="2" && <Button onClick={()=>setShowPR(true)} sx={{fontFamily:'var(--font-regular)',textTransform:'unset',color:'#22356D',height:'20px',marginTop:'20px'}}>{t('POS.Add new product')} <FiPlus style={{height:'10px'}}/></Button>
              }
            </TabList>
          </Box>
          {value == "1" && (
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                background: "white",
                borderRadius: "7px",
                padding: "5px",
                width: "30px",
              }}
              //   onClick={() => setShowModel(true)}
            >
              <img style={{ width: "30px" }} src="/images/spreadsheet.png" />
            </Box>
          )}
        </Box>
        <TabPanel
          value="1"
          sx={{
            padding: "0px 0",
            fontFamily: "var(--font-regular)",
            color: "#22356D",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "none", lg: "space-between" },
              alignItems: { xs: "flex-start", lg: "center" },
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 1, lg: "none" },
            }}
            marginTop={2}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              <Box sx={{ width: { xs: "120px", lg: "170px" } }}>
                <CustomSelectCheckBox
                  background={false}
                  text={"Select location"}
                  value={SelectedLocation}
                  setValue={setSelectedLocation}
                  arr={AllLocations}
                />
              </Box>
              <Box position={"relative"}>
                <FinalCalednarasdmnf
                  type={"range"}
                  containercss={{
                    width: "201px",
                    borderRadius: "7px",
                    backgroundColor: "white",
                    padding: "0px 4px 0px 0px",
                  }}
                  icon={true}
                  placeholder={"select days"}
                  selectedDay={selectedDay}
                  setSelectedDays={setSelectedDays}
                  style={{
                    width: "90%",
                    height: "33px",
                    color: "#22356D",
                    fontFamily: "var(--font-regular)",
                  }}
                />
              </Box>
            </Box>
            <Button
            onClick={()=>setOpen(true)}
              sx={{
                color: "white",
                backgroundColor: "#22356D",
                textTransform: "unset",
              }}
            >
              {t('POS.Make a Sale')}
            </Button>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
            <Grid container gap={5} marginTop={2}>
              <Grid item xs={12} sx={{ ...paymentsDesgin, padding: "20px" }}>
                <Box
                  sx={{
                    ...justifyContent,
                    borderBottom: "1px solid #22356D26",
                    fontFamily: "var(--font-medium)",
                  }}
                >
                  <p>{t('customers.Payment Method')}</p>
                  <p>{t('payments.Sales')}</p>
                  <p>{t('payments.Refunds')}</p>
                  <p>{t('payments.Discounts')}</p>
                  <p style={{ textAlign: "right" }}>{t('payments.Total Sales')}</p>
                </Box>

                {AllPaymentMethods?.map((item: any) => {
                  const SalesPayment = getTotalBookingPrice(AllRecords,item?.method_name);
                  const Refunds = getTotalBookingRefund(AllRecords,item?.method_name);
                  const discount = getTotalBookingDiscount(AllRecords,item?.method_name);
                  const TotalSales =
                    Number(SalesPayment) - Number(Refunds) - Number(discount);
                    AllTotalPayment.push(TotalSales);
                  return (
                    <Box
                      sx={{
                        ...justifyContent,
                        borderBottom: "1px solid #22356D12",
                      }}
                    >
                      <p>{item?.method_name}</p>
                      <p>
                        {addThousandSeparator(SalesPayment, SelectedLocation?.currency)}{" "}
                        {SelectedLocation?.currency}
                      </p>
                      <p>
                        {addThousandSeparator(Refunds, SelectedLocation?.currency)}{" "}
                        {SelectedLocation?.currency}
                      </p>
                      <p>
                        {addThousandSeparator(discount, SelectedLocation?.currency)}{" "}
                        {SelectedLocation?.currency}
                      </p>
                      <p style={{ textAlign: "right" }}>
                        {addThousandSeparator(
                          Number(0) + Number(TotalSales),
                          SelectedLocation?.currency
                        )}{" "}
                        {SelectedLocation?.currency}
                      </p>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontFamily: "var(--font-medium)",
                  }}
                >
                  <p>Total</p>
                  <p>{addThousandSeparator(AllTotalPayment?.reduce((item,acc)=>item+acc,0), SelectedLocation?.currency)} {SelectedLocation?.currency}</p>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <POSFilter
            allPayments={AllRecords}
            selectedLocation={SelectedLocation}
            setSelectedDays={setSelectedDays}
            update={()=>getProductSale(SelectedLocation?.id,selectedDay)}
          />
        </TabPanel>
        {/* ------ Product ----- */}
        <TabPanel
          style={{ color: "#22356D", padding: "0px" }}
          value="2"
          sx={{ padding: "0px 0",position:'relative' }}
        >
          <Products isAdd={showPR} setIsAdd={setShowPR} />
        </TabPanel>
        <TabPanel
          style={{ color: "#22356D", padding: "0px" }}
          value="3"
          sx={{ padding: "0px 0",position:'relative' }}
        >
          <Purchase />
        </TabPanel>
      </TabContext>
      <MakeSale selLocation={SelectedLocation} update={()=>getProductSale(SelectedLocation?.id,selectedDay)} selectedLocationID={SelectedLocation?.id} open={open} setOpen={setOpen}/>
    </Box>
    {!StaffPermission.edit_permissions && <StaffModal />}   
    </Box>
  );
};

export default POS;
