import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ServicesSpecialRow from "./ServicesSpecialRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  CreateBookingBysportID,
  DeleteBookingByID,
  GetBookingByID,
  updateBookingDataSettings,
} from "src/api/ServiceSettings";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Cleave from 'cleave.js/react';
import ConditionalDaysRow from "../../components/CourtSetting/ConditionalDaysRow";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useGetAllCustomersTypes } from "src/hooks/useGetAllCustomersTypes";
import MainContext from "src/context/MainContext";
import { useTranslation } from "react-i18next";
import { useGetAllMemeberShip } from "src/api/membership";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";
import DynamicPrice from "./DynamicPrice";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import { hours } from "src/constants/variables";
import RefundConditionRow from "./RefundConditionRow";

const maincss = {
  color: "#22356D",
  fontFamily: "var(--font-regular)",
  fontSize: "16px",
};
const smallborderInput = {
  height: "25px",
  width: "35px",
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
};

const righttext = {
  color: "rgba(34, 53, 109, 0.70)",
  fontFamily: "var(--font-regular)",
  fontSize: "14px",
};

type FormInputs = {
  price: number;
  duration: number;
  minimum_capacity: number;
  maximum_capacity: number;
  openMatch_price: number;
  dynamicPrice:boolean;
  openmatchPri:boolean;
  isCancellationPolicy:boolean;
  cancelhour:string;
  refundPolicy:refundpolicy[]
};

interface refundpolicy{
  refundPercent:string;
  payment_method:string;
  refund_hour:string
}

interface pricediff{
  price:number,
  name:string,
  openPrice:number
}


const BookingServices = ({
  BookingId,
  sport_id,
  handleClose,
  GetBookingDAta,
  selectedSport,
  selectedLocation
}: {
  BookingId: any;
  sport_id: any;
  handleClose: any;
  GetBookingDAta:any;
  selectedSport:any;
  selectedLocation:{id:number, location_name:string, currency:string}
}) => {
  const {t} = useTranslation();
   const StaffPermission = useGetPermissions();
  const { control:norcontrol,register, handleSubmit, watch, setValue } = useForm<FormInputs>({
    defaultValues:{
      refundPolicy:[]
    }
  });
  const { control, setValue:setCondiiton, watch:conditionWatch } = useForm();
  const [DiffeCourt,setDiffeCourt] = useState<pricediff[]>([]);
  const {data:customType,isSuccess:customerTypeSuccess,isError:customerTypeError} =useGetAllMemeberShip();
  const {Notification,setNotification,setIsPermissionOn} = useContext(MainContext);
  const [RefundIndex,setRefundIndex] = useState(1);
  const { fields, append, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "Rows",
    }
  );
  const conditionData = conditionWatch("Rows");
  const {control:SpecialControl,setValue:setSpecial, watch:specialWatch } = useForm<any>({});
  const { fields:FieldsSpecial,remove:removeSpecial,append:specailAppend ,insert:specialInsert} = useFieldArray<any>(
    {
      control: SpecialControl,
      name: "Special",
    }
  );
  const specialData = specialWatch("Special");
  const [servicesopen,setServicesOpen] = useState(false)
  const [allCustomerType,setAllCustomerType] = useState<any[]>([]);
  const [selectedcustomerType,setselectedcustomerType]= useState<any[]>([]);
  const [FirstisChecked, setFirstIsChecked] = useState(true);
  const [isAppAvail,setIsAppAvail] = useState(false);
  const {
    duration,
    minimum_capacity,
    maximum_capacity,
    price,
    openMatch_price,
    dynamicPrice,
    openmatchPri,
    cancelhour,
    isCancellationPolicy,
    refundPolicy
  } = watch();
  const [error, setError] = useState("");

  // Function to handle checkbox change
  const handleFirstCheckboxChange = () => {
    setFirstIsChecked(!FirstisChecked);
  };


  useEffect(() => {
    if(customerTypeSuccess){
      const filtered = customType?.map((item:any)=>{
        const {id,membership_name}=item;
        return {id,name:membership_name}
      })
      setAllCustomerType(filtered);
    }else{
      setAllCustomerType([])
    }
 }, [customType,customerTypeSuccess,customerTypeError]);
    

  useEffect(() => {
    GetData();
  }, [BookingId]);

  //set the data coming from api
  const GetData = async () => {
    remove();
    removeSpecial();
    if (BookingId !== undefined && sport_id == undefined) {
      const response = await GetBookingByID(BookingId);
      setselectedcustomerType([])
      setIsAppAvail(response?.app_available)
      setValue("duration", response?.duration);
      setValue('dynamicPrice',false);
      setValue("price", response?.price);
      setValue('openmatchPri',false);
      setValue("minimum_capacity", response?.minimum_capacity);
      setValue("maximum_capacity", response?.maximum_capacity);
      setIsAppAvail(response?.app_available)
     if(!response?.is_open_match){
      setFirstIsChecked(false)
     }else{
      setFirstIsChecked(true)
      response?.open_match_price!==response?.price && setValue("openMatch_price", response?.open_match_price);
     }
      const finalCondition = response?.conditional_days?.map(
        (item: any, index: number) => {
          let { days, memberships,all_user,...newitem } = item;
          const filterMember = memberships?.map((mem:any)=>{
            const {id,membership_name} = mem;
            return {id,name:membership_name}
          })
          const id = index;
          days !== null ? (days = [days]) : (days = undefined);
          setselectedcustomerType((prev:any)=>[...prev,filterMember]);
          append({days,all_user, ...newitem })
          return { id, days, ...newitem };
        }
      );
      // const finalSpecial = response?.special_days?.map(
      //   (item: any, index: number) => {
      //     let { days,...newitem } = item;
      //     const id = index;
      //     days !== null ? (days = [days]) : (days = undefined);
      //     specialInsert(index,{days,...newitem})
      //     return { id,days, ...newitem };
      //   }
      // );
    }
  };


  useEffect(() => {
    if(dynamicPrice){
      const updateData = Array.from({ length: maximum_capacity }, (_, index) => ({ price: 0, name: `Court ${index + 1}`,openPrice:0 }));
      setDiffeCourt(updateData);
    }
  }, [dynamicPrice,maximum_capacity])
  



 
  useEffect(()=>{
    selectedcustomerType?.map((item:any,index)=>{
    const isAllUser = conditionData[index].all_user;
    const AllMemebrship = isAllUser ? [{id:0,name:'All User'},...item]:item;
      setCondiiton(`Rows.${index}.memberships`,AllMemebrship)
    })
  },[selectedcustomerType])



  const handleAddSpecial = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      break_times:[],
    };
    specailAppend(final);
  };


  //send data to backend
  const APIData = {
    duration: duration,
    minimum_capacity: minimum_capacity,
    maximum_capacity: maximum_capacity,
    price: price,
    is_open_match: FirstisChecked,
    app_available:isAppAvail,
    open_match_price:
      FirstisChecked && openMatch_price ? openMatch_price : undefined,
    conditional_days: conditionData?.map((item: any) => {
      let { days, date_start, date_end,memberships, ...newitem } = item;
      days = days?.length !== 0 ? days : undefined;
      date_start =
        date_start !== undefined && date_start !== null
          ? date_start
          : undefined;
      date_end =
        date_end !== undefined && date_end !== null ? date_end : undefined;
      const all_user = item.memberships?.some((mem:any)=>mem.name==="All User");
        memberships=item.memberships?.filter((mem:any)=>mem?.name!=="All User")?.map((item:any)=>item.id)
      return { date_start, date_end, days, ...newitem,membership:memberships,all_user};
    }),
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }

    if (!duration) {
      setError("duration");
      setTimeout(() => setError(""), 2000);
    } else if (!minimum_capacity) {
      setError("minimum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!maximum_capacity) {
      setError("maximum capacity");
      setTimeout(() => setError(""), 2000);
    } else if (!price) {
      setError("price");
      setTimeout(() => setError(""), 2000);
    } else if (Number(minimum_capacity) > Number(maximum_capacity)) {
      setError("capacity");
      setTimeout(() => setError(""), 2000);
    }else {
      setError("");
      BookingId !== undefined ? updateBooking() : createBooking();
    }
  };


  const updateBooking = async () => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }

    if (BookingId !== undefined && sport_id == undefined) {
     try {
      const response = await updateBookingDataSettings(BookingId, {...APIData, "app_available": isAppAvail});
      if(response){
         setNotification({message:response,type:'success',subMessage:'',isShow:true})
        GetBookingDAta(selectedSport?.id);
        handleClose();
      }
     } catch (error) {
       // setNotification({message:response,type:'success',subMessage:'',isShow:false})
     }
    }
  };

  const handleDelete = async () => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }

    if (BookingId !== undefined) {
      try {
        const response = await DeleteBookingByID(BookingId);
      if(response){
        setNotification({message:response,type:'success',subMessage:'',isShow:true})
        GetBookingDAta(selectedSport?.id);
        handleClose();
      }
      } catch (error) {
        // setNotification({message:response,type:'success',subMessage:'',isShow:true})
      }
    }
  };

  const createBooking = async () => {

    if(!StaffPermission.edit_permissions){
      setIsPermissionOn(true);
      handleClose()
      return;
    }
    
    if (sport_id !== undefined) {
      try {
        const response = await CreateBookingBysportID(sport_id, {...APIData, "app_available": isAppAvail});
      if(response){
        setNotification({message:response,type:'success',subMessage:'',isShow:true})
        GetBookingDAta(selectedSport?.id);
        handleClose();
      }
      } catch (error) {
        // setNotification({message:response,type:'success',subMessage:'',isShow:true})
      }
    }
  };

  
  const handleAddCondition = () => {
    const final = {
      start_time: "08:00:00",
      end_time: "22:00:00",
      memberships: [],
      // start_date: "",
      // end_date: "",
      // days: [],
      price: "",
    };
    append(final);
  };

  const handleUpdateForm = (data: any, index: number) => {
    setCondiiton(`Rows.${index}.start_time`, data[0].start_time);
    setCondiiton(`Rows.${index}.end_time`, data[0].end_time);
    const updatedObj = conditionData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      delete updatedObj["date_end"];
      delete updatedObj["date_start"];
      setCondiiton(`Rows.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      delete updatedObj["days"];
      setCondiiton(`Rows.${index}.date_start`, data[0].date_start);
      setCondiiton(`Rows.${index}.date_end`, data[0].date_end);
    }
  };

  
  const handleUpdateSpecial = (data: any, index: number) => {
    setSpecial(`Special.${index}.start_time`, data[0].start_time);
    setSpecial(`Special.${index}.end_time`, data[0].end_time);
    const updatedObj = conditionData[index];
    if (
      data[0].days !== undefined &&
      data[0].date_start == undefined &&
      data[0].date_end == undefined
    ) {
      setSpecial(`Special.${index}.days`, data[0].days);
    } else if (
      data[0]?.date_end !== undefined &&
      data[0]?.date_start !== undefined &&
      data[0]?.days === undefined
    ) {
      setSpecial(`Special.${index}.date_start`, data[0].date_start);
      setSpecial(`Special.${index}.date_end`, data[0].date_end);
    }
  };


  function updateField(array:any, index:number, newValue:number,newName:string) {
    if (index >= 0 && index < array.length) {
        array[index][newName] = newValue;
    }
}

const addrefundField = () => {
  const currentFields = watch('refundPolicy') || [];
  const updatedFields = [...currentFields, {   refundPercent:'',
    payment_method:'',
    refund_hour:''}];
  setValue('refundPolicy', updatedFields);
};

  return (
    <Box
      sx={{background:"white", padding: {xs:"0px", sm:"20px 16px 0px 16px"}, borderRadius: "7px" }}
      className="bg-white"
    >
      <span
        style={{
          marginBottom: "10px",
          color: "#22356D",
          fontFamily: "var(--font-semibold)",
          fontSize: "16px",
          borderBottom: "1px solid #22356D",
        }}
      >
       {t('services.bookings.Booking Settings')}
      </span>
      <form onSubmit={handleFormSubmit}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <h3
            className="fontSize"
            style={{
              marginTop: "10px",
              color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "16px",
            }}
          >
            {t('services.bookings.Open Match available')}
          </h3>
          <label style={{ marginTop: "4px" }} className="switch">
            <input
              checked={FirstisChecked}
              onChange={handleFirstCheckboxChange}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        <Box
          sx={{ marginTop: "15px", display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:"center", marginBlock:'10px' }}
        >
          <p style={maincss} className="fontSize">{t('services.bookings.Duration')}<span style={{color:'red'}}> *</span></p>
          <Box style={righttext}>
            <Box> 
            <input
              placeholder="10"
              id={error == "duration" ? "customerror" : ""}
              {...register("duration", { required: true })}
              type="number"
              style={{ ...smallborderInput, width: "51px" }}
            />{" "}
            {t('common.minutes')}
            {/* {error === "duration" && (
                 
                )} */}
                
                </Box>
          </Box>
        </Box>
        <div
          style={{ marginTop: "15px" }}
          className="flex flex-row justify-between items-center my-3"
        >
          <p style={{...maincss}} className="fontSize">{t('services.bookings.Booking Capacity')}<span style={{color:'red'}}> *</span></p>
          <div style={righttext}>
           <Box>
            {t('common.min')} <input
              type="number"
              placeholder="1"
              id={error == "minimum capacity" ? "customerror" : ""}
              {...register("minimum_capacity", { required: true })}
              style={smallborderInput}
            />{" "}
            {t('common.max')} <input
              type="number"
              placeholder="4"
              id={error == "maximum capacity" ? "customerror" : ""}
              {...register("maximum_capacity", { required: true })}
              style={smallborderInput}
            />
             {/* {(error === "minimum capacity" || error === "maximum capacity") && (
                 
                )} */}
                </Box>
          </div>
        </div>
        { error === "capacity" && <span style={{ fontFamily: "var(--font-regular)",color:'red',fontSize:'13px',textAlign:'right'}}>{t('services.bookings.minimum capacity must be less than maximum capacity')}</span>}
        <Box
          sx={{ marginTop: "15px",display:'flex',flexDirection:{xs:"column",md:'row'},justifyContent:{xs:'unset',md:'space-between'} ,gap:'5px'}}
        >
          <Box style={{...maincss,display:'flex',flexDirection:'row',alignItems:'center'}} className="fontSize">{t('common.Dynamic Price')}<span style={{color:'red'}}> *
            </span>  <label style={{marginLeft:'5px'}} className="switch">
                <input 
                checked={dynamicPrice}
                onChange={()=>setValue('dynamicPrice',!dynamicPrice)}
                type="checkbox"
              />
              <span className="slider round"></span>
            </label></Box>
          <Box style={{...maincss,display:'flex',flexDirection:'row',alignItems:'center'}} className="fontSize">{t('services.bookings.Open Match Special Price')}
             <label style={{marginLeft:'5px'}} className="switch">
                <input
                checked={openmatchPri}
                onChange={()=>setValue('openmatchPri',!openmatchPri)}
                type="checkbox"
              />
              <span className="slider round"></span>
            </label></Box>
            
        </Box>
       
{!dynamicPrice &&
      <Box sx={{marginTop:'5px',display:'flex',flexDirection:'column',gap:'5px'}}>
              
                  <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <Typography sx={{fontFamily:'var(--font-regular)',marginLeft:'15px',fontSize:'14px'}}>All Courts Price</Typography>
                <Box width={"150px"} sx={{display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                <Controller
                      name="price"
                      control={norcontrol}
                      render={({ field: { onChange, value } }) => (
                        <Cleave
                        id={error == "price" ? "customerror" : ""}
                        style={{ ...smallborderInput, width: "89px" }}
                        onChange={(event:any) => onChange(event.target.rawValue)}
                        value={value}
                        className="input-price"
                        placeholder="100"
                        options={{
                            numeral: true,
                            numeralThousandsGroupStyle: 'thousand',
                            delimiter:selectedLocation.currency === 'IDR' ?'\u2024':','
                        }}
                    />
                      )}
                    /> 
                        <p style={{color: "#22356D",
                      fontFamily: "var(--font-regular)",
                      fontSize: "14px"}}>{selectedLocation.currency}</p>
                </Box>
                </Box>
                <Box sx={{display:openmatchPri?'flex':"none",flexDirection:'row',justifyContent:'space-between'}}>
                <Typography sx={{fontFamily:'var(--font-regular)',marginLeft:'15px',fontSize:'14px'}}>Open Match Price</Typography>
                <Box width={"150px"} sx={{display: "flex",alignItems: "center",justifyContent: "space-between"}}>
                <Controller
                    name="openMatch_price"
                    control={norcontrol}
                    render={({ field: { onChange, value } }) => (
                      <Cleave
                      style={{ ...smallborderInput, width: "89px" }}
                      onChange={(event:any) => onChange(event.target.rawValue)}
                      value={value}
                      className="input-price"
                      placeholder="100"
                      options={{
                          numeral: true,
                          numeralThousandsGroupStyle: 'thousand',
                          delimiter:selectedLocation.currency === 'IDR' ?'\u2024':','
                      }}
                  />
                    )}
                  />
                   <p style={{color: "#22356D",
                      fontFamily: "var(--font-regular)",
                      fontSize: "14px"}}>{selectedLocation.currency}</p>
                </Box>
                </Box>
      </Box>}

    {dynamicPrice && <Box sx={{display:'flex',flexDirection:"column",gap:'5px'}}>
          {
             DiffeCourt?.map((item:pricediff,index:number)=><DynamicPrice type="Booking" isOpenMatch={openmatchPri} callback={(value:number,key:string)=>updateField(DiffeCourt,index,value,key)} data={item} index={index} selectedlocation={selectedLocation}/>)
          }
    </Box>}

        {/* <Grid marginTop={"25px"}>
          <Grid container alignItems={"center"} gap={"15px"}>
            <h3
              className="fontSize"
              style={{
                color: "#22356D",
                fontFamily: "var(--font-regular)",
                fontSize: "16px",
              }}
            >
              {t('courts.Special Days')}
            </h3>
            <Button
              onClick={handleAddSpecial}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                textTransform: "none",
              }}
            >
              {t('courts.Add special day')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
          </Grid>
          <div className="flex flex-col my-1">
            {FieldsSpecial?.length !== 0 &&
          FieldsSpecial?.map((item: any, index: any) => (
                  <ServicesSpecialRow
                  remove={removeSpecial}
                  handleUpdateSpecial={handleUpdateSpecial}
                    preSpecialDays={specialData}
                    key={index}
                    index={index}
                    setSpecial={setSpecial}
                  />
                ))}
          </div>
        </Grid> */}

        <Grid style={{ margin: "25px 0" }}>
          <Grid container>
            <Grid container alignItems={"center"} gap={"15px"}>
              <h3
                style={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "16px",
                }}
                className="fontSize"
              >
                {t('services.events.Discount Conditions')}
              </h3>
              <Button
                onClick={handleAddCondition}
                style={{
                  color: "rgba(34, 53, 109, 0.70)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  gap: "10px",
                  textTransform: "none",
                }}
              >
                {t('services.bookings.Add Condition')}
                <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
              </Button>
            </Grid>
            <Box sx={{ margin: {xs:"0px", sm:"0 5px 0 20px"}, width: "100%",display:'flex',flexDirection:'column',gap:'10px' }}>
              {fields?.length !== 0 &&
                fields?.map((item: any, index: any) => (
                  <ConditionalDaysRow
                  setServicesOpen={setServicesOpen}
                  customer_type={allCustomerType}
                  conditionData={conditionData}
                  handleUpdateForm={handleUpdateForm}
                  selectedlocation={selectedLocation}
                  setValue={setCondiiton}
                  index={index}
                  remove={remove}
                  />
                ))}
            </Box>
          </Grid>
        </Grid>

        <Box
          sx={{
            width: "max-content",
            margin: "10px 0",
            color: "#22356D",
            fontFamily: "var(--font-semibold)",
            fontSize: "16px",
            borderBottom: "1px solid #22356D",
          }}
        >
          {t('courts.App Settings')}
        </Box>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center', gap:'10px'}}>
          <Typography sx={{ color: "#22356D",
            fontFamily: "var(--font-regular)",
            fontSize: "16px",}}>Available in App</Typography>
        <label className="switch">
            <input
              checked={isAppAvail}
              onChange={()=>setIsAppAvail(!isAppAvail)}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </Box>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center', gap:'10px',marginTop:'10px'}}>
          <Typography sx={{ color: "#22356D",
            fontFamily: "var(--font-regular)",
            fontSize: "16px",}}>Cancellation Policy</Typography>
        <label className="switch">
            <input
            {...register('isCancellationPolicy')}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </Box>
        <Box>
         {isCancellationPolicy ? <Box sx={{display:'flex',flexDirection:{xs:'column',md:'row'},alignItems:{xs:'start',md:'center'}, gap:'10px'}}>
            <Typography sx={{ color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",opacity:"0.8",}}>Users can cancell upto </Typography>
                <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
                <Select
                  value={cancelhour?cancelhour:'Select'}
                  onChange={(e:any)=>setValue('cancelhour',e.target.value)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value} hours`;
                    }
                    return <em>Select</em>;
                  }}
                  sx={{
                    width: {xs:"100%", md:"120px"},
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {hours?.map((location: any) => (
                    <MenuItem
                      key={location}
                      value={location}
                    >
                      {location} hours
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Typography sx={{ color: "#22356D",
              fontFamily: "var(--font-regular)",
              fontSize: "14px",opacity:"0.8",}}>before the booking starts</Typography>
          </Box>:<Typography sx={{color: "#22356D",
              fontFamily: "var(--font-regular)",opacity:"0.8",
              fontSize: "14px",marginY:'5px'}}>Users can cancell anytime before the bookings starts.</Typography>}
        </Box>
        <Grid container mt={'10px'}>
            <Grid container alignItems={"center"} gap={"15px"}>
              <h3
                style={{
                  color: "#22356D",
                  fontFamily: "var(--font-regular)",
                  fontSize: "16px",
                }}
                className="fontSize"
              >
                Refund Conditions
              </h3>
              <Button
                onClick={addrefundField}
                style={{
                  color: "rgba(34, 53, 109, 0.70)",
                  fontFamily: "var(--font-regular)",
                  fontSize: "14px",
                  gap: "10px",
                  textTransform: "none",
                }}
              >
                {t('services.bookings.Add Condition')}
                <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
              </Button>
            </Grid>
          <Box sx={{display:'flex',flexDirection:'column',gap:'10px'}}>
            {

            refundPolicy?.length>0 ? refundPolicy?.map((_,index)=><RefundConditionRow data={refundPolicy} setValue={setValue} index={index}/>):<Typography sx={{color: "#22356D",
              fontFamily: "var(--font-regular)",opacity:"0.8",
              fontSize: "14px",marginY:'5px'}}>There is no refund  upon cancellation</Typography>

            }
            </Box>
        </Grid>
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={servicesopen ?"200px":"20px"}
        >
          <Button
            onClick={BookingId !== undefined ? handleDelete : handleClose}
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              width: "104px",
              height: "37px",
              color: "#22356D",
              backgroundColor: "white",
              fontSize: "16px",
              padding: "10px 30px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
            }}
          >
            {BookingId !== undefined ? t('common.Delete') : t('common.Cancel')}
          </Button>
          <Button
            type="submit"
            style={{
              textTransform: "unset",
              fontFamily: "var(--font-semibold)",
              color: "white",
              backgroundColor: "#22356D",
              width: "92px",
              height: "37px",
              fontSize: "16px",
            }}
          >
            {t('common.Save')}
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default BookingServices;
