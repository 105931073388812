import React, { useEffect, useState } from "react";
import { activeColor } from "src/constants/color";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddPlayer from "./Calendar/AddPlayer";
import "./tableStyling.css";
import ScoreEdit from "./ScoreEdit";
import { useFieldArray, useForm } from "react-hook-form";

const scoreStyle={
    // borderRadius: '4px',
    border:'1px solid #939393',
    width:'20px',
    height:'20px',
    textAlign:"center",
    fontSize:{xs:"0.8rem", sm:"0.9rem"},
    // backgroundColor:'white'
}

  let players = [
    {
      label:"Joaquin Reyes"
    },
    {
      label:"Stella Wilson"
    },
    {
      label:"Murphy Wilson"
    }
  ]

const ScoreList = ({selectAll, handleMasterCheckboxChange, userData, selectedOpenRegular, handleCheckboxChange}:{selectAll: any, handleMasterCheckboxChange: any, userData: any, selectedOpenRegular: string,handleCheckboxChange: any}) => {
    const [addPlayer, setAddPlayer] = useState<string>('');
    const [showList, setShowList] = useState<boolean>(false);
    const [data, setData] = useState(players);
    const {register,setValue,watch,control} =useForm();
    const {append,fields,remove}= useFieldArray({control,name:'score'});
    const fieldarray  = watch('score')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value
    setAddPlayer(val);
    setShowList(true);
    let arr = userData.filter((item:any)=>{
      let name = item.label.toLowerCase();
      return name.includes(val.toLowerCase())
    })
    setData(arr);
  }

  const selectedUser = (item: any)=>{
    setShowList(false);
    setAddPlayer(item.label);
  }
  useEffect(() => {
    const length = userData?.length;
    Array(length).fill("-")?.map((item:any)=>{
      append({position1:'5',position2:'6',position3:'9'})
    })

  }, [userData])
  

  useEffect(()=>{
    document.addEventListener("click",()=>{
      setShowList(false);
    })
  },[])

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <AddPlayer addPlayer={addPlayer} handleChange={handleChange} showList={showList} setShowList={setShowList} data={data} selectedUser={selectedUser} />
            </Box>
            {/* ----- player list ----- */}
            {userData.length>0 ? 
            <Box height={userData.length>3?'288px':'auto'} id="table-person">
                <Box className="waitingList">
                    {userData.map((user: any, index: number) => (
                       <ScoreEdit setValue={setValue} scoredata={fieldarray} index={index} user={user}/>
                    ))}
                </Box>
            </Box>
            :
            <p style={{color: activeColor, marginBottom:"40px", marginTop:"7px"}}>There is no Waiting List</p>}
        </>
      )
}

export default ScoreList