import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import VoucherRow from "src/features/Payments/VocherRows";
import { useGetLocation } from "src/api/CourtSettings";
import CustomSelectDropdown from "../../components/CustomSelectDropdown";
import VoucherSettingsPopup from "src/features/Payments/VoucherSettingsPopup";
import { GetVoucherSale, useGetAllVouchers, useGetAllVouchersByClub } from "../../../src/api/Voucher";
import MainContext, { locationTypes } from "src/context/MainContext";
import CustomSelectCheckBox from "../../components/CustomSelectBox";
import { useTranslation } from "react-i18next";
import { FinalCalednarasdmnf } from "../../components/customTimeSeletor";
import moment from "moment";
import { addThousandSeparator } from "src/constants/SportSEparator";
import Cleave from "cleave.js/react";
import StaffModal from "src/Notification/StaffModal";
import { Permission, useGetPermissions } from "src/api/CalendarBooking";

const underlineCss = {
  marginBottom: "10px",
  color: "#22356D",
  fontFamily: "var(--font-semibold)",
  fontSize: "16px",
  borderBottom: "1px solid #22356D",
};

const paymentsDesgin = {
  background:"white", 
  borderRadius:'7px', 
  padding:"20px 26px 20px 20px", 
  gap:'5px'
}

const justifyContent = {
  display:"grid",
  gridTemplateColumns:"repeat(4, 1fr)",
  fontFamily:"var(--font-regular)",
  color:"#22356DB2",
  gap:'30px'   
}

const justifyContent23 = {
  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"space-between",
  fontFamily:"var(--font-regular)",
  color:"#22356DB2",
  gap:'30px'   
}


const Voucher = () => {
  const [view, setView] = useState([]);
  const {t} = useTranslation();
   const StaffPermission = useGetPermissions();
  const {Notification,setNotification,SelectedLocation:AccountingLocation,setSelectedLocation:setAccountingLocation} = useContext(MainContext);
  const [AllSales,setAllSales] = useState<any[]>([]);
  const [selectedDay,setSelectedDays] = useState<any>({date_start:moment(new Date()).format('YYYY-MM-DD'),date_end:moment(new Date()).format('YYYY-MM-DD')});
  const [selectedLocation,setSelectedLocation] = useState<locationTypes>({ id: 0, location_name: "All locations",currency:"IDR" });
  const [showVoucherSettings, setShowVoucherSettings] = useState(false);
  const [allVouchers, setAllVouchers] = useState<any[]>([]);
  const [clickedData, setClickedData] = useState<any>();
  const [AccountingFiltered, setAccountingFiltered] = useState<any>([]);
  const [filterdVouchers, setfilterdVouchers] = useState<any>([]);
  // const [AccountingLocation,setAccountingLocation] = useState<locationTypes | any>();
  const AllTotalRight:any= [];
  const AllTotalPrice :any= []

  const {data:locationdata,isLoading:locationloading,isError:locationerror,isSuccess:locationSucess} = useGetLocation();

  const { data, isLoading, isSuccess, isError, refetch } = useGetAllVouchersByClub();


  useEffect(() => {
    if(locationSucess && !locationerror){
      setView(locationdata);
    }else{
      setView([]);
    }   
  }, [locationdata,locationerror,locationSucess]);

  const GetallVoucher = async()=>{
    try {
      const response =await GetVoucherSale(selectedDay);
    const filtered = response?.vouchers?.map((item:any)=>{
      const {voucher,method} = item;
      const voucherName = voucher?.voucher_name;
      const price =voucher?.price;
      const value =  voucher?.value;
      const paymentMethod = method?.method_name
      return {voucherName,price,paymentMethod,value}
    });
    const filteredManual = response?.manualCredits?.filter((item:any)=>item?.credit_type==="Vouchers")?.map((item:any)=>{
      const {method,price,value} = item;
      const voucherName = "Manual Voucher";
      const paymentMethod = method?.method_name
      return {voucherName,price,paymentMethod,value}
    })
    setAllSales([...filtered,...filteredManual])
    } catch (error) {
      setAllSales([])
    }
  }


  useEffect(() => {
    GetallVoucher();
  }, [selectedDay])
  

    //for membership
    useEffect(() => {
      if(selectedLocation.location_name!== "All locations"){
        const filtered = allVouchers?.filter((item:any)=>item.locations?.some((loc:any)=>loc.id===selectedLocation.id));
        console.log(filtered,'filtetete')
        setfilterdVouchers(filtered)
      } else{
        setfilterdVouchers(allVouchers)
      } 
      }, [selectedLocation,allVouchers])
    
  //for accounting
    useEffect(() => {
      const filtered = allVouchers?.filter((item:any)=>item.locations?.some((loc:any)=>loc.id===AccountingLocation.id))
      setAccountingFiltered(filtered)
    }, [AccountingLocation,allVouchers])
    



  useEffect(() => {
    if (isSuccess) {
      setAllVouchers(data);
    } else if (isError) {
      setAllVouchers([]);
    }
  }, [isSuccess,isError,data]);


  const handleClose = () => {
    setShowVoucherSettings(false);
  };

  const handleClick = (data: any) => {
    if (data === true) {
      setClickedData("");
      setShowVoucherSettings(true);
    } else {
      setClickedData(data);
      setShowVoucherSettings(true);
    }
  };

  const getTotalPrice=(data:any,voucher_name:string,payment_method:string)=>{
   const total = data?.filter((item:any)=>item?.paymentMethod?.toLowerCase() === payment_method?.toLowerCase() && item?.voucherName===voucher_name)?.reduce((acc:any,item:any)=>acc+item?.price,0);
   return total
  }

  const TotalCreditAwarded = (data:any,voucher_name:string)=>{
   
      const total = data?.filter((item:any)=> item?.voucherName===voucher_name)?.reduce((acc:any,item:any)=>acc+item?.value,0);

      return total
  }

  const manualCredit = getTotalPrice(AllSales,'Manual Voucher',"credit card");
  const manualCash = getTotalPrice(AllSales,'Manual Voucher',"cash");
  const ManualCreditRight = TotalCreditAwarded(AllSales,"Manual Voucher")
// const TotalPRice = AllTotalPrice?.reduce((acc:any,item:any)=>acc+item,0)+manualCash+manualCredit;
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Box sx={{display:'flex',flexDirection:'row',gap:'10px'}} marginY={"20px"}>
        <Box sx={{width:'150px'}}>
        <CustomSelectCheckBox
          background={false}
          text={"Select location"}
          value={AccountingLocation}
          setValue={setAccountingLocation}
          arr={view}
        />
        </Box>
        <Box width={"201px"} position={'relative'}>
            <FinalCalednarasdmnf type={"range"} containercss={{width:'201px',borderRadius:'7px',backgroundColor:'white',padding:'0px 4px 0px 0px'}} icon={true} placeholder={"select days"} selectedDay={selectedDay} setSelectedDays={setSelectedDays} style={{width:"90%", height:"33px",color:"#22356D",fontFamily:"var(--font-regular)"}}/>
        </Box>
      </Box>
      <Box sx={{display:{xs:'none', sm:"none", md:"block"},marginBottom:'20px'}}>
                  <Grid container gap={5} marginTop={2}>
                     <Grid item xs={7} sx={{...paymentsDesgin,padding:'20px'}}>
                        <Box sx={{...justifyContent,borderBottom:"1px solid #22356D26", fontFamily:"var(--font-medium)",}}>
                           <p>{t('payments.Voucher Name')}</p>
                           <p>{t('payments.Paid by Credit Card')}</p>
                           <p>{t('payments.Paid by Cash')}</p>
                           <p style={{textAlign:'right'}}>{t('payments.Total Sales')}</p>
                        </Box>
                        {AccountingFiltered?.map((item:any)=> {
                           const TotalSalesByCredit = getTotalPrice(AllSales,item?.voucher_name,"credit card");
                           const TotalSalesByCash = getTotalPrice(AllSales,item?.voucher_name,"Cash");
                           const TotalSales = TotalSalesByCash+TotalSalesByCredit;
                           AllTotalPrice.push(TotalSales);

                           return <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>
                            <p>{item?.voucher_name}</p>
                            <p>{addThousandSeparator(TotalSalesByCredit,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                            <p>{addThousandSeparator(TotalSalesByCash,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                            <p style={{textAlign:'right'}}>{addThousandSeparator(TotalSales,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                         </Box>
                           }
                        )} 
                        <Box sx={{...justifyContent, borderBottom:"1px solid #22356D12"}}>  
                           <p>Manual Voucher</p>
                           <p>{addThousandSeparator(manualCredit,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                           <p>{addThousandSeparator(manualCash,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                           <p style={{textAlign:'right'}}> {addThousandSeparator(manualCredit+manualCash,AccountingLocation?.currency)}</p>
                        </Box>
                        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', fontFamily:"var(--font-medium)"}}>  
                           <p>Total</p>
                           <p>{addThousandSeparator(AllTotalPrice?.reduce((acc:any,item:any)=>acc+item,0)+manualCash+manualCredit,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                        </Box>

                     </Grid>
                     <Grid item xs={4.5} sx={{...paymentsDesgin,padding:'20px'}}>
                        <Box sx={{...justifyContent23,borderBottom:"1px solid #22356D26", fontFamily:"var(--font-medium)"}}>
                           <p>{t('customers.Voucher Name')}</p>
                           <p>{t('payments.Total Credits Award')}</p>
                        </Box>
                        {
                          AccountingFiltered?.map((item:any)=>
                          {

                            const VoucherCredit = TotalCreditAwarded(AllSales,item?.voucher_name);
                            
                            AllTotalRight.push(VoucherCredit);

                            return <Box sx={{...justifyContent23, borderBottom:"1px solid #22356D12"}}>
                          <p>{item?.voucher_name}</p>
                          <p>{addThousandSeparator(VoucherCredit,AccountingLocation?.currency)}</p>
                       </Box>
                          })
                        }
                        
                        <Box sx={{...justifyContent23, borderBottom:"1px solid #22356D12"}}>
                           <p>Manual Voucher</p>
                           <p>{addThousandSeparator(ManualCreditRight,AccountingLocation?.currency)}
                           </p>
                        </Box>
                        <Box sx={{...justifyContent23, fontFamily:"var(--font-medium)"}}>  
                           <p>Total</p>
                           <p>{addThousandSeparator(AllTotalRight?.reduce((acc:any,item:any)=>acc+item,0)+ ManualCreditRight,AccountingLocation?.currency)} {AccountingLocation?.currency}</p>
                        </Box>
                     </Grid>
                  </Grid>
      </Box>
      <Box sx={{width:'180px',marginBottom:'20px'}}>
        <CustomSelectCheckBox
          background={false}
          text={"Select location"}
          value={selectedLocation}
          setValue={setSelectedLocation}
          arr={[{id:0,location_name:'All locations',currency:'IDR'},...view]}
        />
        </Box>
      <Box
        sx={{background:'white', overflow:'hidden', borderRadius:'7px', padding:{xs:"23px", sm:"23px", md:"40px 40px 20px"}}}
      >
        <Grid>
          <span style={underlineCss}>{t('title.Vouchers')}</span>
          <Box marginTop={"20px"}>
            <Button
              onClick={() => handleClick(true)}
              style={{
                color: "rgba(34, 53, 109, 0.70)",
                fontFamily: "var(--font-regular)",
                fontSize: "14px",
                gap: "10px",
                paddingLeft: "0px",
                textTransform: "none",
              }}
            >
              {t('common.Add new voucher')}
              <AddIcon style={{ opacity: 0.7 }} fontSize="small" />
            </Button>
            <Box>
              <Box
                style={{
                  marginTop: "10px",
                  borderRadius: "7px",
                  boxShadow: "inset 2px 2px 10px rgba(0, 0, 0, 0.1)",
                  background:
                    "linear-gradient(0deg, rgba(238, 239, 238, 0.05) 0%, rgba(238, 239, 238, 0.05) 100%), #FFF",
                  border: "0.5px solid #EEEFEE",
                }}
              >
                <Box
                  id={"table-person"}
                  className="overflow-hidden"
                  style={{
                    marginTop: "0px",
                    width: "-webkit-fill-available",
                    overflowY: "auto",
                  }}
                >
                  {filterdVouchers?.length > 0 ? (
                    filterdVouchers?.map((item: any, index: number) => (
                      <div key={index} onClick={() => handleClick(item)}>
                        <VoucherRow
                          alllocations={view}
                          data={item}
                          index={index}
                        />
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        backgroundColor: "rgba(136, 154, 182, 0.10)",
                        padding: "8px 10px",
                      }}
                      className=""
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        margin={"5px"}
                      >
                        <Typography
                          sx={{
                            color: "#22356D",
                            fontFamily: "var(--font-semibold)",
                            fontSize: "14px",
                          }}
                        >
                          {t('common.No Vouchers Found')}
                        </Typography>
                      </Box>
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
      {!StaffPermission.edit_permissions && <StaffModal />}
      <Dialog
        sx={{
          ".MuiDialog-paper": {
            width: "900px",
            height: "auto",
          },
        }}
        onClose={handleClose}
        open={showVoucherSettings}
      >
        <DialogContent style={{ marginTop: "0px" }} id="table-person">
          <VoucherSettingsPopup
            handleClose={handleClose}
            getVouchers={refetch}
            locationdata={view}
            data={clickedData}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Voucher;
