import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { dopdowncustomcss } from "src/constants/dropdownCSS";
import React, { useCallback, useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Cleave from "cleave.js/react";
import { Box } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import MyDropdown from "../../components/Payment/LocationSelectCheckBox";


const borderInput = {
  padding: "5px 8px",
  fontSize: "14px",
  border: "1px solid #EEEFEE",
  borderRadius: "7px",
  color: "#22356D",
  height: "24px",
};

const EventDiscountCondition = ({
  index,
  preData,
  setValue,
  selectedlocation,
  allMembership,
  remove
}: {
  index: number;
  preData: any;
  setValue: any;
  allMembership:any[];
  remove:any;
  selectedlocation:{id:number, location_name:string, currency:string}
}) => {
  const [selectedcustomerType,setselectedcustomerType] = useState<any[]>([]);
  const [isOpen,setIsOpen] =useState(false);
 

  const handleRemove =()=>{
    remove(index)
  }
  
  useEffect(() => {
    setValue(`Rows.${index}.memberships`,selectedcustomerType);
  }, [selectedcustomerType])
   
  return (
    <Box sx={{display:"flex", flexDirection:{xs:"column", sm:"row"},marginBlock:"10px", justifyContent:"space-between", gap:{xs:"7px", sm:"0px"}, alignItems:{xs:"baseline", sm:"center"}}}>
     <MyDropdown isMembership={true} getOpeon={setIsOpen} width={"200px"} border={true} text="Membership Type" arr={allMembership}  selected={preData?.memberships} setSelected={setselectedcustomerType}/>
      <div className="flex flex-row items-center gap-1">
        <p
          style={{
            color: "rgba(34, 53, 109, 0.70)",
            fontFamily: "var(--font-regular)",
            fontSize: "14px",
            lineHeight: "17px",
          }}
        >
          Price
        </p>
        <Cleave
          style={{ ...borderInput, width: "98px" }}
          onChange={(event: any) => setValue(`Rows.${index}.price`,event.target.rawValue)}
          value={preData?.price}
          placeholder="100"
          options={{
            numeral: true,
            numeralThousandsGroupStyle: "thousand",
            delimiter:'\u2024'
          }}
        />
        {/* <input
          value={price}
          onChange={(e: any) => setPrice(e.target.value)}
          type="text"
          placeholder="600"
          style={{ ...borderInput, width: "98px" }}
        /> */}
        <span
          style={{
            color: "rgba(34, 53, 109, 0.70)",
            fontFamily: "var(--font-regular)",
          }}
        >
          {selectedlocation.currency}
        </span>
        <RxCross1 onClick={handleRemove} style={{cursor:'pointer',fontSize:'14px',color:'#22356D'}}/>
      </div>
    </Box>
  );
};

export default EventDiscountCondition;
