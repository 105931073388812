import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import { getDateAtSpecificTime } from 'src/constants/MomentTimeFor';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import BookingModel from '../calenderDaily/BookingModel';
import useMediaQuery from "@mui/material/useMediaQuery";
import MainContext from 'src/context/MainContext';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CoachPopover from './CoachPopover';
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import moment from 'moment';
import { isWithin30Minutes, roundTimeToNextHour } from '../calenderDaily/CourtCalendar';
import { useGetAllServices } from 'src/api/CalendarBooking';
import BookedSlotPopover from '../calenderDaily/BookedSlotPopover';
import { calculateTotalHours } from 'src/constants/variables';
import { useGetCoachesDailyStats } from 'src/api/CoachCalendar';

const localizer = momentLocalizer(moment);

let formats = {
  timeGutterFormat: 'HH:mm',
}

interface Booking {
  booking?: any;
  end_time: Date;
  event: any;
  full_name: string;
  id: number;
  lesson: any;
  players: any[]; // You can define an interface for players if needed
  recurring: boolean;
  service_id: number;
  service_type: string;
  start_time: Date;
}

interface BookingArray extends Array<Booking> {};

const switchBUtton = {cursor:"pointer",background:"white", boxShadow:{xs:"0px 4px 4px 0px #0000000D inset", sm:"none"}, borderRadius:"7px", padding:{xs:"5px", sm:"0px"}, width:{xs:"70px", sm:"auto"}, height:{xs:"36px", sm:"auto"}}

const arrows = {
  position:"absolute",
  top:"55%", 
  zIndex:"99",
  cursor:"pointer",
  height:"60px",
  display:{xs:"block", md:"none"},
  background:"white", 
  borderRadius:"4px", 
  padding:"21px 2.2px", 
  border:"1px solid #EEEFEE", 
  color:"#22356D", 
  boxSizing:"border-box",
  boxShadow: "4px 4px 4px 0px #0000000D"

}
 
const CoachCalendar = ({ columnsArray, courtList, setSelectView, coachList, locationId, view ,coachStatsRefecth}:{ columnsArray: string, courtList: any, setSelectView:any, coachList: any, locationId:number, view: string,coachStatsRefecth:any }) => {
 
    const date = new Date();
    const [AllCoaches,setAllCoaches] =useState<any[]>([]);
    const totalCourts = AllCoaches?.length;
    const [viewPort, setViewPort] = useState("Laptop")
    const context = useContext(MainContext);
    const selectedDate = context?.selectedDate;
    const setEvent = context?.setEvent;
    const sport_id = context?.sport_id;
    const SelectedLocation = context?.SelectedLocation;
    const theme = useTheme(); 
    const updatedData:any = {};
    const service_id:number[] = [];
    const [maxTime,setMaxtime] = useState<any>("22:00:00")
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [minTime, setMinTime] = useState<any>();
    const [currentTime, setCurrentTime] = useState(moment().format('HH:mm'));
    const [serviceList, setServiceList] = useState<BookingArray>([]);
    const [visibleCourts, setVisibleCourts] = useState([0, isMobile ? 0 : 3 ]);
    const [filteredArray, setFilteredArray] = useState<any>([]);
    const [update, setUpdate] = useState<boolean>(false);
    const [clickStart, setClickStart] = useState('');
    const [clickEvent, setClickEvent] = useState({});
    const [open, setOpen] = useState<boolean>(false);
    const [clickCoach, setClickCoach] = useState<any>([]);
    const [clickEnd, setClickEnd] = useState('');
    const [TotalHeight,setTotalHeight] = useState(900);
    const manipulatedData:any = [];
    const {data,isSuccess,isLoading,isError,refetch,setDate:setSelectedDate,setLocation_id} = useGetAllServices();
    // const {data,isSuccess,refetch,isError,setSelectedDate,setLocation_id} = useGetCoachesDailyService();

useEffect(() => {
  if(sport_id?.length>0){
const filteredData = coachList?.filter((coach:any)=>{
  return sport_id?.some((sport:any)=>sport===coach.sport_id);
});
setAllCoaches(filteredData);
  }

}, [sport_id,coachList]);

const handleREfetch =()=>{
  refetch();
  coachStatsRefecth();
}


    serviceList?.map((item:any)=>{
      if (service_id.includes(item?.service?.id)){
        updatedData[item?.service?.id] = {
          ...updatedData[item?.service?.id],
          value:updatedData[item?.service?.id]?.value + item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity
        }
      }else{
        service_id.push(item?.service?.id);
        updatedData[item?.service?.id] = {
          value: item?.service[item?.service?.service_type.toLowerCase()]?.maximum_capacity,
          players: item?.service?.players?.length
        }
      }
    })  


    // styling the event
    const eventPropGetter = useCallback(
      (event:any) => {
        const isAnyPlayerShown = event.players?.some((item:any) => item.is_show);
        const pendingPayment = event?.players?.filter((item:any)=>!item?.is_show && !item?.is_canceled)?.some((item:any) => item.status ==="Pending payment");


        return {
          ...(event?.service?.service_type?.includes('Event') && {
            className: isAnyPlayerShown && pendingPayment ? "twos-border": isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
              style: {
                backgroundColor: '#FDF0E699',
                border:'#F07727 solid 0.5px'
              },
          }),
          ...(event?.service?.service_type?.includes('Lesson') && {
            className: isAnyPlayerShown && pendingPayment ? "twos-border": isAnyPlayerShown ? "purple-border" : pendingPayment ? 'red-border' :'court-border',
              style:{ 
                backgroundColor:'#FFFDD199',
                border:'#A3A434 solid 0.5px'
              }
          }),
          ...(event?.is_cancelled && event?.service?.service_type.includes('Event') && {
            className: 'cancel eventCan',
            style:{
              borderRadius: '7px !important',
            },
          }),
          ...(event?.is_cancelled && event?.service?.service_type.includes('Lesson')  && {
            className: 'cancel lessCan',
            style:{
              borderRadius: '7px !important'
            },
          }),
          ...(event?.type?.includes('working_hours') && {
            className: 'special'
          }),
          ...(event?.type?.includes("break_hours") && {
            className:"special"
          }),
          ...(event?.type?.includes("special_days") && {
            className:"special"
          })
        }
      },[]
    )

    function checkEventOverlap(startTime:any, endTime:any, fullName:string) {
      return filteredArray.some((event:any) => {
        const eventStart = new Date(event.start_time);
        const eventEnd = new Date(event.end_time);
        const inputStart = new Date(startTime);
        const inputEnd = new Date(endTime);
        const isTimeOverlap = (inputStart > eventStart && inputStart < eventEnd) ||
                              (inputEnd > eventStart && inputEnd < eventEnd) ||
                              (inputStart <= eventStart && inputEnd >= eventEnd);
        return isTimeOverlap && event.full_name === fullName;
      });
    }

    // checking if it is background event or not
    const handleClickOpen = (event:any) => {
      let data:{id?:number, full_name?:string} = {};
      const {start, end, resourceId} = event;
      let start_time = moment(start).format("HH:mm");
      let end_time = moment(end).format("HH:mm");
      AllCoaches.filter((item:any)=>{
        if(item.full_name===resourceId){
          data = {
            id: item.id,
            full_name: item.full_name
          }
        }
      })
      setClickCoach([data])
      setClickStart(start_time);
      setClickEnd(end_time);
      setClickEvent({});
      setEvent({});
      setOpen(true);
      // let background = checkEventOverlap(start, end, resourceId);
      // if(background){
      //   setOpen(false);
      // }else{
      //   setOpen(true);
      // }
    }

    const ShowRecurring = () =>{  
      return (
        <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#889AB6", fontSize:"9px",lineHeight:"7.5px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>R</Box>
      )
    }
  
    const ShowAvailablity = () =>{  
      return (
        <Box sx={{width:'12.5px', height:'12.5px', borderRadius:'17px', background:"#455585", fontSize:"9px",lineHeight:"7.5px", paddingBlock:'2.5px', textAlign:'center', fontWeight:'500'}}>A</Box>
      )
    }
    
    const CustomEvent = ({ event }:{event:any}) => {

      const [showPopHover, setPopHover] = useState(null);
      const [anchorE1, setAnchorE1] = React.useState<HTMLElement | null>(null);
      const [hoveredCourt,setHoveredCourt] = useState<any>();
    
      const handleMouseEnter = (events: React.MouseEvent<HTMLElement>) =>{
        const filteredCourt = AllCoaches?.filter((item:any)=>item?.full_name===event?.full_name);
        setHoveredCourt(filteredCourt[0])
        setPopHover(event);
        setAnchorE1(events.currentTarget);
      }
    
      const handlePopClose= () => {
        setAnchorE1(null);
        setPopHover(null);
      }

      const handleTouchStart = (events: React.TouchEvent<HTMLDivElement>) => {
        events.stopPropagation();
        const filteredCourt = AllCoaches?.filter((item:any)=>item?.id===event?.id);
        setHoveredCourt(filteredCourt[0]?.bookabledays[0])
        setPopHover(event);
        setAnchorE1(events.currentTarget);
      };

      const handleTouchEnd = (event: React.TouchEvent<HTMLDivElement>) => {
        setAnchorE1(null);
        setPopHover(null);
      };

      function isThirtyMinutes(start_time: Date, end_time: Date): boolean {
        // Parse the date objects using Moment.js
        const startTime = moment(start_time);
        const endTime = moment(end_time);
        
        // Calculate the difference in minutes
        const duration = moment.duration(endTime.diff(startTime));
        
        // Check if the duration is equal to 30 minutes
        if(duration.asMinutes() === 60){
          return true;
        }else if(duration.asMinutes() === 30){
          return true;
        }else{
          return false;
        }
      }

      const totaluser = event?.players?.filter((item:any)=>item?.is_canceled!==true);
      let unwaiting = [];
      if(totaluser){
        unwaiting = totaluser?.filter((item:any)=>!item.is_wating);
      }

      if(event?.service?.service_type==='Event'){
        return(
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} position={'relative'} height={'100%'}>
            {!isThirtyMinutes(event.start_time, event.end_time) && <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>}
           
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Box>
                <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#F07727', textTransform:"capitalize"}}>{event.service?.additional_service ? event.service?.additional_service : event.service?.event?.event_name}</Typography>
                <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#F07727', textTransform:"capitalize"}}>{event?.courts?.length > 1 ? `${event?.courts[0]?.court_name} +${event?.courts?.length -1} more` : event?.courts[0]?.court_name}</Typography>
                </Box>
              <Typography sx={{fontFamily:'var(--font-regular)', borderRadius:'7px', width:'fit-content', height:'14px', fontSize:'11px',padding:'1px 7px', textAlign:'center',background:`${unwaiting?.length==event?.service?.event?.maximum_capacity ? 'transparent' : '#F07727'}`,
              color:`${unwaiting?.length==event?.service?.event?.maximum_capacity ? '#F07727' : 'white'}`, position:"absolute",bottom:0, right:0}}>{unwaiting?.length}{event?.service?.event?.maximum_capacity ? `/${event?.service?.event?.maximum_capacity}`: ''}</Typography>
            </Box>
            {/* ------ showing recurring ------ */}
            <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
              {event?.service?.event?.app_available && ShowAvailablity()}
              {event.service?.recurring && ShowRecurring()}
            </Box>
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>  
        )
      }else if(event?.service?.service_type==="Lesson"){
        return(
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} height={'100%'} position={'relative'}>
            {!isThirtyMinutes(event.start_time, event.end_time) && <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#22356D'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>}
            {/* <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#A3A434'}}>{event.full_name}</Typography> */}
            <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#A3A434', textTransform:"capitalize"}}>{event.service?.additional_service ? event.service?.additional_service : event.service?.lesson?.lesson_name}</Typography>
            <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#A3A434', textTransform:"capitalize"}}>{event?.courts.length > 1 ? `${event.courts[0].court_name} ${event.courts.length -1}` : event.courts[0].court_name}</Typography>
            {/* ------ showing recurring ------ */}
            <Box sx={{position:"absolute", width:"100%", top:"5px", display:"flex", justifyContent:"end", gap:"5px"}}>
              {event?.service?.lesson?.app_available && ShowAvailablity()}
              {event.service?.recurring && ShowRecurring()}
            </Box>

            <Typography sx={{fontFamily:'var(--font-regular)', borderRadius:'7px', width:'fit-content', height:'14px', fontSize:'11px',padding:'1px 7px', textAlign:'center',background:`${unwaiting?.length==event?.service?.lesson?.maximum_capacity ? 'transparent' : '#A3A434'}`,
            color:`${unwaiting?.length==event?.service?.lesson?.maximum_capacity ? '#A3A434' : 'white'}`, position:"absolute",bottom:0, right:0}}>{unwaiting?.length}{event?.service?.lesson?.maximum_capacity ? `/${event?.service?.lesson?.maximum_capacity}`: ''}</Typography>
            <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>
        )
      }else if(event?.service?.service_type=='Block'){
        return (
          <>
            <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} className="default-hover" height={'100%'}>
              <Typography sx={{fontFamily:'var(--font-regular)', fontSize:"0.8rem", color:'#889AB6CC'}}>{moment(event.start_time).format('HH:mm')} - {moment(event.end_time).format('HH:mm')}</Typography>
              <Typography sx={{fontFamily:'var(--font-medium)', fontSize:"0.9rem", color:'#889AB6CC'}}>Blocked</Typography>
              <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
            </Box>
          </>
        )
      }else if(event?.is_cancelled){
        return(
          <Box onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} className="default-hover" width={"10px"} height={'100%'}>
              <BookedSlotPopover SelectedLocation={SelectedLocation} viewType='daily' players={updatedData[event.service?.id].players} total={updatedData[event.service?.id].value} eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </Box>
        )
      }else{
        return (
          <>
            <Box onMouseEnter={handleMouseEnter} onMouseLeave={handlePopClose} sx={{ pointerEvents: "all", cursor: "pointer"}} height={'100%'}>
            </Box>
            <CoachPopover coachTiming={hoveredCourt} SelectedLocation={SelectedLocation} viewType='daily' eventType={showPopHover} anchorEl={anchorE1} handlePopoverClose={handlePopClose} />
          </>
        )
      }
    }

    const handleEvent = (data:any) => {
      const {end_time,start_time} = data;

      setClickEnd(end_time);
      setClickEvent(data);
      setClickStart(start_time);
      setOpen(true);
    }

    const handleNext = () => {
      if(viewPort === "Mobile"){
        const newStart = visibleCourts[1] + 1;
        const newEnd = Math.min(newStart, totalCourts - 1);
        if (newStart <= totalCourts - 1) {
          setVisibleCourts([newStart, newEnd]);
        }
      }else if(viewPort === "Tablet"){
        const newStart = visibleCourts[1] + 1;
        const newEnd = Math.min(visibleCourts[1] + 4, totalCourts - 1);
        if (newStart <= totalCourts - 1) {
          setVisibleCourts([newStart, newEnd]);
        }
      }else{
        const newStart = visibleCourts[1] + 1;
        const newEnd = Math.min(visibleCourts[1] + 4, totalCourts - 1);
        if (newStart <= totalCourts - 1) {
          setVisibleCourts([newStart, newEnd]);
        }
      }
    };
    
    const handlePrev = () => {
      if(viewPort === "Mobile"){
        const newEnd = visibleCourts[0] - 1;
        const newStart = Math.max(visibleCourts[0] - 1, 0);
        if (newEnd >= 0) {
          setVisibleCourts([newStart, newEnd]);
        }
      }else if (viewPort === "Tablet"){
        const newEnd = visibleCourts[0] - 1;
        const newStart = Math.max(visibleCourts[0] - 4, 0);
        if (newEnd >= 0) {
          setVisibleCourts([newStart, newEnd]);
        }
      }else{
        const newEnd = visibleCourts[0] - 1;
        const newStart = Math.max(visibleCourts[0] - 4, 0);
        if (newEnd >= 0) {
          setVisibleCourts([newStart, newEnd]);
        }
      }
    };
    
    useEffect(() => {
      const handleResize = () => {
        if (window.matchMedia('(max-width: 600px)').matches ) {
          setViewPort("Mobile");
          setVisibleCourts([0, 0]);
        } else if (window.matchMedia('(max-width: 850px)').matches) {
          setViewPort("Tablet");
          setVisibleCourts([0, 3]);
        } else {
          setViewPort("Laptop");
          setVisibleCourts([0, 3]);
        }
      };


      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
      setSelectedDate(formattedDate);
      setLocation_id(locationId);
    }, [selectedDate,locationId]);

    useEffect(() => {
      if(isSuccess){
        getServiceList(data)
      }else{
        getServiceList([])
      }
     
    }, [data,isSuccess,isError])

    const getServiceList = async (data:any) => {
      const dateStr = moment(selectedDate).format("YYYY-MM-DD");
      const myArray = data?.serviceBookings;
      const newArray = myArray?.filter((item:any)=>item?.service?.service_type==="Lesson"||item?.service?.service_type==="Event");
      const array = newArray?.flatMap((obj:any) => {
        return obj?.service?.coaches?.map((item:any)=>
        ({ ...obj,
            service_id:obj?.id,
            full_name:item?.full_name,
            start_time: moment(`${dateStr} ${obj?.start_time}`, "YYYY-MM-DD HH:mm:ss").toDate(),
            end_time: moment(`${dateStr} ${obj?.end_time}`, "YYYY-MM-DD HH:mm:ss").toDate()
          }
        ))   
          
        });

      const NotHidden = array?.filter((item:any)=>!item?.is_hide)
      setServiceList(NotHidden || []);
    } 

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(moment().format('HH:mm'));
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);
    
    // for showing the active line only if current day
    const elements = document.querySelectorAll('.rbc-current-time-indicator');
    useEffect(() => {
      const today = new Date();
      const isToday = moment(selectedDate).format('DD/MM/YY')===moment(today).format('DD/MM/YY');
      if(elements?.length>0 && !isToday){
        elements.forEach(function(element) {
          element.classList.add('hiddencalRow');
        });  
      }else{
        elements.forEach(function(element) {
          element.classList.remove('hiddencalRow');
        }); 
      }  
    }, [selectedDate,elements]);
  
    const label = document.querySelectorAll('.rbc-label');

    useEffect(() => {
      const updateDisplayTime = () => {
        const firstDiv = document.querySelector('.rbc-current-time-indicator');
        const existingDisplayTime = document.getElementById('display-time');
        if (firstDiv !== null) {
          if (existingDisplayTime) {
            existingDisplayTime.textContent = currentTime;
          } else {
            const newDiv = document.createElement('div');
            newDiv.textContent = currentTime;
            newDiv.id = 'display-time';
            firstDiv.appendChild(newDiv);
          }
        }
      };
     
      const today = new Date()
    const isToday = moment(selectedDate).format('DD/MM/YY')===moment(today).format('DD/MM/YY');
    if(isToday){
      label.forEach(function(element:any) {
        if(isWithin30Minutes(element.innerText,currentTime)){
          element.style.display="none";
        }else{
          element.style.display="block";
        }
      });
    }else{
      label.forEach(function(element:any) {
        if(isWithin30Minutes(element.innerText,currentTime)){
          element.style.display="block";
        }
        });
    }
     

      // Delay the update until after the initial render
      const timeoutId = setTimeout(updateDisplayTime, 1000);

      return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
    }, [currentTime,label]);

    function getEarliestStartTimeForToday(coaches:any) {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const today = new Date().getDay();
      const todayName = daysOfWeek[today];
  
      let earliestTime:any = null;
      let earliestObject:any = null;
  
      coaches?.forEach((coach:any) => {
        const todayTiming = coach.bookabledays.find((timing:any) => timing.days === todayName);
        if (todayTiming) {
          if (!earliestTime || todayTiming.start_time < earliestTime) {
            earliestTime = todayTiming.start_time;
            earliestObject = coach;
          }
        }
      });
      let currentDate = moment(selectedDate).toDate();
      if(earliestTime){
        let value = getDateAtSpecificTime(currentDate, earliestTime);
        // setMinTime(value);
        return value;
      }else{
        return minTime;
      }
    };

    useEffect(() => {
      const min = moment(minTime).format("HH:mm:ss")
      const hours =  calculateTotalHours(min,maxTime);
      const height:any= (hours * 2)*27
      setTotalHeight(height);

    }, [minTime,maxTime])

    // for set the background events ------------
    useEffect(()=>{
      setFilteredArray([])
      let time = getEarliestStartTimeForToday(AllCoaches);
      const dateStr = moment(selectedDate).format("YYYY-MM-DD");
      const timeStr = moment(time).format("HH:mm:ss");

      let latestStartTime: any = "07:00:00";
      AllCoaches.forEach((coach:any) => {
        coach.bookabledays.forEach((timing:any) => {
          const isToday = new Date(timing?.date_start) <= new Date(selectedDate) && new Date(timing?.date_end) >= new Date(selectedDate) && timing?.is_special;
          const isCurrentDay = moment(selectedDate).format('dddd') === timing?.days && timing?.is_special;
          if (isToday || isCurrentDay) {
            if (timing.start_time < latestStartTime) {
              latestStartTime = timing.start_time;
            }
          }else if(!timing.is_special){
            if (timing.start_time < latestStartTime) {
              latestStartTime = timing.start_time;
            }
          }
      })});

      let GetLateEndTime = "22:00:00"; 
      AllCoaches.forEach((coach:any) => {
        coach.bookabledays.forEach((timing:any) => {
          const isToday = new Date(timing?.date_start) <= new Date(selectedDate) && new Date(timing?.date_end) >= new Date(selectedDate);
          const isCurrentDay = moment(selectedDate).format('dddd') === timing?.days && timing?.is_special;
          if (isToday || isCurrentDay) {
            if (timing.end_time > GetLateEndTime) {
              GetLateEndTime = timing.end_time;
            }
          }else if(!timing.is_special){
            if (timing.end_time > GetLateEndTime) {
              GetLateEndTime = timing.end_time;
            }
          }
      })});

      let {endtime:latestEndTime,starttime}:any = roundTimeToNextHour(latestStartTime,GetLateEndTime);
      const startTimeDAy = moment().hour(starttime?.slice(0,2)).minute(starttime?.slice(3,5)).second(0).millisecond(0).toDate();
      setMinTime(startTimeDAy);
      setMaxtime(latestEndTime);
 
      const adjustTimings = (court:any) => {
        const { id, full_name, bookabledays } = court;
        const sTimeMoment = moment(`${dateStr} ${starttime}`, 'YYYY-MM-DD HH:mm');
        const eTimeMoment = moment(`${dateStr} ${latestEndTime}`, 'YYYY-MM-DD HH:mm');
        const currentDay = moment(selectedDate).format("dddd");
        const workingDays = bookabledays
        .filter((entry:any) => !entry.special)
        .map((entry:any) => entry.days);
        const allDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const nonWorkingDays = allDays?.filter(day => !workingDays?.includes(day));
        const dateString = moment(time).format('HH:mm');

        nonWorkingDays?.map((item:any)=>{
          if(currentDay===item){
            setFilteredArray((prevArray:any) => [
              ...prevArray,
              {
                type: "working_hours",
                start_time: moment(sTimeMoment).toDate(),
                end_time: moment(eTimeMoment).toDate(),
                id,
                full_name
              },
            ]);
          }
        })


        // Check if today is a special day
        let specialDay = false;
        let specialStartTime:any;
        let specialEndTime:any;
  
        bookabledays.forEach(({ start_time, end_time,Unavailable, date_start,days, date_end, is_special ,break_times}: any) => {
          if (is_special) {
            const startday = moment(date_start).format("YYYY-MM-DD");
            const endday = moment(date_end).format("YYYY-MM-DD");
            const selected = moment(selectedDate).format("YYYY-MM-DD");
            const isToday = selected >= startday && selected <= endday && is_special;
            const isCurrentDay = moment(selectedDate).format('dddd')===days && is_special;
            if (isToday || isCurrentDay ) {

              if(Unavailable){
                setFilteredArray((prevArray:any) => [
                  ...prevArray,
                  {
                    type: "working_hours",
                    start_time: sTimeMoment.toDate(),
                    end_time: eTimeMoment.toDate(),
                    full_name,
                    id,
                  },
                ]);
              }else{
                specialDay = true;
                specialStartTime = start_time;
                specialEndTime = end_time;
                break_times.forEach(({ start_break, end_break }: any) => {
                  const startBreakTime = moment(`${dateStr} ${start_break}`, "YYYY-MM-DD HH:mm:ss");
                  if (startBreakTime.isAfter(sTimeMoment)) {
                    setFilteredArray((prev: any) => [
                      ...prev, {
                        type: "break_hours",
                        start_time: startBreakTime.toDate(),
                        end_time: moment(`${dateStr} ${end_break}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                        id,
                        full_name
                      }
                    ]);
                  }
                });
              }
              
            }
          }
        });

        bookabledays.forEach(({ start_time, end_time, days, break_times,Unavailable, is_special, date_start, date_end }: any) => {
          let startTime =specialDay?moment(`${dateStr} ${specialStartTime}`, "YYYY-MM-DD HH:mm:ss"): moment(`${dateStr} ${start_time}`, "YYYY-MM-DD HH:mm:ss");
          let endTime = specialDay?moment(`${dateStr} ${specialEndTime}`, "YYYY-MM-DD HH:mm:ss"): moment(`${dateStr} ${end_time}`, "YYYY-MM-DD HH:mm:ss");
  
          // const CompareTime = moment(latestStartTime).format('HH:mm:ss');
          if (days === currentDay && !is_special) {
            if (startTime.isAfter(sTimeMoment) && starttime!==start_time) {
              setFilteredArray((prevArray:any) => [
                ...prevArray,
                {
                  type: "working_hours",
                  start_time: moment(`${dateStr} ${starttime}`, "YYYY-MM-DD HH:mm:ss"),
                  end_time: startTime.toDate(),
                  full_name,
                  id
                },
              ]);
            }
  
            if (endTime.isBefore(eTimeMoment)) {
              setFilteredArray((prevArray:any) => [
                ...prevArray,
                {
                  type: "working_hours",
                  start_time: endTime.toDate(),
                  end_time: moment(`${dateStr} ${latestEndTime}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                  full_name,
                  id
                },
              ]);
            }

            if(break_times.length>0 && !specialDay){
              break_times.forEach(({start_break, end_break}:any) => {
                const startBreakTime = moment(`${dateStr} ${start_break}`,"YYYY-MM-DD HH:mm:ss");
                const Time = starttime;
                const getTime = moment(`${dateStr} ${Time}`,"YYYY-MM-DD HH:mm:ss");

                if (startBreakTime.isAfter(getTime)){
                  setFilteredArray((prev:any)=>[
                    ...prev,{
                      type:"break_hours",
                      start_time: startBreakTime.toDate(),
                      end_time: moment(`${dateStr} ${end_break}`, "YYYY-MM-DD HH:mm:ss").toDate(),
                      full_name,
                      id
                    }
                  ])
                }
              })
            }
          }
        });
      };

      AllCoaches.forEach(adjustTimings);
    },[AllCoaches, locationId,selectedDate]);


    useEffect(()=>{
      const containers = document.querySelectorAll('.rbc-events-container');
  
      containers.forEach(container => {
        const cancels = container.querySelectorAll('.cancel');
        cancels.forEach((cancel:any, index:number) => {
          cancel.id = `cancel-${index}`;
        });
      });
    },[serviceList]);


    let dates = moment(`${moment(selectedDate).format("YYYY-MM-DD")}`, "YYYY-MM-DD").toDate();

    return (
      <Box sx={{marginRight:{xs:"0px", sm:"32px"}, paddingInline:{xs:"10px", sm:"0px"}}}>
      <div style={{width:"100%", position:"relative"} as React.CSSProperties}>

          {AllCoaches?.length>(isMobile ? 0 : 4) && visibleCourts[0]>0 &&
            <Box position={'absolute'} sx={{...switchBUtton, zIndex:"99", cursor:"pointer", left:{xs:"2%", sm:"4%"}, top:"1.5%"}} onClick={handlePrev}>
               <SlArrowLeft style={{color:"#22356D"}} className="switch-icon" />
               </Box>}

          {AllCoaches?.length>(isMobile ? 0 : 4) && visibleCourts[1]!== totalCourts -1 && 
            <Box position={'absolute'} right={"2%"} sx={{...switchBUtton, zIndex:"99", top:{xs:"1.4%", sm:"1.4%"}}} onClick={handleNext}>
              <SlArrowRight style={{color:"#22356D"}} className="switch-icon" />
            </Box>}
          {/* ----- for mobile view only ----- */}
          
          {AllCoaches?.length>0 && visibleCourts[0]>0 && 
         <Box sx={{...arrows, zIndex:"99", left:{xs:"-3%", sm:"6%"}}} onClick={handlePrev}><SlArrowLeft style={{color:"#22356D"}} /></Box>}

          {AllCoaches?.length>0 && visibleCourts[1]!== totalCourts -1 && 
         <Box sx={{...arrows, zIndex:"99", right:{xs:"-3%", sm:"-2%"}}} onClick={handleNext}><SlArrowRight style={{color:"#22356D"}} /></Box>}

          <Calendar
           dayLayoutAlgorithm={'no-overlap'}
            key={columnsArray}
            date={dates}
            defaultView={Views.DAY}
            backgroundEvents={filteredArray}  // showing background events
            events={serviceList}
            localizer={localizer}
            formats={formats}
            resourceIdAccessor="full_name"
            resourceAccessor="full_name"
            resourceTitleAccessor={(resource: any) => (
              <Box sx={{paddingRight:{xs:"55px", sm:"0px"}}}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={1} sx={{flexDirection:{xs:"column", sm:"row"}}} onClick={()=>{isMobile ? console.log("select view") : setSelectView(resource)}}>
                  {resource.profile_url && <img src={resource.profile_url} alt='coach' style={{width:"29px", height:"29px",borderRadius:'50%'}} />}
                  <p style={{textTransform:"capitalize"}}>{resource.full_name}</p>
                </Box> 
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:"11px", textAlign:"center"}}>{resource?.sport?.sport_name}</Typography>
                <Typography sx={{fontFamily:"var(--font-regular)", fontSize:"11px", textAlign:"center", display:{xs:"block", sm:"none"}}}>{visibleCourts[1]+1}/{AllCoaches.length}</Typography>
              </Box>
            )} 
            titleAccessor="full_name"
            startAccessor='start_time'
            endAccessor='end_time'
            min={minTime}
            max={maxTime=== "24:00:00"?moment().endOf('day').toDate():moment().hour(maxTime?.slice(0,2)).minute(maxTime?.slice(3,5)).second(0).millisecond(0).toDate()}
            resources={AllCoaches?.slice(visibleCourts[0], visibleCourts[1]+1 )}
            step={30}
            selectable
            onSelectSlot={handleClickOpen}
            onSelectEvent={handleEvent}
            eventPropGetter={eventPropGetter}
            components={{
              event: (serviceList) => {
                return CustomEvent(serviceList)
              }
            }}
            views={{ day: true, week: false, month: false }}
            style={{height: TotalHeight}}
          />
           <Box sx={{position:'absolute', bottom:'-10px', left:"4px", color:"#22356DB2",fontFamily:'var(--font-regular)',display:isWithin30Minutes(moment(maxTime, "HH:mm:ss").format("HH:mm"),currentTime)?'none':'block'}}>{moment(maxTime, "HH:mm:ss").format("HH:mm")}</Box>
        </div>
       
        {/* ----- Model Box ------ */}
        <BookingModel fromWh={""} events={clickEvent} update={update} serviceList={()=>handleREfetch()} columnsArray={columnsArray} openBookingModel={open} setOpen={setOpen} clickStart={clickStart} clickEnd={clickEnd} clickCourt={[]} courtList={courtList} coach={true} clickCoach={clickCoach} view={"Coaches"} />
      </Box>
    )
}

export default CoachCalendar;

CoachCalendar.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
  dayLayoutAlgorithm: PropTypes.string,
}