import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts/PieChart";
import MainContext from "src/context/MainContext";
import React, { useContext, useEffect, useState } from "react";
import {
  activeColor,
  events,
  lessons,
  pending,
  openMatch,
  noShow,
} from "src/constants/color";
import moment from "moment";
import { addThousandSeparator } from "src/constants/SportSEparator";
import { useMediaQuery } from "@mui/material";
import theme from "src/theme";
import { useTranslation } from "react-i18next";

const status = {
  width: "15px",
  height: "10px",
  borderRadius: "5px",
  display: "inline-flex",
};

const coachColors = [
  "#D8D8EB",
  "#CECEE6",
  "#C4C4E0",
  "#BABAD9",
  "#B0B0D3",
  "#A6A6CE",
  "#9C9CC8",
  "#9292C3",
  "#8888BD",
  "#7E7EB8",
  "#7474B2",
  "#6A6AAD",
  "#6060A7",
  "#5656A2",
  "#4C4C9C",
  "#424297",
  "#38497C",
];

interface type {
  label: string;
  value: number;
}

const CourtPlayerGraph = ({
  viewSelection,
  tabValue,
  dates,
  weekGraph,
}: {
  viewSelection: string;
  tabValue: string;
  dates?: any;
  weekGraph?: any;
}) => {
  const {t} = useTranslation();
  const context = useContext(MainContext);
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const innerWidth = Number(window.innerWidth) >= 1200 && Number(window.innerWidth) <= 1360;
  const courtMonthlyStats = context?.courtMonthlyStats;
  const graphDetails = context?.ratingDetails;
  const SelectedLocation = context?.SelectedLocation;
  const monthlyGraph = context?.monthlyGraph;
  const weeklyGraph = context?.weeklyGraph;
  const CoachMonthStats = context?.CoachMonthStats;
  const [weeks, setWeeks] = useState<type[]>([]);
  const [total, setTotal] = useState(0);
  const {
    bookingPercentage,
    lessonPercentage,
    eventPercentage,
    totalPendingPayments,
  } = monthlyGraph;
  const {
    bookingPercent,
    eventPercent,
    lessonPercent,
    openMatchCounts,
    unfilledOpenMatchCounts,
    noShowCounts,
    pendingPayments,
    dailyOccupancyRate,
    dailyOccupancyHours,
  } = graphDetails;

  function convertArray(inputArray: any) {
    return inputArray.map((item: any) => {
      let durationValue;
      const durationMinutes = item.duration;
      durationValue = (durationMinutes / 60).toFixed(2);
      return {
        label: item.name,
        value: Number(durationValue), // Scale the duration value as desired
      };
    });
  }

  useEffect(() => {
    const specificDate = moment(dates?.startDate);
    const startOfWeek = specificDate.clone().startOf("week").add(1, "day");

    // Initialize an array to store the weekday details
    const weekdays: type[] = [];

    // Loop through each day of the week (from Monday to Saturday)
    for (let i = 0; i < 6; i++) {
      const currentDate = startOfWeek.clone().add(i, "days");
      const formattedDate = currentDate.format("dddd Do");
      const data = {
        label: formattedDate,
        value: 0,
      };
      // Push the formatted date to the array
      weekdays.push(data);
    }
    setWeeks(weekdays);
  }, [dates]);

  useEffect(() => {
    let arr: { label: string; value: number }[] = [];
    let sum = 0;

    const specificDate = moment(dates?.startDate);
    const startOfWeek = specificDate.clone().startOf("week").add(1, "day");
    if (typeof weekGraph !== "undefined") {
      const data = weekGraph;
      if (typeof data !== "undefined") {
        Object.keys(data?.days).forEach((key, i) => {
          const currentDate = startOfWeek.clone().add(i, "days");
          const formattedDate = currentDate.format("dddd Do");
          if (key !== "Sunday") {
            arr.push({
              label: formattedDate,
              value: data?.days[key],
            });
            sum = sum + data?.days[key];
          }
        });
      }
    }
    setWeeks(arr);
    setTotal(Number(sum.toFixed(2)));
  }, [weekGraph]);

  const totalHours = weeklyGraph?.coachesDetails?.reduce(
    (item, acc) => item + acc?.duration,
    0
  );

  return (
    <Box>
      {/* --- court daily view --- */}
      {viewSelection === "Daily" && tabValue == "Courts" && (
        <Grid
          container
          padding={"18px"}
          sx={{
            gap: { xs: "15px", md: "0px" },
            borderRadius: "7px",
            width: "fit-content",
            boxShadow: "0px 4px 4px 0px #0000000d inset",
            fontFamily: "var(--font-regular)",
          }}
          alignItems={"center"}
        >
          <Grid
            item
            sx={{ lineHeight: "26px", marginRight: "28px" }}
            textAlign={"left"}
            color={activeColor}
          >
            <p>{t('Calendar.Daily Occupancy Rate')} {dailyOccupancyRate}%</p>
            <p>{t('Calendar.Daily Occupancy')} {dailyOccupancyHours.toFixed(1)} hrs</p>
            <p>{t('Calendar.Open Matches')} {openMatchCounts}</p>
          </Grid>
      {!innerWidth &&    <Grid item sx={{ marginRight: { xs: "0px", md: "30px" } }}>
            <Box
              display="grid"
              gridTemplateColumns="auto auto 1fr"
              alignItems="center"
              sx={{ gap: { xs: "5px", md: "0px" } }}
            >
              <Box
                fontWeight="700"
                color={activeColor}
                sx={{ marginRight: { xs: "0px", minWidth: "10px" } }}
              >
                <p>
                  <span
                    style={{ ...status, backgroundColor: activeColor }}
                  ></span>{" "}
                  {bookingPercent}%
                </p>
                <p>
                  <span style={{ ...status, backgroundColor: events }}></span>{" "}
                  {eventPercent}%
                </p>
                <p>
                  <span style={{ ...status, backgroundColor: lessons }}></span>{" "}
                  {lessonPercent}%
                </p>
              </Box>
              <Box
                color={activeColor}
                sx={{ opacity: "0.7", marginLeft: "5px", marginRight: "5px" }}
              >
                <p>{t('common.Bookings')}</p>
                <p>{t('common.Events')}</p>
                <p>{t('common.Lessons')}</p>
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", sm: "auto" },
                  justifySelf: { xs: "end", md: "unset" },
                }}
              >
                <PieChart
                  colors={[activeColor, events, lessons]} //
                  series={[
                    {
                      data: [
                        { label: "Bookings", value: bookingPercent },
                        { label: "Events", value: eventPercent },
                        { label: "Lessons", value: lessonPercent },
                      ],
                      innerRadius: 16,
                      outerRadius: 33,
                      paddingAngle: 2,
                      cornerRadius: 4,
                      startAngle: 0,
                      endAngle: 360,
                      cx: 30,
                      cy: 30,
                    },
                  ]}
                  width={70}
                  height={70}
                  tooltip={{ trigger: "none" }}
                  slotProps={{
                    legend: { hidden: true },
                  }}
                />
              </Box>
            </Box>
          </Grid>}
          <Grid>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={0.5}
              sx={{ justifyContent: { xs: "flex-start", md: "right" } }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "0.875rem" } }}
                fontFamily={"var(--font-regular)"}
              >
                {t('Calendar.Unfilled Open Matches')}: {unfilledOpenMatchCounts}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={openMatch}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ justifyContent: { xs: "flex-start", md: "right" } }}
              marginBottom={0.5}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "0.875rem" } }}
                fontFamily={"var(--font-regular)"}
              >
                {t('customers.No-Show')}: {noShowCounts}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={noShow}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ justifyContent: { xs: "flex-start", md: "right" } }}
            >
              <Typography
                sx={{ fontSize: { xs: "1rem", md: "0.875rem" } }}
                fontFamily={"var(--font-regular)"}
              >
                {t('customers.Pending Payments')}:{" "}
                {addThousandSeparator(
                  pendingPayments,
                  SelectedLocation?.currency
                )}{" "}
                {SelectedLocation?.currency}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={pending}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
          </Grid>
        </Grid>
      )}
      {/* --- court monthly view --- */}
      {viewSelection === "Monthly" && tabValue == "Courts" && (
        <Box
          sx={{
            display: "flex",
            gap: { xs: "5px", sm: "30px" },
            flexDirection: { xs: "column", sm: "row" },
            padding: "10px 15px",
            borderRadius: "7px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
            paddingRight: { xs: "65px", sm: "15px" },
          }}
        >
          <Box>
            <p>
              {t('Calendar.Monthly Occupancy Rate')}{" "}
              {courtMonthlyStats?.occupancyRate.toFixed(1)}%
            </p>
            <p>{t('Calendar.Open Matches')} {monthlyGraph.openMatchCounts.toFixed(1)}</p>
            <p>
              Pending Payment:{" "}
              {addThousandSeparator(
                totalPendingPayments,
                SelectedLocation?.currency
              )}{" "}
              {SelectedLocation?.currency}
            </p>
            <p>
              {t('Calendar.Hours Booked')} {courtMonthlyStats?.occupiedHours.toFixed(1)} hrs
            </p>
          </Box>
          <Box sx={{ textAlign: { xs: "left", sm: "right" } }}>
            <p>
            {t('common.Bookings')}: {!!bookingPercentage && bookingPercentage.toFixed(1)}%
            </p>
            <p>{t('common.Events')}: {eventPercentage.toFixed(1)}%</p>
            <p>{t('common.Lessons')}: {lessonPercentage.toFixed(1)}%</p>
          </Box>
        </Box>
      )}

      {/* --- coach monthly view --- */}
      {viewSelection === "Monthly" && tabValue == "Coaches" && (
        <Box
          sx={{
            display: "flex",
            gap: { xs: "5px", sm: "30px" },
            flexDirection: { xs: "column", sm: "row" },
            padding: "10px 15px",
            borderRadius: "7px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
            paddingRight: { xs: "65px", sm: "15px" },
          }}
        >
          <Box>
            <p>
            {t('Calendar.Monthly Occupancy Rate')}{" "}
              {Math.round(CoachMonthStats?.occupancyRate)}%
            </p>
            <p>
             {t('Calendar.Monthly Total Hours')}{" "}
              {(
                CoachMonthStats?.overallTotalEventsHours +
                CoachMonthStats?.overallTotalLessonsHours
              ).toFixed(1)}
            </p>
          </Box>
          <Box sx={{ textAlign: { xs: "left", sm: "right" } }}>
            <p>
            {t('common.Events')}: {Math.round(CoachMonthStats?.eventPercent)}%{" "}
              {CoachMonthStats?.overallTotalEventsHours.toFixed(1)} hr
            </p>
            <p>
            {t('common.Lessons')}: {Math.round(CoachMonthStats?.lessonsPercent)}%{" "}
              {CoachMonthStats?.overallTotalLessonsHours.toFixed(1)} hr
            </p>
          </Box>
        </Box>
      )}

      {/* --- coach daily view --- */}
      {viewSelection === "Daily" && tabValue == "Coaches" && (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          gap={1}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "baseline", md: "center" },
            padding: "10px 15px",
            borderRadius: "7px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
          }}
        >
          <Box>
            <Typography variant="body2" fontFamily={"var(--font-regular)"}>
             {t('Calendar.Daily Occupancy Rate')} {(totalHours / 60).toFixed(1)} hrs
            </Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ gap: { xs: "55px", md: "8px" } }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "auto", md: "repeat(2, 1fr)" },
                columnGap:'10px'
              }}
            >
              {convertArray(weeklyGraph.coachesDetails).map(
                (item: any, index: number) => (
                  <p>
                    <span
                      style={{
                        ...status,
                        backgroundColor: coachColors[index],
                      }}
                      key={index}
                    ></span>
                    &nbsp;<b>{item.value.toFixed(1)}</b> &nbsp;
                    {item.label}
                  </p>
                )
              )}
            </Box>
            {/* <Box>
              <PieChart
                colors={coachColors} //
                series={[
                  {
                    data: [...convertArray(weeklyGraph.coachesDetails)],
                    innerRadius: 16,
                    outerRadius: 33,
                    paddingAngle: 2,
                    cornerRadius: 4,
                    startAngle: 0,
                    endAngle: 360,
                    cx: 30,
                    cy: 30,
                  },
                ]}
                width={70}
                height={70}
                tooltip={{ trigger: "none" }}
                slotProps={{
                  legend: { hidden: true },
                }}
              />
            </Box> */}
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={0.5}
              sx={{ justifyContent: { xs: "flex-start", md: "right" } }}
            >
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
                {t('customers.No-Show')}: {weeklyGraph.noShowCounts}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={noShow}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
              {t('customers.Pending Payments')}:{" "}
                {addThousandSeparator(
                  weeklyGraph.pendingPayments,
                  SelectedLocation?.currency
                )}{" "}
                {SelectedLocation?.currency}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={pending}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
          </Box>
        </Box>
      )}

      {/* --- coach weekly view --- */}
      {viewSelection === "Weekly" && tabValue == "Coaches" && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            padding: "10px 15px",
            borderRadius: "7px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
          }}
        >
          <Box>
            <Typography variant="body2" fontFamily={"var(--font-regular)"}>
              {t('Calendar.Weekly Occupancy')} {total.toFixed(1)} hrs
            </Typography>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
            {weeks.map((item: any, index: number) => (
              <p style={{ fontSize: "0.9rem" }}>
                <span
                  style={{ ...status, backgroundColor: coachColors[index] }}
                  key={index}
                ></span>
                &nbsp;
                <b>
                  {weekGraph?.days[item?.label.split(" ")[0]].toFixed(1)}
                </b>{" "}
                &nbsp;
                {item.label}
              </p>
            ))}
          </Box>
          {/* <Box>
            <PieChart
              colors={coachColors} //
              series={[
                {
                  data: [...weeks],
                  innerRadius: 16,
                  outerRadius: 33,
                  paddingAngle: 2,
                  cornerRadius: 4,
                  startAngle: 0,
                  endAngle: 360,
                  cx: 30,
                  cy: 30,
                },
              ]}
              width={70}
              height={70}
              tooltip={{ trigger: "none" }}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </Box> */}
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"right"}
              marginBottom={0.5}
            >
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
                {t('customers.No-Show')}: {weekGraph?.noShowCounts}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={noShow}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
                {t('customers.Pending Payments')}:{" "}
                {addThousandSeparator(
                  weekGraph?.pendingPayments,
                  SelectedLocation?.currency
                )}{" "}
                {SelectedLocation?.currency}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={pending}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
          </Box>
        </Box>
      )}
      {/* --- court weekly view --- */}
      {viewSelection === "Weekly" && tabValue == "Courts" && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            padding: "10px 15px",
            borderRadius: "7px",
            boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.1) !important",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
          }}
        >
          <Box>
            <Typography variant="body2" fontFamily={"var(--font-regular)"}>
              {t('Calendar.Weekly Occupancy')} {total.toFixed(1)} hrs
            </Typography>
          </Box>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
            {weeks.map((item: any, index: number) => (
              <p style={{ fontSize: "0.9rem" }}>
                <span
                  style={{ ...status, backgroundColor: coachColors[index] }}
                  key={index}
                ></span>
                &nbsp;
                <b>
                  {weekGraph?.days[item?.label.split(" ")[0]].toFixed(1)}
                </b>{" "}
                &nbsp;
                {item.label}
              </p>
            ))}
          </Box>
          <Box>
            <PieChart
              colors={coachColors} //
              series={[
                {
                  data: [...weeks],
                  innerRadius: 16,
                  outerRadius: 33,
                  paddingAngle: 2,
                  cornerRadius: 4,
                  startAngle: 0,
                  endAngle: 360,
                  cx: 30,
                  cy: 30,
                },
              ]}
              width={70}
              height={70}
              tooltip={{ trigger: "none" }}
              slotProps={{
                legend: { hidden: true },
              }}
            />
          </Box>
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"right"}
              marginBottom={0.5}
            >
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
              {t('customers.No-Show')}:{" "}
                {!!weekGraph?.noShowCounts ? weekGraph?.noShowCounts : 0}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={noShow}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
            <Box display="flex" alignItems="center" justifyContent={"right"}>
              <Typography variant="body2" fontFamily={"var(--font-regular)"}>
              {t('customers.Pending Payments')}:{" "}
                {!!weekGraph?.pendingPayments ? weekGraph?.pendingPayments : 0}{" "}
                {SelectedLocation?.currency}
              </Typography>
              <Box
                width="2px"
                height="17px"
                borderRight={3}
                borderColor={pending}
                borderRadius="2px"
                ml={"3px"}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourtPlayerGraph;
