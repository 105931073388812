import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IoIosArrowDown, IoMdAdd } from "react-icons/io";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { matchSorter } from 'match-sorter';
import CustomerInfo from '../../components/CustomerInfo';
import MainContext from 'src/context/MainContext';
import { activeColor } from 'src/constants/color';

interface Customer{
  id: number,
  first_name: string,
  last_name:string,
  email:string,
  phone_number: string,
  Exist:boolean;
}

const SearchTeammates = ( { customerList,customer, text, customerId, index, addingTeam,updateList } : { customerList: Customer[], customer?:string, text:string, customerId?: number, index?:number, addingTeam: any,updateList:any } ) => {
  const [value, setValue] = useState<any>(null);
  const {event:Event} = useContext(MainContext);
  const [IsOpen,setIsOpen] = useState(false);

  const handleChange = (event:any, newValue:any) => {
    // console.log(newValue,'newvalueueu')
  if(newValue.first_name!=="Create" && newValue.last_name!=="new +"){
    setValue(newValue);
    if(text==="first"){
      let data = {
        text: text, 
        newValue: newValue.id,
        index: index,
        customer
      }
       setValue(newValue);
       let promise = addingTeam(data);
      // console.log(res);
      promise.then((result:any) => {
        if(Object.keys(Event)?.length>1){
        setValue(null);
      }
      }).catch((error:any) => {
        console.error("Promise rejected:", error);
      });
      
    }else{
      let data = {
        text: text, 
        newValue: newValue.id,
        customerId: customerId,
        index: index,
        customer
      }
      setValue(newValue);
      let promise = addingTeam(data);
      // console.log(res);
      promise.then((result:any) => {
        if(Object.keys(Event)?.length>1){
          setValue(null);
        }
      }).catch((error:any) => {
        console.error("Promise rejected:", error);
      });
    }
  }else{
    setIsOpen(true)
  }
    
  };

  const handleUpdate=(data:any)=>{
    if(!data){
      updateList();      
      setIsOpen(data);
    }
  }

  const customFilter = (options:any, { inputValue }:any) => {
    if (!inputValue) {
      return options;
    }
    return options.filter((option:any) => 
      `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
    );
  };



  return (
    <Box position={"relative"} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
{
  !value && <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{width:{xs:"28px !important", sm:"35px !important"}, height:{xs:"28px !important", sm:"35px !important"}, borderRadius:"50%", border:"2px dashed #22356D"}}><IoMdAdd style={{fontSize:"1.3rem"}} /></Box>
}
    {value ? <Box sx={{height:'40px'}} position={'relative'} display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} marginBottom={'5px'}>
        <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"}}}} 
          >
          <img
            src={value?.profile_url?value?.profile_url:"/images/Reserved.svg"} alt="user" style={{width:"35px", height:"35px",borderRadius:'50%'}}/>
          <Box sx={{fontFamily: "var(--font-regular)"}} lineHeight={'20px'}>
            <Box fontSize={'16px'} sx={{">br":{display:{xs:"block", sm:"none"},position:'relative'}}}>{value?.first_name} {value?.last_name} &nbsp; 
                    </Box>
          </Box>
        </Box>
      
        {/* <Box display={"flex"} alignItems={"center"} gap={1}>
        <p style={{color:item?.status!=="Pending payment" ? "#5B8777": "#FF1313"}}>
        <span style={{color:'#22356DB2'}}>
          {item?.refunded_status?`Refunded | `:''}
          {item?.status==="Paid" ?`${item?.payment_method} | `:''}
        </span>
        {SelectedLocation?.currency}{" "}
        {
          item?.pending_price===0 && !item?.refunded_status ? addThousandSeparator(item?.paid_price,SelectedLocation.currency): item?.pending_price>0 ? addThousandSeparator(item?.pending_price,SelectedLocation.currency) : item?.refunded_status ? (`- ${addThousandSeparator(item?.refund_price,SelectedLocation.currency)}`):''
        }
        </p>
          <label className="container">
            <input onClick={()=>!item.refunded_status && handleChildClick(item)} checked={selectedCheckbox?.some((plar:any)=>plar?.id ===item?.id)} type="checkbox" />
            <span className="mark"></span>
          </label>
        </Box> */}

      </Box>:  <Box sx={{width:{xs:"130px", sm:"228px"}}}><Autocomplete
        value={value}
        filterOptions={customFilter}
        onChange={handleChange}
        options={customerList}
        className='autoComplete'
        renderOption={(props, option) => (
          <li style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}} {...props} key={option.id}>
            {`${option.first_name} ${option.last_name}`} {option?.Exist && <span onClick={()=>console.log('hell')} style={{background:'white',padding:'2px 5px', borderRadius:'8px'}}><img src={'/images/refreshICON.svg'} style={{color:'#22356D',width:'16px',height:'16px'}}/></span>}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add Teammates"
            placeholder='Add Teammates'
            variant="outlined"
            size="small"
            sx={{"&>div>svg":{display:{xs:"none", sm:"block"}}}}
            InputProps = {{
              ...params.InputProps,
              endAdornment: (
                <IoIosArrowDown style={{marginTop:"6px",color:"#22356D"}} />
              ),
            }}
          />
        )}
      /></Box>}

      <CustomerInfo hidebackdrop={false} GetAllCustomer={(data:any)=>console.log(data)} customerdata={''} customfield={[]}  fromCustomer={false}
        show={IsOpen} setShow={(data:any)=>handleUpdate(data)}
        />
    </Box>
  )
}

export default SearchTeammates



export const SearchAddedTeammates = ( { customerList,data} : { customerList: Customer[],data:any} ) => {
  const [value, setValue] = useState<any>(null);
  const {event:Event} = useContext(MainContext);
  const [IsOpen,setIsOpen] = useState(false);

  const handleChange = (event:any, newValue:any) => {
    // console.log(newValue,'newvalueueu')
 console.log(newValue);
  };

  useEffect(() => {
    const hell =  `${data?.customer?.first_name} ${data?.customer?.last_name} ${data.reserved ? 'Reserved Slot':''}`
    setValue(hell);
  }, [data])
  

  const customFilter = (options:any, { inputValue }:any) => {
    if (!inputValue) {
      return options;
    }
    return options.filter((option:any) => 
      `${option.first_name} ${option.last_name}`.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  console.log(customerList,'custoerm',data)


  return (
    <Box position={"relative"} sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
     <Box sx={{width:{xs:"130px", sm:"228px"}}}><Autocomplete
     sx={{height:'29px'}}
        value={value}
        filterOptions={customFilter}
        onChange={handleChange}
        options={customerList}
        className='autoComplete'
        renderOption={(props, option) => (
          <li style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',color:'#22356D'}} {...props} key={option.id}>
            {`${option.first_name} ${option.last_name}`} <span onClick={()=>console.log('hell')} style={{background:'white',padding:'2px 5px', borderRadius:'8px'}}><img src={'/images/refreshICON.svg'} style={{color:'#22356D',width:'16px',height:'16px'}}/></span>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            // label="Add Teammates"
            placeholder='Add Teammates'
            variant="outlined"
            size="small"
            sx={{"&>div>svg":{display:{xs:"none", sm:"block"}},color:'#22356D'}}
            InputProps = {{
              ...params.InputProps,
              endAdornment: (
                <IoIosArrowDown style={{marginTop:"6px",color:"#22356D",display:'none'}} />
              ),
            }}
          />
        )}
      /></Box>

    </Box>
  )
}
