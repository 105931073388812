import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { BsChevronDown } from "react-icons/bs";
import { t } from "i18next";
import MainContext from "src/context/MainContext";

interface MyDropdownProps {
  text: string;
  arr: any;
  selected: any;
  setSelected: any;
  error?: any;
  border:boolean;
  getOpeon?:any;
  width?:any;
  sport?:any;
  isValidation?:any;
  isMembership?:boolean
}

const MyDropdown: React.FC<MyDropdownProps> = ({
  text,
  sport,
  arr,
  selected,
  setSelected,
  error,
  border,
  getOpeon,
  width,
  isValidation,
  isMembership
}) => {
  const [open, setOpen] = useState(false);
  const {Notification,setNotification} = useContext(MainContext)

  const handleShow = (event: any) => {
    event.stopPropagation();
    setOpen((prev) => !prev);
    getOpeon((prev:any) => !prev);
  };

  const MemberArray = [{id:0,name:'All User'},...arr]

  const handleSelection = (event: any, item: any) => {
    event.stopPropagation();
    if (item === "Select All" || item === "All Users") {
      if(isValidation && text==="locations"){
         if(!arr?.every((curr:any)=>arr[0]?.currency === curr?.currency)){
          setNotification({isShow:true,message:'Cannot select locations with different currencies.',subMessage:'',type:'error'});
          return;
         }else{
          setSelected(selected?.length === arr?.length ? [] : arr);
         }
      }else{
        setSelected(selected?.length === arr?.length ? [] : arr);
      }
     
      if(isMembership && text==="Membership Type"){
        setSelected(selected?.length === MemberArray?.length ? [] : MemberArray);
      }else{
        setSelected(selected?.length === arr?.length ? [] : arr);
      }
    } else if (text === "locations" || text==='Membership Type' || text ==="Services" || text==='Memberships' || text ==="Bookings" || text ==="Lessons" ||  text ==="Events") {
      const firstSelectedItem = selected[0]
      if(text === "locations" && isValidation){
        if (!!firstSelectedItem) {
          if (firstSelectedItem.currency !== item.currency) {
            setNotification({isShow:true,message:'Cannot select locations with different currencies.',subMessage:'',type:'error'})
            return;
          }
        }
    
        if (selected?.some((updatedItem: any) => updatedItem?.id === item?.id)) {
          setSelected((prev: any) =>
            prev?.filter((option: any) => option.id !== item.id)
          );
        } else {
          setSelected((prev: any) => [...prev, item]);
        }
      }else{
         if (selected?.some((updatesd:any)=>updatesd?.id ===item?.id)) {
             if(isMembership){
              const filtered = selected?.filter((option:any)=> option.id !== item?.id && option.id!=0 );
              setSelected(filtered);
              // setSelected((prev: any) =>
              //   prev?.filter((option: any) => option.id !== item.id)
              // );
             }else{
              setSelected((prev: any) =>
                prev?.filter((option: any) => option.id !== item.id)
              );
             }
            } else {
              setSelected((prev: any) => [...prev, item]);
            }
      }
    
    }
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setOpen(false);
      getOpeon(false)
    });
  }, []);


  let inputValue;
  if(selected=== undefined || selected[0]=== undefined && text ==="Services"){
    inputValue = ""
  }else if(selected?.length === arr?.length && text ==="Services"){
    inputValue =`All Services`;
  }else if(selected?.length >1 && text ==="Services"){
    inputValue =`${selected[0]?.name}... +${selected?.length-1}`;
  }else{
    inputValue = selected[0]?.name
  }

  let inputValu;
  if(selected=== undefined || selected[0]=== undefined){
    inputValu = ""
  }else if(selected?.length === MemberArray?.length && isMembership){
    inputValu =`All Users`;
  }else if(selected?.length >1){
    inputValu =`${selected[0]?.name}... +${selected?.length-1}`;
  }else{
    inputValu = selected[0]?.name
  }
 
 

  let value;
  if(selected=== undefined || selected[0]=== undefined){
    value = ""
  }else if(selected?.length >1){
    value =`${selected[0]?.name}... +${selected?.length-1}`;
  }else{
    value = selected[0]?.name
  }
  
const isAllSelected = isMembership && text==="Membership Type" ? selected?.length === MemberArray?.length : selected?.length===arr?.length

  return (
    <Box width={width?width:"180px"} position={"relative"}>
      <Box  height={'35px'} sx={{backgroundColor:'white',borderRadius:'7px',display:'flex',flexDirection:'row',alignItems:'center'}} position={"relative"} onClick={handleShow}>
        <input
           id={error && "customerror"}
           type="text"
           placeholder={t(`common.${text}`)}
           style={border?{
            width: "100%",
            textTransform:'capitalize',
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            color: "#22356D",
            fontFamily: "var(--font-regular)",
            height:'33px',
          }:{
            textTransform:"capitalize",
             width: "100%",
             borderRadius: "7px",
             color: "#22356D",
             fontFamily: "var(--font-regular)",
           }}
           readOnly
           value={text==="Services"?inputValue:isMembership?inputValu:value}
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "10px",
            right: "5.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {open && (
        <Box
          position={"absolute"}
          top={35}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{
            background: "white",
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
          }}
        >
          {/* Add Select All option */}
          <div
            id="table-person"
            style={{ maxHeight: "220px", overflowY: "auto", marginTop: "0px" }}
          >
            <Box
              sx={{
                background:
                 isAllSelected ? "#22356DB2" : "#889AB61A",
                color:isAllSelected ? "white" : "#22356D",
                padding: "10px",
                cursor: "pointer",
                "&:hover": {
                  background: "#22356DB2",
                  color: "#FFFFFF",
                },
              }}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"20px"}
              onClick={(event) => handleSelection(event, "Select All")}
            >
              <p style={{ fontFamily: "var(--font-regular)" }}>{text=="Membership Type" ? "All Users" :"Select All"}</p>
              <label className="container">
                <input style={{border:'none !important'}}
                  type="checkbox"
                  readOnly
                  checked={isAllSelected}
                />
                <span className="mark" style={{ left: "-9px" }}></span>
              </label>
            </Box>
            {
              sport && <Box
              sx={{
                background:
                  selected?.length === arr?.length ? "#22356DB2" : "#889AB61A",
                color: selected?.length === arr?.length ? "white" : "#22356D",
                padding: "10px",
                cursor: "pointer",
                "&:hover": {
                  background: "#22356DB2",
                  color: "#FFFFFF",
                },
              }}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"20px"}
            >
              <p style={{ fontFamily: "var(--font-regular)" ,textTransform:'capitalize'}}>{sport?.sport_name}</p>
            </Box>
            }
            {/* Render other options */}
            {arr?.map(
              (item: any, index: any) =>
                item !== "Select All" && (
                  <Box
                    key={index}
                    sx={{ background:selected?.some((data:any) => data.id === item.id)
                        ? "#22356DB2"
                        : "#889AB61A",
                      color: selected?.some((data:any) => data.id === item.id) ? "white" : "#22356D",
                      padding: "8px 10px",
                      cursor: "pointer",
                      textTransform:'capitalize',
                      "&:hover": {
                        background: "#22356DB2",
                        color: "#FFFFFF",
                      },
                    }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    gap={"20px"}
                    onClick={(event) => handleSelection(event, item)}
                  >
                    <p style={{ fontFamily: "var(--font-regular)", textTransform:"capitalize" }}>{item.name}</p>

                    <label className="container relative">
                      <input
                        type="checkbox"
                        readOnly
                        checked={ selected?.some((data:any) => data.id === item.id) ? true : false}
                      />
                      <span className="mark" style={{ left: "-9px" }}></span>
                    </label>
                  </Box>
                )
            )}
          </div>
        </Box>
      )}
    </Box>
  );
};




export const EventSelector = ({
  text,
  arr,
  selected,
  setSelected,
  error,
  border,
  getOpeon
}:{
  text:any,
  arr:any,
  selected:any,
  setSelected:any,
  error:any,
  border:any,
  getOpeon:any
}) => {
  const [open, setOpen] = useState(false);


  const handleShow = (event: any) => {
    event.stopPropagation();
    setOpen((prev) => !prev);
    getOpeon((prev:any) => !prev);
  };

  const handleSelection = (event: any, item: any) => {
    event.stopPropagation();
    if (item === "Select All") {
      setSelected((prev:any)=>prev?.length === arr[0]?.children?.length ? [] : arr[0]?.children);
    } else if (text==="services") {
      if (selected?.some((updatesd:any)=>updatesd.id ===item.id)) {
        setSelected((prev: any) =>
          prev.filter((option: any) => option.id !== item.id)
        );
      } else {
        setSelected((prev: any) => [...prev, item]);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      setOpen(false);
      getOpeon(false)
    });
  }, []);



  return (
    <Box width={"180px"} position={"relative"}>
      <Box  height={'35px'} sx={{backgroundColor:'white',borderRadius:'7px',display:'flex',flexDirection:'row',alignItems:'center'}} position={"relative"} onClick={handleShow}>
        <input
           id={error == "events" ? "customerror" : ""}
           type="text"
           placeholder={`Select ${text}`}
           style={border?{
            width: "100%",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            opacity: 0.7,
            color: "#22356D",
            fontFamily: "var(--font-regular)",
            height:'30px'
          }:{
             width: "100%",
             borderRadius: "7px",
             opacity: 0.7,
             color: "#22356D",
             fontFamily: "var(--font-regular)",
           }}
           readOnly
           value={selected?.length === arr[0]?.children?.length ?`All ${arr[0]?.label}`:selected?.length>1 ? selected.length >0 ?`${selected[0]?.label}... +${selected?.length-1}`:"selected":selected[0]?.label}
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "10px",
            right: "5.5px",
            fontSize: "0.8rem",
          }}
        />
      </Box>
      {open && (
        <Box
          position={"absolute"}
          top={35}
          left={0}
          zIndex={9}
          width={"-webkit-fill-available"}
          sx={{
            background: "white",
            borderBottomLeftRadius: "7px",
            borderBottomRightRadius: "7px",
          }}
        >
          {/* Add Select All option */}
          <div
            id="table-person"
            style={{ maxHeight: "220px", overflowY: "auto", marginTop: "0px" }}
          >
            <Box
              sx={{
                background:
                  selected.length === arr[0]?.children?.length ? "#22356DB2" : "#889AB61A",
                color: selected.length === arr[0]?.children?.length ? "white" : "#22356D",
                padding: "10px",
                cursor: "pointer",
                "&:hover": {
                  background: "#22356DB2",
                  color: "#FFFFFF",
                },
              }}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"20px"}
              onClick={(event) => handleSelection(event, "Select All")}
            >
              <p style={{ fontFamily: "var(--font-regular)" }}>Select All</p>
              <label className="container">
                <input
                  type="checkbox"
                  readOnly
                  checked={selected.length === arr[0]?.children?.length}
                />
                <span className="mark" style={{ left: "-9px" }}></span>
              </label>
            </Box>
            {/* Render other options */}
            {arr[0]?.children?.map(
              (item: any, index: any) =>
                item !== "Select All" && (
                  <Box
                    key={index}
                    sx={{ background:selected?.some((data:any) => data?.id === item?.id)
                        ? "#22356DB2"
                        : "#889AB61A",
                      color: selected?.some((data:any) => data.id === item.id) ? "white" : "#22356D",
                      padding: "8px 10px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#22356DB2",
                        color: "#FFFFFF",
                      },
                    }}
                    display={"flex"}
                    justifyContent={"space-between"}
                    gap={"20px"}
                    onClick={(event) => handleSelection(event, item)}
                  >
                    <p style={{ fontFamily: "var(--font-regular)" }}>{item.label}</p>

                    <label className="container relative">
                      <input
                        type="checkbox"
                        readOnly
                        checked={ selected?.some((data:any) => data.id === item.id) ? true : false}
                      />
                      <span className="mark" style={{ left: "-9px" }}></span>
                    </label>
                  </Box>
                )
            )}
          </div>
        </Box>
      )}
    </Box>
  );
};
export default MyDropdown;
