import { Box, Typography } from '@mui/material'
import React from 'react'
import { activeColor } from 'src/constants/color'



const scoreStyle={
    // borderRadius: '4px',
    border:'1px solid #939393',
    width:'20px',
    height:'20px',
    textAlign:"center",
    fontSize:{xs:"0.8rem", sm:"0.9rem"},
    // backgroundColor:'white'
}

const ScoreEdit = ({index,user,setValue,scoredata}:{user:any,index:number,setValue:any,scoredata:any}) => {


  return (
    <Box key={user.id} display={'flex'} gap={1} sx={{"&:hover":{background:"#889AB64D !important"}}} alignItems={'center'} padding={'8px 10px'}>
                            <Box>
                              <Box sx={{ borderRadius:"7px", width:"26px", height:"26px", textAlign:"center", padding: '0px 10px', boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)', fontSize:{xs:"0.8rem", sm:"1rem"} }}>{index + 1}</Box>
                            </Box>
                            <Box style={{width: "inherit", flex:1}}>
                                <Box display={'flex'} justifyContent={"space-between"} width={"100%"} color={activeColor} alignItems={'center'}>
                                  <Box>
                                    <Box display={"flex"} alignItems={"center"} gap={1} marginBottom={1} sx={{marginBottom:{xs:"0px", sm:"8px"},">img":{display:{xs:"none", sm:"block"},">p":{display:{xs:"none", sm:"block"}}}}}>
                                      <img src="/images/Reserved.svg" alt="user" width='30px' height='30px' />
                                      <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user.name}</Typography>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} gap={1} sx={{">img":{display:{xs:"none", sm:"block"},">p:nth-child(1)":{display:{xs:"none", sm:"block"}}}}}>
                                      <img src="/images/Reserved.svg" alt="user" width='30px' height='30px' />
                                      <Typography sx={{fontFamily:"var(--font-regular)", fontSize:{xs:"0.8rem", sm:"1rem"}}}>{user.name}</Typography>
                                    </Box>
                                  </Box>
                                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} paddingRight={1} sx={{flexDirection:{xs:"column", sm:"row"}, width:{xs:"106px", sm:"196px"}}}>
                                    <Box display={"flex"} alignItems={"center"} gap={2}>
                                      <Box sx={{...scoreStyle}} >7</Box>
                                      <Box sx={{...scoreStyle}} >6</Box>
                                      <Box sx={{...scoreStyle}} >4</Box>
                                    </Box>
                                    {index!==0 && <Typography fontFamily={"var(--font-medium)"} sx={{fontSize:{xs:"0.8rem", sm:"1rem"}}}>Winner</Typography>}
                                  </Box>
                                </Box>
                            </Box>
                        </Box>
  )
}

export default ScoreEdit