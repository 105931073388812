import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import { getMonthsService } from 'src/api/CalendarBooking';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import MainContext from 'src/context/MainContext';
import useWeekData from 'src/customHook/useWeekData';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useSocket } from "src/context/SocketProvider";

interface Data {
  [key: string]: {
    [key: string]: number;
  };
}

const arrows = {
  position:"absolute", 
  display:{xs:"block", sm:"block", md:"none"}, 
  top:"50%", 
  zIndex:99, 
  background:"white", 
  borderRadius:"7px", 
  border:"1px solid #EEEFEE", 
  boxSizing:'border-box', 
  padding:"7px 2.2px"
}
 
const CoachMonthCalendar = ({selectLocation, selectedMonth,coachList} : {selectLocation:string, selectedMonth:any,coachList:any}) => {
  const alldays = useWeekData(selectedMonth);
  const [show, setShow] = useState<any>({});
  const [coachShow, setCoachShow] = useState<string>('');
  const [dateShow, setDateShow] = useState('');
  const theme = useTheme();
  const [viewPort, setViewPort] = useState("Laptop")
  const [visibleData, setVisibleData] = useState([0, 30]);
  const [dateView, setDateView] = useState<{ [key: string]: number }>({});
  const {messages, sendMessage, connectionStatus,setUrl,listenToEvent  } = useSocket();
  const [sum, setSum] = useState<number>(0);
  const [data, setData] = useState<Data>({});
  const [status, setStatus] = useState("");
  const arr = [1,2,3,4,5,6];
  const context = useContext(MainContext);
  const location_id = context?.selectedLocationId;
  const setCoachMonthStats = context?.setCoachMonthStats;


  const calculateBackgroundColor = (rate:any) => {
    let val = Number(rate);
    if (val === 0) {
      return '#EDF0F4';
    } else if (val <= 25) {
      return '#BEC3D4';
    } else if (val <= 50) {
      return '#A6AEC5';
    } else if (val <= 75) {
      return '#7E89AA';
    } else {
      return '#22356D';
    }
  };

  const monthsData = async (view_type:string, date:any,location_id:number) => {
    setStatus("loading...")
    try {
      let res: any = await getMonthsService(view_type, date, location_id);     
      setStatus("");
      let value = res.data.data?.occupancyRateList;
      setCoachMonthStats(res.data?.data?.stats)
      let combinedData: { [key: string]: number } = {};
      
      // Loop through each person's data
      for (const person in value) {
        const personData = value[person];
        // Loop through each date in the person's data
        for (const date in personData) {
          const formattedDate = moment(date).format("ddd DD MMM");
          if (!combinedData[formattedDate]) {
            combinedData[formattedDate] = 0;
          }
          // Add the value for this date from the person's data
          combinedData[formattedDate] += personData[date];
        }
      }
      setDateView(combinedData);
      setData(res.data?.data?.occupancyRateList);
    } catch (error:any) {
      setStatus("No data found")
    }
  };

  const showAverageData = (occupancy:any, val:string) => {
    setCoachShow(val);
    let value:number = 0
    for (const date in occupancy) {
      value += occupancy[date];
    }
    setSum(value);
  } 

  const handleNext = (event:any) => {
    event.stopPropagation();

    if(viewPort ==  "Mobile"){
      if(visibleData[1] == 28 && alldays.length == 31){
        setVisibleData([27,31])
      }else if(visibleData[1] == 28 && alldays.length == 30){
        setVisibleData([26,30])
      }else if(visibleData[1] == 28 && alldays.length == 28){
        setVisibleData([24,28])
      }else if(visibleData[1] == 28 && alldays.length == 29){
        setVisibleData([25, 29])
      }else{
        const newStart = visibleData[0] + 4;
        const newEnd = visibleData[1] + 4;
        setVisibleData([newStart, newEnd])
      }
    }else{
      if(visibleData[1] == 24 && alldays.length == 31){
        setVisibleData([19,31])
      }else if(visibleData[1] == 24 && alldays.length == 30){
        setVisibleData([18,30])
      }else if(visibleData[1] == 24 && alldays.length == 28){
        setVisibleData([16, 28])
      }else if(visibleData[1] == 24 && alldays.length == 29){
        setVisibleData([17, 29])
      }else{
        const newStart = visibleData[0] + 12;
        const newEnd = visibleData[1] + 12;
        setVisibleData([newStart, newEnd])
      }
    }
  }

  const handlePrev = (event:any) => {
    event.stopPropagation();
    if(viewPort=="Mobile"){
      if(visibleData[0] > 25 && alldays.length == 31 || visibleData[0] > 25 && alldays.length == 30){
        setVisibleData([24, 28])
      }else if(visibleData[0] === 17 && alldays.length == 29 || visibleData[0] === 16 && alldays.length == 28){
        setVisibleData([20, 24]);
      }else{
        const newStart = visibleData[0] - 4;
        const newEnd = visibleData[1] - 4;
        setVisibleData([newStart, newEnd]);
      }
    }else{
      if(visibleData[0] ===19 && alldays.length == 31 || visibleData[0] == 18 && alldays.length == 30 ||visibleData[0] === 17 && alldays.length == 29 || visibleData[0] === 16 && alldays.length == 28){
        setVisibleData([12, 24])
      }else{
        const newStart = visibleData[0] - 12;
        const newEnd = visibleData[1] - 12;
        setVisibleData([newStart, newEnd])
      }
    }
  }

  useEffect(()=>{
    monthsData("coaches", moment(selectedMonth).format("MM-YYYY"), location_id);
  }, [selectLocation, selectedMonth]);

  const sortedKeys = Object.keys(data)?.sort((itemA:any,itemB:any)=> Object.values(data[itemB])?.reduce((item,acc)=>item + acc,0)-Object.values(data[itemA])?.reduce((item,acc)=>item + acc,0));

  const sortedData :any= {};
  sortedKeys.forEach(key => {
      sortedData[key] = data[key];
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia('(max-width: 600px)').matches) {
        setViewPort("Mobile")
        setVisibleData([0, 4]);
      } else if (window.matchMedia('(max-width: 850px)').matches) {
        setViewPort("Tablet")
        setVisibleData([0, 12]);
      } else {
        setViewPort("Laptop")
        setVisibleData([0, alldays.length])
      }
    };

    handleResize();


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [alldays]);

  useEffect(() => {
    const handleEventOne = (data: any) => {
      // console.log('Calendar Daiy:', data);
      monthsData("coaches", moment(selectedMonth).format("MM-YYYY"), location_id);
      // Handle event one data
    };

    listenToEvent('calendars', handleEventOne);

    // Cleanup
    return () => {
      // Unregister event listeners if necessary
    };
  }, [listenToEvent]);


  return (
    <Box height={Object.keys(data).length>3 ? "auto": "85vh"} sx={{ marginBottom:{xs:"57px", sm:"24px"} }}> 
      <Box position={'relative'} display={"flex"} borderRadius={'7px'} sx={{boxShadow: {xs:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", sm:"unset"}, background:{xs:"white", sm:"transparent"}, marginRight:{xs:"15px", sm:"24px"}, marginLeft:{xs:"15px", sm:'0px'}, paddingLeft:{xs:"10px", sm:"0px"}}}>
        {/* left arrow - mobile only*/}
        {Object.keys(data).length>0 && visibleData[0]>0 && <Box position={'absolute'} sx={{display:{xs:"block", md:"none"}, left:"4%", top:"2%", zIndex:99}} onClick={handlePrev}><SlArrowLeft style={{color:"#22356D"}} /></Box>}
        {/* right arrow - mobile only */}
        {Object.keys(data).length>0 && visibleData[1]<alldays.length && <Box position={'absolute'} right={"1%"} sx={{display:{xs:"block", md:"none"},top:"2%", zIndex:99}} onClick={handleNext}><SlArrowRight style={{color:"#22356D"}} /></Box>}

        {/* left arrow - mobile only - in mid */}
        {Object.keys(sortedData).length>4 && visibleData[0] > 0 && <Box position={'absolute'} sx={{...arrows, left:{xs:"-4%", sm:"8%"}}} onClick={handlePrev}><SlArrowLeft style={{color:"#22356D"}} /></Box>}

        {/* right arrow - mobile only - in mid */}
        {Object.keys(sortedData).length>4 && visibleData[1] < 30 && <Box sx={{...arrows, right:{xs:"-4%", sm:"-2%"}}} onClick={handleNext}><SlArrowRight style={{color:"#22356D"}} /></Box>}

        <Box width={'62px'} marginTop={"63px"} display={"flex"} flexDirection={"column"} marginRight={1}>
        {Object.keys(sortedData).length>0 && Object.keys(sortedData).map((item:any,index:number) => {
          const CoachProfile_url = coachList?.filter((fil:any)=>fil.id===Number(item.split("-")[0]))[0]?.profile_url;
          
          
          return(
            <Box key={index} position={'relative'} 
            sx={{height:{xs:"132px", md:"144px"}, fontFamily:"var(--font-regular)", color:"#22356DB2", textAlign:'center', display:"flex", justifyContent:"center", alignItems:'center'}}
             onMouseEnter={()=>showAverageData(data[item], item)} onMouseLeave={()=>setCoachShow('')} onTouchStart={()=>showAverageData(data[item], item)} onTouchEnd={()=>setCoachShow('')}> 
              <Box width={'41px'} sx={{textAlign:'center',color:"#22356DB2"}}>
                <img src={CoachProfile_url?CoachProfile_url:"/images/Reserved.svg"} alt="coach" style={{width:"29px", height:'29px',margin:"auto",borderRadius:'50%'}} />
                <p>{item.split("-")[1]}</p>
                <Typography sx={{fontFamily:"var(--font-semibold)"}}>{Object.values(data[item])?.reduce((item:any,acc:any)=>item+acc,0)?.toFixed(1)} hr</Typography>
              </Box>
              {coachShow===item && <Box position={'absolute'} zIndex={1} sx={{background:"white",padding:"2px 10px",borderRadius:"7px", width:"175px", textAlign:'left', fontFamily:"var(--font-medium) !important", color:"#889AB6",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} left={'10px'}>
                <p style={{fontFamily:"var(--font-regular) !important"}}>{item.split("-")[1]}</p>
                <p>{sum?.toFixed(1)} hrs occupancy</p>
              </Box>}
            </Box>
          )
        }
       )}
        </Box>
        <Box flex={1} sx={{background:"white", padding:'7.5px', borderRadius:"7px", boxShadow:'rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'}}>
          <Box width={"100%"}>
            {/* ---- week showing ---- */}
            <Box display={'flex'} className="borderRight">
              {alldays.slice(visibleData[0], visibleData[1]).map((item:any, index:number)=>
              
              <Box key={index} position={"relative"} sx={{ 
                width: {xs:"calc(100% / 4)", sm:`calc(100% / 12)`, md:`calc(100% / ${alldays?.length})`},
                borderRight: {xs:"none !important", md:"1px solid #22356D26"},
                borderBottom: '1px solid #22356D26', 
                textAlign:'center' }} 
                onTouchStart={()=>setDateShow(item)} onTouchEnd={()=>setDateShow('')}
                onMouseEnter={()=>setDateShow(item)} onMouseLeave={()=>setDateShow('')}>
                <Box sx={{fontFamily:"var(--font-regular) !important",width:"32px", margin:'auto',color:'#22356DB2', lineHeight:"17px", marginBottom:"5px"}}>
                  <p>{item.split(" ")[0]}</p>
                  <p style={{fontFamily:"var(--font-semibold)",fontSize:'1.1rem'}}>{item.split(" ")[1]}</p>
                  <p>{item.split(" ")[2]}</p>
                </Box>
                {dateShow==item && <Box position={"absolute"} zIndex={1} sx={{background:"white",padding:"2px 10px",borderRadius:"7px", width:"175px", textAlign:'left', fontFamily:"var(--font-medium) !important", color:"#889AB6",boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} left={alldays.length-1===index || alldays.length-2===index?"-77px":"0px"}>
                  <p>{item}</p>
                  <p>{dateView[item]?.toFixed(1)} hrs occupancy</p>
                </Box>}
              </Box>

              )}
            </Box>
            {/* ---- occupancy rate ---- */}
            <Box id="month-chart">
              {/* when it have data */}
              {Object.keys(sortedData).length>0 && Object.keys(sortedData).map((item,index) =>{   
                 return (
                  <Box key={index} display={'flex'} className="borderRight">
                    {Object.values(data[item]).slice(visibleData[0], visibleData[1]).map((val:any, index:number) => {

                      const dateKeys = Object.keys(data[item]);
                      return (
                        <Box key={index} sx={{ 
                          width: {xs:"calc(100% / 4)", sm:`calc(100% / 12)`, md:`calc(100% / ${alldays?.length})`},
                          borderRight: {xs:"none !important", md:"1px solid #22356D26"},
                          borderBottom: '1px solid #22356D26', 
                          fontFamily:"var(--font-regular) !important", 
                          textAlign:'center',
                          padding: {xs:"4.5px 10px", md:"2.5px 2px"},
                          height:{ xs:"132px", md:"144px"},
                        }} 
                          position={'relative'} 
                          onTouchStart={()=>setShow({id:index, row:item})} onTouchEnd={()=>setShow({})}
                          onMouseEnter={()=>setShow({id:index, row:item})} onMouseLeave={()=>setShow({})}
                        >
      
                          <Box sx={{width:"90%", height:"98%", margin:"auto",background:calculateBackgroundColor(val), borderRadius:"7px" }}></Box>
                          {/* --- show on hover --- */}
                          {show?.id===index && show?.row===item && 
                          
                          <Box position={'absolute'} top={0} right={0} zIndex={'9999'} sx={{
                            background:"white",
                            padding:"2px 10px",
                            borderRadius:"7px", 
                            width:"145px", 
                            textAlign:'left', 
                            fontFamily:"var(--font-medium) !important", 
                            color:"#889AB6",
                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                            lineHeight:"20px",
                            left:{xs:alldays.length - 1 === index || alldays.length - 2 === index
                              ? "-77px" : "-85px", 
                              sm:alldays.length - 1 === index || alldays.length - 2 === index
                              ? "-77px" : "0px"}
                            }}
                            >
                              <p style={{fontFamily:"var(--font-regular) !important", color:'#22356D99'}}>{moment(dateKeys[index]).format('ddd DD MMM')}</p>
                              <p>{item.split("-")[1]}</p>
                              <p>{val?.toFixed(1)} hrs Occupancy</p>
                            
                          </Box>}
                        </Box>)
                    })}
                  </Box>
                )
              }
             )}
              {Object.keys(data).length===0 && <p style={{width:"100%", textAlign:"center", fontFamily:"var(--font-family)", color:"#22356D", marginBlock:"1rem"}}>{status}</p>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CoachMonthCalendar;