import { Box, FormControl, MenuItem, Select } from '@mui/material'
import React from 'react'
import { dopdowncustomcss } from 'src/constants/dropdownCSS'
import { RxCross2 } from "react-icons/rx";
import { hours } from 'src/constants/variables'
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const PercentArr = ['50','75','100']
const RefundConditionRow = ({data,index,setValue}:{data:any,index:number,setValue:any}) => {
    const handleRefundPercentage=(e:any)=> {
       setValue(`refundPolicy[${index}].refundPercent`,e.target.value)
    }

    const handlePaymentType=(e:any)=> {
        setValue(`refundPolicy[${index}].payment_method`,e.target.value)
     }

     const handlerefundHour=(e:any)=> {
        setValue(`refundPolicy[${index}].refund_hour`,e.target.value)
     }

     const removeField = () => {
        const currentFields = data || [];
        const updatedFields = currentFields.filter((_:any, i:number) => i !== index);
        setValue('refundPolicy', updatedFields);
      };
    


    const percent = data[index].refundPercent;
    const methodType = data[index].payment_method;
    const refundHour = data[index].refund_hour;
  return (
    <Box sx={{display:'flex',flexDirection:{xs:'column',md:'row'},alignItems:{xs:'start',md:'center'},gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
        <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
              <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
                <Select
                  value={percent?percent:'Select'}
                  onChange={(e:any)=>handleRefundPercentage(e)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value} %`;
                    }
                    return <em>Select</em>;
                  }}
                  sx={{
                    width: {xs:"100%", md:"120px"},
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {PercentArr?.map((location: any) => (
                    <MenuItem
                      key={location}
                      value={location}
                    >
                      {location} %
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              to
              <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
                <Select
                  value={methodType?methodType:'Select'}
                  onChange={(e:any)=>handlePaymentType(e)}
                  IconComponent={KeyboardArrowDownIcon}
                  placeholder="Customer type"
                  id="customdropdown"
                  renderValue={(value) => {
                    if (value) {
                      return `${value}`;
                    }
                    return <em>Select</em>;
                  }}
                  sx={{
                    width: {xs:"100%", md:"120px"},
                    height: "35px",
                    border: "1px solid #EEEEFE",
                    borderRadius: "7px",
                    fontSize: "14px",
                    opacity: "1",
                    fontFamily: "var(--font-regular)",
                    color: "#22356D",
                  }}
                  MenuProps={{ sx: dopdowncustomcss }}
                >
                  {['Club Wallet',"cash","credit card"]?.map((location: any) => (
                    <MenuItem
                      key={location}
                      value={location}
                    >
                      {location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </Box>
              <Box sx={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px',fontSize:'14px',color:'#22356D',fontFamily:'var(--font-regular)'}}>
              if cancel 
        <FormControl sx={{width:{xs:"auto", md:"auto"}}}>
        <Select
            value={refundHour?refundHour:'Select'}
            onChange={(e:any)=>handlerefundHour(e)}
            IconComponent={KeyboardArrowDownIcon}
            placeholder="Customer type"
            id="customdropdown"
            renderValue={(value) => {
            if (value) {
                return `${value} hours`;
            }
            return <em>Select</em>;
            }}
            sx={{
            width: {xs:"100%", md:"120px"},
            height: "35px",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontSize: "14px",
            opacity: "1",
            fontFamily: "var(--font-regular)",
            color: "#22356D",
            }}
            MenuProps={{ sx: dopdowncustomcss }}
        >
            {hours?.map((location: any) => (
            <MenuItem
                key={location}
                value={location}
            >
                {location} hours
            </MenuItem>
            ))}
        </Select>
        </FormControl> 
        </Box>
        before the booking starts
        <RxCross2 onClick={removeField}/>
    </Box>
  )
}

export default RefundConditionRow